import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class SubmitButton extends Component {

  render() {
    // const buttonColor = this.props.buttonColor;
    const disabled = (this.props.disabled != null)? this.props.disabled: false;
    return (
      <button type="button" id="submit" className="button-submit" onClick={this.props.onClick} disabled={disabled}>{this.props.text}</button>
    );
  }
}
export default SubmitButton;