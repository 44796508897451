import React, { Component } from 'react';
import ItemCard from './item-card';
import { imgPath } from '../common/helper';
// import { sortOrder } from '../common/utile';
import { categoryName, thumbLabelName, thumbLabelClass } from '../common/const';

var category = [];
var soldItems = [];

class CategoryEndList extends Component {
  render() {
    category = [];
    soldItems = [];

    const endSeriesList = this.props.endSeriesList;
    for (var endKey in endSeriesList) {
      const endSeries = endSeriesList[endKey];
      for (var endCategoryKey in endSeries.category) {
        category.push(endSeries.category[endCategoryKey]);
      }
      soldItems.push({linkTo: "/lp/" + endSeries.rscdir + "/", imgSrc: imgPath(endSeries.rhash + "/img/top/top_thumb.jpg"), imgAlt: endSeries.title, salesLabelType: endSeries.salesLabelType, labelKind: thumbLabelName[endSeries.salesLabelType], labelClass: thumbLabelClass[endSeries.salesLabelType], title: endSeries.title, startTime: endSeries.startTime, endTime: endSeries.endTime, category: endSeries.category});
    }

    // カテゴリソート
    category = category.filter(function (x, i, self) {
      return self.indexOf(x) === i;
    });
    category.sort(function(a,b){
      if( a > b ) return 1;
      if( a < b ) return -1;
      return 0;
    });

    return (
      <ItemEndSort endSeriesLimitCount={this.props.endSeriesLimitCount} />
    );
  }
}
export default CategoryEndList;


function compareNumbers(a, b) {
  return a - b;
}

class ItemEndSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 0,
      endItems: soldItems,
    };
    this.handleActive = this.handleActive.bind(this);
  }

  handleActive(key) {
    let endItems = [];

    soldItems.map((item, i) => {
      if(key === "0" || item.category.indexOf(key) >= 0) {
        if (this.props.endSeriesLimitCount > 0 && i >= this.props.endSeriesLimitCount) return true;
        endItems.push(item);
      }
      return null;
    });
    this.setState({
      active: key,
      endItems: [],
    });
    setTimeout(() => {
      this.setState({
        endItems: endItems,
      });
    });
  }
  // ※iOSの場合のみ描画タイミングが遅く、値段部分の表示が画像より先に出ることがあり結果ちらつきが発生するため
  // 値段表示部分のみdelayをいれて対応しています。現在1000msで設定していますが、もし気になるようでしたらご調整ください。
  // 2019/02/11 初めから描画させてみる(iOSでちらついたら場合分けする)
  componentDidUpdate(){
    Array.from(document.getElementsByClassName('item-card__img--price')).forEach((el, index) => {
      el.setAttribute('style', 'display:none');
      setTimeout(() =>
      el.removeAttribute('style'),0)
    })
  }

  componentWillReceiveProps(){
    this.handleActive("0");
  }

  render () {
    var categoryList = [];
    categoryList.push(<li key="0"><a className={this.state.active === "0" ? "category-link active" : "category-link"} href="javascript:void(0)" onClick={() => this.handleActive("0")}>{categoryName[0]}</a></li>);

    // カテゴリNumberが若い順にソート
    category.sort(compareNumbers);
    for(var i in category){
      const key = category[i];

      if(key !== ""){
        categoryList.push(<li key={key}><a className={this.state.active === key ? "category-link active" : "category-link"} href="javascript:void(0)" onClick={() => this.handleActive(key)}>{categoryName[key]}</a></li>);
      }
    }

    return (
      <React.Fragment>
        <ul className="item-sort">{categoryList}</ul>
        {this.state.endItems.length > 0 &&
          <ul className="item-wrapper">
            {this.state.endItems.map((item, i) => {
            return (
              <ItemCard key={i} item={item} index={i} />
            );
          })}
          </ul>
        }
      </React.Fragment>
    )
  };
}