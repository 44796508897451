import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import { sendGA } from '../common/helper';

function MessageSection() {
  return (
    <section className="entry-pre-complete">
      <SectionTitle title="新規会員登録&nbsp;仮完了" />
      <p>「くじフェス！」へ新規会員登録いただきありがとうございます。<br /><br />
      ご登録いただきましたメールアドレスへ、確認メールをお送りさせていただきました。<br />
      お手数ですがメール内容をご確認いただき、メール記載のURLより本登録をお願いいたします。<br /><br />
      メールが届かない場合は、迷惑メールボックスの設定解除、mail.kujifes.comからのドメイン着信許可の設定をご確認ください。<br />
      数時間経ってもメールが届かない場合は、<a style={{color: "#00B2F4"}} href="mailto:&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;">お問い合わせ</a>よりお問い合わせください。
      </p>
    </section>
  );
}

class EntryPreComplete extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <MessageSection />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default EntryPreComplete;