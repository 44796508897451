import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { LOGIN_CHECK_REQUEST, loginSuccess, loginClear } from '../actions/actions';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);

    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    payload.isLogin = payload.authorized;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(loginSuccess(payload));
    } else {
      yield put(loginClear());
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const loginCheckSaga = [takeEvery(LOGIN_CHECK_REQUEST, handleRequest)];
export default loginCheckSaga;