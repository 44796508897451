import React, { Component } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import FlashMessage from '../components/flash-message';
import SectionTitle from '../components/section-title';
import MiniButtonRound from '../components/mini-button-round';
import { CORS_PROXY, INFO_RSS_URL } from '../common/const';
import { formatInfoNoYearDate, unixTime2Date } from '../common/utile';
import { imgPath } from '../common/helper';
import RssParser from 'rss-parser';
import { sendGA } from '../common/helper';

import { connect } from 'react-redux';
import { checkAccountRequest, purchaseHistoryByRangeRequest } from '../actions/actions';
import { sortOrder } from '../common/utile';
// 1ページのデータ取得数
const PURCHASE_HISTORY_LIMIT = 10;
var lastPurchaseDate = 0;
var historyItems = [];

// マイページ
class MypageSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      infoItems: [],
    }

    var _infoItems = [];
    let parser = new RssParser();
    parser.parseURL(CORS_PROXY + INFO_RSS_URL, (err, feed) => {
      if (typeof feed === 'undefined') return;
      var index = 0;
      feed.items.forEach(function(entry) {
        if (index++ >= 3) return;
        const date = formatInfoNoYearDate(entry.pubDate);
        _infoItems.push({linkTo: entry.link, dateMonth: date.month, dateDay: date.day, week: date.week, text: entry.title});
      });
      this.setState({
        infoItems: _infoItems,
      });
    })
  }

  render () {
    return (
      <section className="mypage">
        <SectionTitle title="マイページ" />
        <div className="mypage__panel">
          <h3 className="pc mypage__headline">運営からのお知らせ</h3>
          <ul>
           {this.state.infoItems.map((item, i) => {
              return (
                <li key={i} className="mypage__item">
                  <a href={item.linkTo} target="_blank" rel="noopener noreferrer">
                    <span className="mypage__item--date">{item.dateMonth + "/" + item.dateDay + " (" + item.week + ")"}</span>
                    <span className="mypage__item--text">{item.text}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    );
  }
}

// 登録情報
class RegistrationInfoSection extends Component {
  render () {

    // console.log("account: this.props" , this.props);
    const account = this.props.account;
    return (
      <section className="registration-info">
        <h2 className="section-title">
          <span>登録情報</span>
          <MiniButtonRound linkTo="/user/edit" text="変更する"/>
        </h2>
        <div className="form-inner-wrap">
          <div className="form-inner">
            <dl>
              <dt>お名前</dt>
              <dd>{account.surname + " " + account.name}</dd>
            </dl>
            <dl>
              <dt>住所</dt>
              <dd>{"〒" + account.postalCode1 + "-" + account.postalCode2 + " " + account.prefectures + account.address1 + account.address2}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{account.phone1 + "-" + account.phone2 + "-" + account.phone3}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{account.email}</dd>
            </dl>
            <dl>
              <dt>生年月日</dt>
              <dd>{account.birthYear + "年" + account.birthMonth + "月" + account.birthDay + "日"}</dd>
            </dl>
          </div>
        </div>
      </section>
    );
  }
}

// 購入履歴一覧
class HistoryItem extends Component {
  render () {
    const item = this.props.item;

    return (
      <li className="history-item">
        <Link to={item.linkTo}>
          <div className="history-item__img">
            <img src={item.imgUrl} alt={item.title} />
          </div>
          <div className="history-item__contents">
            <div className="history-item__no"><p>ご注文番号 :&nbsp;</p><p>{item.no}</p></div>
            <h4 className="history-item__title">{item.title}</h4>
            <div className="history-item__fix-wrapper">
              <span className="history-item__amount">購入数 : {item.amount}個</span>
              <span className="history-item__total">合計 : {item.total.toLocaleString()}円</span>
              <p className="history-item__date">ご注文日時 : {item.date}</p>
            </div>
          </div>
        </Link>
      </li>
    );
  }
}

class HistoryListSection extends Component {
  constructor(props) {
    super(props);
    historyItems = [];
    this.state = {
      hasMore: true,
    }
  }

  dispatch = () => {
    console.log("============================================================================");
    console.log("lastPurchaseDate ==> ", lastPurchaseDate);
    console.log("============================================================================");

    const data = {
      usid: this.props.login.usid,
      seid: this.props.login.seid,
      pcdt: lastPurchaseDate,
      count: PURCHASE_HISTORY_LIMIT,
    };
    this.props.dispatchPurchaseHistoryByRangeRequest(data);

    setTimeout(() => {
      this.setState({
        hasMore: this.props.hasMore,
      });
    }, 500);
  }

  render () {
    const { purchaseHistoryItems } = this.props;

    // console.log("this.state.hasMore => ",this.state.hasMore);
    // console.log("purchaseHistoryItems.length ==> ",purchaseHistoryItems.length);
    return (
      <section className="history-list">
        <SectionTitle title="購入履歴一覧" />
        <ul className="history-list__inner">
        {purchaseHistoryItems.length <= 0 ?
            <div>購入履歴はありません。</div>
            :
            <InfiniteScroll
              dataLength={purchaseHistoryItems.length}
              next={this.dispatch}
              hasMore={this.state.hasMore}
              // hasMore={this.props.hasMore}
              loader={<div className="history-item-loading"><img src="/loading.gif" alt="" /></div>}
              scrollThreshold={0.9}
            >
            {
              purchaseHistoryItems.map((item, i) => {
                return (
                  <HistoryItem key={i} item={item} />
                );
              })
            }
            </InfiniteScroll>
        }
        </ul>
      </section>
    );
  }
}

class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateFlag: false
    }

    if (props.location.state && props.location.state.updateFlag) {
      this.state.updateFlag = true;
      props.history.push({ pathname: '/mypage', state: {updateFlag: false}});
    }

    const { usid, seid } = props.login;
    const data = {
      usid: usid,
      seid: seid,
    };

    props.dispatchCheckAccountRequest(data);
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { login, account, purchaseHistoryItems, hasMore } = this.props;

    if(!login.isLogin) {
      return (<Redirect to="/" />);
    }

    return (
      <div className="wrapper">
        {this.state.updateFlag &&
          <FlashMessage message="登録情報を変更しました。"/>
        }
        <Header />
        <div className="container">
          <MypageSection />
          <RegistrationInfoSection account={account} />
          <HistoryListSection purchaseHistoryItems={purchaseHistoryItems} login={login} hasMore={hasMore} dispatchPurchaseHistoryByRangeRequest={this.props.dispatchPurchaseHistoryByRangeRequest} />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {

  const { account, login, purchaseHistoryList, purchaseHistoryByRange } = state;

  console.log("purchaseHistoryList ==> ", purchaseHistoryList);


  let itemsList = purchaseHistoryList.itemsList;
  // 購入日の降順でソート
  sortOrder(itemsList, "purchaseDate", "desc");

  historyItems = [];
  for (var key in itemsList) {
    var historyInfo = itemsList[key];
    var date = unixTime2Date(historyInfo.purchaseDate);
    var dateFormat = date.year + "/" + date.month + "/" + date.day + " (" + date.week + ") " + date.hour + ":" + date.min + ":" + date.sec;
    historyItems.push({no: historyInfo.oid, linkTo: "/history/" + historyInfo.oid, imgUrl: imgPath(historyInfo.rscdir + "/img/product/product_" + historyInfo.rscid + ".jpg"), title: historyInfo.title, amount: historyInfo.num, total: historyInfo.totalPrice, date: dateFormat});
  }

  // 履歴リストデータの最後のデータの購入時間を保持しておく(purchase-hitstory-by-rangeに必要なパラメータ)
  if(itemsList.length > 0) {
    lastPurchaseDate = itemsList[itemsList.length - 1].purchaseDate;
  }

console.log("mapStateToProps: historyItems => ", historyItems);



  return {
    account: account,
    login: login,
    purchaseHistoryItems: historyItems,
    hasMore: purchaseHistoryByRange.hlist.length >= PURCHASE_HISTORY_LIMIT,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCheckAccountRequest: data => dispatch(checkAccountRequest(data, 'login-check')),
    dispatchPurchaseHistoryByRangeRequest:  data => dispatch(purchaseHistoryByRangeRequest(data, 'purchase-history-by-range')),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(History));