import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { CERTIFICATE_REQUEST, loginSuccess, certificateSuccess, certificateFailure } from '../actions/actions';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(loginSuccess(payload));
      yield put(certificateSuccess(payload));
    } else {
      yield put(certificateFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const certificateSaga = [takeEvery(CERTIFICATE_REQUEST, handleRequest)];
export default certificateSaga;