import redirect from '../common/redirect';
import { history } from '../configureStore';

function checkResponse(response, data) {
  console.log("status ==> ", response.status);
  console.log("response ==> ", response);
  if((response.status >= 200 && response.status < 300) || response.status === 400) {
    // const data = response.json();
    // const data = JSON.stringify(response.body);
    console.log("data ===> ", data);

    if(data.mn == null){
      return true;
    } else {
      history.push("/maintenance");
      return false;
    }
  } else {
    console.log("ここに入る");
    redirect(response.status);

    return false;
  }
}

export default checkResponse;