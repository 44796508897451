import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import api from '../common/api';
import Sha256 from 'js-sha256';
import { SEND_RESET_PASSWORD_REQUEST, sendResetPasswordReceive, sendResetPasswordClear } from '../actions/actions';
import checkResponse from '../common/checkResponse';

function* handleRequest(action) {

  let requestData = Object.assign({}, action);
  requestData.payload = Object.assign({}, requestData.payload, action.payload);
  requestData.payload.ahash = Sha256(requestData.payload.ahash);

  try {
    const response = yield call(api, requestData);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      payload.errMsg=null;
      yield put(sendResetPasswordReceive(payload));
      yield put(sendResetPasswordClear());
      yield put(push('/password/reissue-complete'));
    } else {
      yield put(sendResetPasswordReceive(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const sendResetPasswordSaga = [takeEvery(SEND_RESET_PASSWORD_REQUEST, handleRequest)];
export default sendResetPasswordSaga;