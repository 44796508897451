import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutRequest } from '../actions/actions';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import LogoImg from '../images/common/logo.svg';
import PCHeaderIconEntry from '../images/pc/pc_header_icon_entry.png';
import PCHeaderIconEntryHover from '../images/pc/pc_header_icon_entry_hover.png';
import PCHeaderIconLogin from '../images/pc/pc_header_icon_login.png';
import PCHeaderIconLoginHover from '../images/pc/pc_header_icon_login_hover.png';
import PCHeaderIconMypage from '../images/pc/pc_header_icon_mypage.png';
import PCHeaderIconMypageHover from '../images/pc/pc_header_icon_mypage_hover.png';
import SPMenuIconOpen from '../images/sp/sp_menu_icon_open.png';
import SPMenuIconClose from '../images/sp/sp_menu_icon_close.png';
import SPMenuIconLogin from '../images/sp/sp_menu_icon_login.png';
// import SPMenuIconLogout from '../images/sp/sp_menu_icon_logout.png';
import SPMenuIconEntry from '../images/sp/sp_menu_icon_entry.png';
import SPMenuIconMypage from '../images/sp/sp_menu_icon_mypage.png';

function SPMenu(props) {
  return (
    <div className="sp header-inner__sp-menu">
      <input type="checkbox" className="check" id="checked" />
      <label className="sp-menu__button" htmlFor="checked">
        <img src={SPMenuIconOpen}　alt="メニューopen" />
      </label>
      <label className="close-menu" htmlFor="checked"><img className="close-menu__button" src={SPMenuIconClose} alt="閉じる" /></label>
      <nav className="sp-menu__contents">
        <ul className="menu-list">
          {props.isLogin ?
            <div className="sp-menu--is-login">
              <li>
                <Link to="/mypage">
                  <div className="item-wrapper">
                    <img src={SPMenuIconMypage} alt="マイページ" />
                    <span className="bigger">マイページ</span>
                  </div>
                </Link>
              </li>
            </div>
            :
            <div className="sp-menu--is-logout">
              <li>
                <Link to="/login">
                  <div className="item-wrapper">
                    <img src={SPMenuIconLogin} alt="ログイン" />
                    <span className="bigger">ログイン</span>
                  </div>
                </Link>
              </li>
              <li>
                <HashLink to="/login#entry-anchor" className="header-nav__entry" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>
                  <div className="item-wrapper">
                    <img src={SPMenuIconEntry} alt="新規会員登録" />
                    <span className="bigger">新規会員登録</span>
                  </div>
                </HashLink>
              </li>
            </div>
          }
          <li>
            <Link to="/">
              <div className="item-wrapper">
                <span>トップページ</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/about">
              <div className="item-wrapper">
                <span>くじフェス！とは</span>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/faq">
              <div className="item-wrapper">
                <span>よくあるご質問</span>
              </div>
            </Link>
          </li>
          <li>
            <a href="/#/contact">
              <div className="item-wrapper">
                <span>お問い合わせ</span>
              </div>
            </a>
          </li>
          {props.isLogin &&
            <li>
              <a href="#" onClick={ e => props.dispatchLogOut({usid: props.usid, seid: props.seid})}>
                <div className="item-wrapper">
                  <span>ログアウト</span>
                </div>
              </a>
            </li>
          }
        </ul>
      </nav>
    </div>
  );
}

function Logo() {
  const logoAlt = 'くじフェス！';
  return (
    <div className="header-logo">
      <h1>
        <Link to="/">
            <img className="sp" src={LogoImg} alt={logoAlt} />
            <img className="pc pc-logo" src={LogoImg} alt={logoAlt} />
        </Link>
      </h1>
    </div>
  );
}

function HeaderNav(props) {
  return (
    <nav className="pc header-nav">
      {props.isLogin ?
        <div className="header-nav--is-login">
          <Link to="/mypage" className="header-nav__mypage"><img src={PCHeaderIconMypage} alt="マイページ" /><img src={PCHeaderIconMypageHover} alt="マイページ" className="header-nav__icon--active" /><span>マイページ</span></Link>
          <button className="header-nav__logout" type="button" onClick={ e => props.dispatchLogOut({usid: props.usid, seid: props.seid})}><span className="header-nav__button--logout">ログアウト</span></button>
        </div>
        :
        <div className="header-nav--is-logout">
          <HashLink to="/login#entry" className="header-nav__entry" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}><img src={PCHeaderIconEntry} alt="新規会員登録" /><img src={PCHeaderIconEntryHover} alt="新規会員登録" className="header-nav__icon--active" /><span>新規会員登録</span></HashLink>
          <Link to="/login" className="header-nav__login"><img src={PCHeaderIconLogin} alt="ログイン" /><img src={PCHeaderIconLoginHover} alt="ログイン" className="header-nav__icon--active" /><span>ログイン</span></Link>
        </div>
      }
    </nav>
  );
}

class Header extends Component {
  render() {
    // console.log("this.props", this.props);

    return (
      <header id="header" className="header">
        <div className="header-inner">
          <SPMenu {...this.props} dispatchLogOut={this.props.dispatchLogOut} />
          <Logo />
          <HeaderNav {...this.props} dispatchLogOut={this.props.dispatchLogOut} />
        </div>
      </header>
    );
  }
}

function mapStateToProps(state){
  return state.login;
}
function mapDispatchToProps(dispatch){
  return {
    dispatchLogOut: data => dispatch(logoutRequest(data, 'logout')),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
