import { SEND_RESET_PASSWORD_RECEIVE, SEND_RESET_PASSWORD_VALUE, SEND_RESET_PASSWORD_CLEAR } from '../actions/actions';
const initialState = {
  email: null,
  surname: null,
  name: null,
  questionType: null,
  isSuccess: false,
  errMsg: null,
};

export default function login(state=initialState, action) {

  switch (action.type) {
    case SEND_RESET_PASSWORD_VALUE:
      return Object.assign({},
        state,
        action.payload
      );
    case SEND_RESET_PASSWORD_CLEAR:
      return initialState;
    case SEND_RESET_PASSWORD_RECEIVE:
      console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    default:
      return state;
  }
}
