import { call, put, takeEvery, select} from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { INQUIRY_CONTACT_REQUEST, inquiryContactFailure } from '../actions/actions';

// ログインcheck
function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.sxp === false ) {
      yield put(push("/contact/complete"));
    } else {
      yield put(inquiryContactFailure(payload));
    }

  } catch(error) {
    console.log("error ==> ",error);

  }
}

const inquiryContactSaga = [takeEvery(INQUIRY_CONTACT_REQUEST, handleRequest)];
export default inquiryContactSaga;