import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import NormalButton from '../components/normal-button';
import PasswordCard from '../components/password-card';
import { sendGA } from '../common/helper';

class PasswordReissueComplete extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <PasswordCard heading="メールを送信しました。">
          <p className="pc">パスワード再設定ページへのリンクを記載したメールをお送りしました。<br/>
          再設定メールが届かない場合は、迷惑メールボックスの設定解除、<br/>
          mail.kujifes.comからのドメイン着信許可の設定をご確認ください。</p>
          <p className="sp">パスワード再設定ページへのリンクを記載したメールをお送りしました。<br/>
          再設定メールが届かない場合は、迷惑メールボックスの設定解除、mail.kujifes.comからのドメイン着信許可の設定をご確認ください。</p>
          <NormalButton buttonColor="red" text="くじフェス！トップページへ" linkTo="/" />
          </PasswordCard>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default PasswordReissueComplete;