import store from '../configureStore';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { PURCHASE_HISTORY_REQUEST, purchaseHistoryList, purchaseHistoryListClear } from '../actions/actions';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sxp === false && payload.errMsg == null ) {

      yield put(purchaseHistoryList(payload));
    } else {

      yield put(purchaseHistoryListClear());
      store.dispatch(push('/404'));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const purchaseHistorySaga = [takeEvery(PURCHASE_HISTORY_REQUEST, handleRequest)];
export default purchaseHistorySaga;