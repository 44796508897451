import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import IconPagetop from '../images/common/pagetop.png';

function PageTopImg() {
  return (
    <AnchorLink href="#root" offset="50">
      <img src={IconPagetop} alt="ページトップに戻る" />
    </AnchorLink>
  );
}

class PageTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.setState({
      isVisible: window.pageYOffset > 0
    });
  }
  render() {
    const { isVisible } = this.state;
    return (
      <div className="page-top">
        {isVisible ? <PageTopImg /> : null}
      </div>
    );
  }
}
export default PageTop;
