import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import storageSession from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native

import account from './account';
import certificate from './certificate';
import checkSeries from './checkSeries';
import checkCertificateReset from './checkCertificateReset';
import certificateReset from './certificateReset';
import login from './login';
import checkEmail from './checkEmail';
import endSeriesList from './endSeriesList';
import sendResetPassword from './sendResetPassword';
import result from './result';
import oid from './oid';
import order from './order';
import product from './product';
import register from './register';
import top from './top';
import categorySeriesList from './categorySeriesList';
import userEdit from './userEdit';
import purchaseHistoryList from './purchaseHistoryList';
import purchaseHistoryByRange from './purchaseHistoryByRange';
import purchaseHistoryDetail from './purchaseHistoryDetail';
import inquiryContact from './inquiryContact';
import pendingShipment from './pendingShipment';


const persistStorageConfig = {
  key: 'login',
  storage: storage,
  whitelist: ['usid', 'seid', 'isLogin']
}
const sendResetPasswordPersistSessionConfig = {
  key: 'sendResetPassword',
  storage: storageSession,
  whitelist: ['email', 'surname', 'name', 'questionType']
}

const persistSessionRegisterConfig = {
  key: 'register',
  storage: storageSession
}

const persistSessionOidConfig = {
  key: 'oid',
  storage: storage,
  whitelist: ['oid']
}

const persistSessionProductConfig = {
  key: 'product',
  storage: storage,
  whitelist: ['srid', 'ltp', 'num']
}
const accountPersistSessionConfig = {
  key: 'account',
  storage: storageSession
}
const userEditPersistSessionConfig = {
  key: 'userEdit',
  storage: storageSession
}
const purchaseHistoryListPersistSessionConfig = {
  key: 'purchaseHistoryList',
  storage: storageSession
}

const persistedStorageReducer = persistReducer(persistStorageConfig, login);
const sendResetPasswordPersistedSessionReducer = persistReducer(sendResetPasswordPersistSessionConfig, sendResetPassword);
const persistedSessionRegisterReducer = persistReducer(persistSessionRegisterConfig, register);
const persistedSessionProductReducer = persistReducer(persistSessionProductConfig, product);
const accountPersistedSessionProductReducer = persistReducer(accountPersistSessionConfig, account);
const userEditPersistedSessionProductReducer = persistReducer(userEditPersistSessionConfig, userEdit);
const purchaseHistoryListPersistSessionReducer  = persistReducer(purchaseHistoryListPersistSessionConfig, purchaseHistoryList);

const persistedSessionOidReducer = persistReducer(persistSessionOidConfig, oid);

const rootReducer = combineReducers({
  account: accountPersistedSessionProductReducer,
  certificate,
  checkSeries,
  checkCertificateReset,
  certificateReset,
  login: persistedStorageReducer,
  checkEmail,
  endSeriesList,
  sendResetPassword: sendResetPasswordPersistedSessionReducer,
  result,
  order,
  product: persistedSessionProductReducer,
  register: persistedSessionRegisterReducer,
  oid: persistedSessionOidReducer,
  form: reduxFormReducer,
  top: top,
  categorySeriesList: categorySeriesList,
  userEdit: userEditPersistedSessionProductReducer,
  purchaseHistoryList: purchaseHistoryListPersistSessionReducer,
  purchaseHistoryByRange,
  purchaseHistoryDetail: purchaseHistoryDetail,
  inquiryContact: inquiryContact,
  pendingShipment: pendingShipment,
});

export default rootReducer;
