import { ORDER_SUCCESS, ORDER_FAILURE } from '../actions/actions';
const initialState = {
  showModal: false,
};

export default function order(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case ORDER_SUCCESS:
      action.payload.showModal = true;
      return Object.assign({},
        state,
        action.payload
      );
    case ORDER_FAILURE:
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}