import React, { Component } from 'react';
import ButtonTwitterIcon from '../images/common/button_twitter_icon.png';
import BadgePR from '../images/common/badge_special.png';
import BadgeS from '../images/common/badge_s.png';
import BadgeA from '../images/common/badge_a.png';
import BadgeB from '../images/common/badge_b.png';
import BadgeC from '../images/common/badge_c.png';
import BadgeD from '../images/common/badge_d.png';
import BadgeE from '../images/common/badge_e.png';
import BadgeF from '../images/common/badge_f.png';
import BadgeG from '../images/common/badge_g.png';
import BadgeH from '../images/common/badge_h.png';
import BadgeI from '../images/common/badge_i.png';
import BadgeJ from '../images/common/badge_j.png';
import BadgeK from '../images/common/badge_k.png';
import BadgeL from '../images/common/badge_l.png';
import BadgeM from '../images/common/badge_m.png';
import { dynamicLinksApi } from '../common/dynamicLinksApi';
// import { TwitterShareButton } from 'react-share';
import { TWITTER_USER } from '../common/const';

import { imgPath, getLotteryAwardText } from '../common/helper';
import { restfulApiConfig } from '../common/config';


const sharelHandleClick = function(e, data) {
  dynamicLinksApi(data);
}

class PrizeCard extends Component {

  render() {
    const badges = {
      0: BadgePR,
      1: BadgeS,
      2: BadgeA,
      3: BadgeB,
      4: BadgeC,
      5: BadgeD,
      6: BadgeE,
      7: BadgeF,
      8: BadgeG,
      9: BadgeH,
      10:BadgeI,
      11:BadgeJ,
      12:BadgeK,
      13:BadgeL,
      14:BadgeM,
    }
    const { item, rscdir } = this.props;
    // const shareUsername = TWITTER_USER;
    // const shareMessage = item.lotteryItemTitle + 'があたったよ！';
    // const shareUrl = restfulApiConfig.apiURL + 'share?rscdir=' + rscdir + '&rscid=' + item.rscid;

    let tagText = "";
    for(let i in item.snsTagList) {
      tagText += '%23' + item.snsTagList[i] + '%20';
    }

    const shareData = {
      username: '@' + TWITTER_USER,
      message: item.lotteryItemTitle + 'があたったよ！',
      url: restfulApiConfig.apiURL + 'share?rscdir=' + rscdir + '%26rscid=' + item.rscid,
      hashTag: tagText,
    };
    // console.log("shareData ==>", shareData);
    return (
      <React.Fragment>
      {this.props.isSpecial ?
      <li className="prize-card-big">
        <div className="prize-card-big__img">
          <img src={imgPath(item.rscid +".jpg", rscdir, "item" )} alt={getLotteryAwardText(item.lotteryAwardType)+"賞"} />
          <div className="prize-card-big__img--badge">
            <img src={badges[0]} alt="プレミアムチャンス" />
          </div>
        </div>
        <div className="prize-card-big__contents">
          <h3 className="prize-card-big__contents--prize-name">{item.itemAwardName}賞</h3>
          <h4 className="prize-card-big__contents--title">{item.lotteryItemTitle}</h4>
        </div>
        <div className="prize-card-big__button-wrapper">
          <span className="prize-card-big__amount"><span className="prize-card-big__amount--red">{item.num}</span>個</span>
          {/* <TwitterShareButton title={shareMessage} via={shareUsername} url={shareUrl} hashtags={item.snsTagList}>
            <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
          </TwitterShareButton> */}
          <button className="share-button share-button--size_s share-button--twitter"  onClick={e=> sharelHandleClick(e, shareData)}>
            <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
          </button>
        </div>
      </li>
      :
      <React.Fragment>
        {this.props.isOnce ?
        <li className="prize-card-once">
          <div className="prize-card-once__img">
            <img src={imgPath(item.rscid +".jpg", rscdir, "item" )} alt={item.lotteryItemTitle} />
            <div className="prize-card-once__img--badge">
              <img src={badges[item.lotteryAwardType]} alt={getLotteryAwardText(item.lotteryAwardType)} />
            </div>
          </div>
          <div className="prize-card-once__contents">
            <h3 className="prize-card-once__contents--prize-name">{item.itemAwardName}賞</h3>
            <h4 className="prize-card-once__contents--title">{item.lotteryItemTitle}</h4>
          </div>
          <div className="prize-card-once__button-wrapper">
            <span className="prize-card-once__amount"><span className="prize-card-once__amount--red">{item.num}</span>個</span>
            {/* <TwitterShareButton title={shareMessage} via={shareUsername} url={shareUrl} hashtags={item.snsTagList}>
              <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
            </TwitterShareButton> */}

            <button className="share-button share-button--size_s share-button--twitter" onClick={e=> sharelHandleClick(e, shareData)}>
              <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
            </button>
          </div>
        </li>
      :
        <li className="prize-card">
          <div className="prize-card__img">
            <img src={imgPath(item.rscid +".jpg", rscdir, "item" )} alt={item.lotteryItemTitle} />
            <div className="prize-card__img--badge">
              <img src={badges[item.lotteryAwardType]} alt={getLotteryAwardText(item.lotteryAwardType)+"賞"} />
            </div>
          </div>
          <div className="prize-card__contents">
            <h3 className="prize-card__contents--prize-name">{item.itemAwardName}賞</h3>
            <h4 className="prize-card__contents--title">{item.lotteryItemTitle}</h4>
          </div>
          <div className="prize-card__button-wrapper">
            <span className="prize-card__amount"><span className="prize-card__amount--red">{item.num}</span>個</span>
            {/* <TwitterShareButton title={shareMessage} via={shareUsername} url={shareUrl} hashtags={item.snsTagList}>
              <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
            </TwitterShareButton> */}
            <button className="share-button share-button--size_s share-button--twitter" onClick={e=> sharelHandleClick(e, shareData)}>
              <img src={ButtonTwitterIcon} alt="Twitter" /><span>シェアする</span>
            </button>
          </div>
        </li>
        }
      </React.Fragment>
      }
      </React.Fragment>
    );
  }
}
export default PrizeCard;