import { END_SERIES_LIST_SUCCESS, END_SERIES_LIST_FAILURE } from '../actions/actions';

const initialState = {};

export default function top(state = initialState, action) {
  switch (action.type) {
    case END_SERIES_LIST_SUCCESS:
      return Object.assign({},
        state,
        action.payload
      );
    case END_SERIES_LIST_FAILURE:
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}