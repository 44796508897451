import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import PasswordCard from '../components/password-card';
import { checkEmailRequest } from '../actions/actions';
import { restfulApiConfig } from '../common/config';
import ReCAPTCHA from "react-google-recaptcha";
import { sendGA } from '../common/helper';

var recaptchaID = "";

class PasswordReissueMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputForm: {
        email: "",
        surname: "",
        name: "",
      },
      isRecaptcha: false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
    document.getElementById('button-reissue').disabled = !this.state.isRecaptcha;

    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  componentDidUpdate () {
    document.getElementById('button-reissue').disabled = !this.state.isRecaptcha;
  }

  // 入力内容の変更を監視
  handleChange(event) {
      let inputForm = this.state.inputForm;
      switch (event.target.name) {
        case 'input-email':
          inputForm.email = event.target.value;
          break;
        case 'input-surname':
          inputForm.surname = event.target.value;
          break;
        case 'input-name':
          inputForm.name = event.target.value;
          break;
        default:
          break;
      }

      // 状態を更新
      this.setState({
        inputForm: inputForm
      });
  }

  onChange(value){
    recaptchaID = value;
    this.setState({
      isRecaptcha: true,
    });
  }

  onSubmit(recaptchaRef){
    const { dispatchRequest } = this.props;
    let inputForm = this.state.inputForm;
    inputForm.recaptcha = recaptchaID;
    dispatchRequest(inputForm)
    this.setState({
      isRecaptcha: false,
    });
    recaptchaRef.current.reset();
    recaptchaID = "";
  }

  render() {
    const {checkEmail} = this.props;
    const isError = (checkEmail.errMsg != null);
    const recaptchaRef = React.createRef();

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <PasswordCard heading="パスワードをお忘れの方">
            <p>ご登録時のメールアドレスとご登録されたお名前を入力してください。<br/>
            入力されたメールアドレスへ、パスワード再設定ページへのリンクをお送りします。</p>
            {isError && <p className="error-message">{checkEmail.errMsg.toString()}</p>}
            <h4 className="password-card__subheading">メールアドレス</h4>
            <input type="email" className="password-card__input--normal" name="input-email" placeholder="登録されたメールアドレス" value={this.state.inputForm.email} onChange={this.handleChange} />
            <h4 className="password-card__subheading">お名前</h4>
            <div className="input-wrapper">
              <input type="text" className="password-card__input--name" name="input-surname" placeholder="姓" value={this.state.inputForm.surname} onChange={this.handleChange} />
              <input type="text" className="password-card__input--name" name="input-name" placeholder="名" value={this.state.inputForm.name} onChange={this.handleChange} />
            </div>
            <div className="button-wrapper">
              <ReCAPTCHA
                className="recaptcha"
                ref={recaptchaRef}
                sitekey={restfulApiConfig.recaptchaID}
                onChange={this.onChange}
              /><br />
              <button id="button-reissue" type="button" onClick={e => this.onSubmit(recaptchaRef)} className="button-red">次へ進む</button>
            </div>
          </PasswordCard>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state",state);

  const {checkEmail} = state;
  return {
    checkEmail: checkEmail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: inputForm => dispatch(checkEmailRequest(inputForm, 'check-email')),
   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReissueMail);