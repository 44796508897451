import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import { sendGA } from '../common/helper';

class Contents extends Component {
  render() {
    return (
      <table className="policy__table" summary="特定商取引に関する法律に基づく表記">
      <tbody>
        <tr>
          <th>販売業者</th>
          <td>株式会社GOODROID</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>運営責任者</th>
          <td>松田 和彬</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>住所</th>
          <td>〒153-0042<br />東京都目黒区青葉台4-7-7</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>メールアドレス</th>
          <td><a href="mailto:&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;">&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;</a></td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>URL</th>
          <td><a href="https://kujifes.com/">https://kujifes.com/</a></td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>Tel</th>
          <td>03-6328-4040<br /><br className="pc" />※サービスに関してのお問い合わせは上記メールアドレスまでお願いいたします。</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>商品以外の必要代金</th>
          <td>配送手数料が必要です。<br />詳細は各商品詳細ページをご確認ください。<br />また、インターネットの利用に関わる全ての料金(インターネット通信費・パソコン・スマートフォン代など)はお客様ご負担となります。</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>注文方法</th>
          <td>
            新規会員登録後、当サイト上で、購入したい商品(くじ)とその数量を選択し、「購入手続きへ」ボタンを押すと、ポップアップが表示されます。<br />
            ポップアップでOKを押すと、GMOペイメントゲートウェイのお支払い方法選択ページへ遷移します。<br />
            お支払い方法を選択し、「進む」ボタンを押すとそれぞれの必要事項記入フォームが表示されます。<br />
            必要事項を記入し「決済する」ボタンを押した時点で注文が確定します。
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>支払方法</th>
          <td>
          クレジットカード決済、コンビニエンスストア決済、キャリア決済(ドコモ/au/ソフトバンク)、WebMoney(ウェブマネー)がご利用いただけます。<br />
	        クレジットカードは5大国際ブランド（Visa、MasterCard、JCB、AMEX、Diners）のほか、日本国内の各種クレジートカード会社発行のクレジットカードに対応しております。<br />
          <a href="https://www.gmo-pg.com/service/mulpay/credit-card/?purpose=1" target="_blank" rel="noopener noreferrer">詳しくはこちら</a>
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>支払期限</th>
          <td><a href="https://www.gmo-pg.com/" target="_blank" rel="noopener noreferrer">GMOペイメントゲートウェイ</a>にて「決済する」ボタンを押した時点で、お支払いが完了します。</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>引渡し時期</th>
          <td>ご購入いただいた商品(くじ)は、各商品ページにて記載してあります「お届け目安」時期に配送となります。</td>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <th>返品・交換について</th>
          <td>
            お客様都合による、ご注文後の返品・交換等はお受けできません。<br />
            商品に不具合あった場合は、当社が不具合と認めた場合に限り、交換対応を承りますが、理由のいかんに関わらず、返金・注文取消は承れませんので予めご了承ください。<br /><br />
            ※詳しくは「<Link to="/notes">注意事項</Link>」をご確認ください。</td>
        </tr>
      </tbody>
      </table>
    );
  }
}

class Policy extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="policy">
            <SectionTitle title="特定商取引に関する法律に基づく表記" />
            <Contents />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default Policy;