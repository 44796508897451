import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import LoginForm from '../components/login-form';
import EntryForm from '../components/entry-form';

import { connect } from 'react-redux';
import { loginRequest, registerValue } from '../actions/actions';
import { sendGA } from '../common/helper';

var historyBack = true;

class Login extends Component {
  constructor(props) {
    super(props);

    if(props.history.location.state != null) {
      historyBack = (props.history.location.state.referrer !== "/password/resetting-complete");
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    sendGA(pathname);
  }

  render() {
    const {login, register} = this.props;

    if(login.isLogin) {
      var history = this.props.history;

      var id = setInterval(function(){
        let data = JSON.parse(localStorage.getItem("persist:login"));

        if(data.usid !== "null" && data.seid !== "null"){
          clearInterval(id);

          if(historyBack) {
            history.goBack();
          } else {
            history.push("/");
          }
        }
      }, 100);
    }

    const recaptchaRef = React.createRef();

    return (
      <div className="wrapper">
        <Header isLogin={login.isLogin} />

        <div className="container">
          <section id="login" className="login">
            <SectionTitle title="ログイン" />
            <LoginForm login={login}  history={this.props.history} dispatchRequest={this.props.dispatchRequest} recaptchaRef={recaptchaRef}  />
          </section>

          <section id="entry" className="entry">
            <SectionTitle title="新規会員登録" />
            <EntryForm register={register} history={this.props.history} dispatchRegister={this.props.dispatchRegister} />
          </section>
        </div>

        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: loginForm => dispatch(loginRequest(loginForm, 'login')),
    dispatchRegister: registForm => dispatch(registerValue(registForm))
   }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));