import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import TopSlider from '../components/top-slider';
// import CategoryList from '../components/category-list';
import CategoryContent from '../components/category-content';
import InfoItem from '../components/info-item';
import OfficialSNS from '../components/official-sns';
import RssParser from 'rss-parser';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { CORS_PROXY, INFO_RSS_URL, CAUTION_INFO_TAG } from '../common/const';
import { formatInfoDate } from '../common/utile';

import { connect } from 'react-redux';
import { topRequest, categorySeriesListRequest } from '../actions/actions';
import { saveUrlParam, sendGA } from '../common/helper';

// const timeLineAfterFunc = function(){
//   console.log("onComplete Call =====> ");
//   document.getElementsByClassName('timeline-Header-title')[0].setAttribute('style', 'font-size: 14px;')
// }

class Top extends Component {
  constructor(props) {
    super(props);

    var infoItems = [];
    var cautionInfo = [];
    let parser = new RssParser();
    parser.parseURL(CORS_PROXY + INFO_RSS_URL, (err, feed) => {
      if (typeof feed === 'undefined') return;

      feed.items.forEach(function(entry) {
        const pushContent = {linkTo: entry.link, date: entry.pubDate, text: entry.title};
        infoItems.push(pushContent);
        if (entry.title.indexOf(CAUTION_INFO_TAG) !== -1 && cautionInfo.length <= 0) {
          cautionInfo.push(pushContent);
        }
      });

      if(this.refs.cautionInfo != null) {
        this.refs.cautionInfo.updateState(cautionInfo);
      }
      if(this.refs.info != null) {
        this.refs.info.updateState(infoItems);
      }
    })
    this.state = {
      topResponse: {},
    }

    saveUrlParam();
  }

  componentDidMount(){
    let { pathname } = this.props.location;

    sendGA(pathname);

    this.props.dispatchRequest(this.state.topResponse);
  }

  render() {
    console.log("this.props.top ===> ", this.props.top);
    return (
      <div className="wrapper">
        <Header />
        <TopSlider sc={this.props.top.sc} topBannerList={ this.props.top.topBannerList } seriesList={ this.props.top.seriesList } />
        <CautionInfo ref="cautionInfo" />
        <div className="top-container">
          {/* <CategoryList seriesList={ this.props.seriesList } endSeriesList={ this.props.endSeriesList } isEndListOnly={false} endSeriesLimitCount={3} /> */}
          <CategoryContent
            seriesList={ this.props.top.seriesList } 
            endSeriesList={ this.props.top.endSeriesList } 
            categorySeriesList={ this.props.categorySeriesList.seriesList } 
            categoryEndSeriesList={ this.props.categorySeriesList.endSeriesList } 
            isEndListOnly={false} endSeriesLimitCount={3} 
            categorySeriesRequest={ this.props.dispatchCategorySeriesList }
          />

          <div className="information-wrap">
            <Info ref="info" />
            <section className="official-twiiter">
              <h2 className="section-title"><span>公式Twitter</span></h2>
              <div className="timeLine-wrap">
                <TwitterTimelineEmbed sourceType="profile" lang={'ja'} noHeader={true} screenName="kujifes" options={{width: 768, height: 490}} />
              </div>
            </section>
          </div>
          <OfficialSNS />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { top, categorySeriesList } = state;

  return {
    top: top,
    categorySeriesList: categorySeriesList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: topResponse => dispatch(topRequest(topResponse, 'series-list')),
    dispatchCategorySeriesList: data => dispatch(categorySeriesListRequest(data, 'category-series-list')),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Top));

class Info extends Component {
  constructor(props) {
    super(props);
    // console.log("Top props", props);
    this.state = {
      infoItems: [],
    }
  }

  updateState (data) {
    this.setState({
      infoItems: data,
    });
  }

  render() {
    // const { infoItems } = this.props;
    return (
      <section className="info">
        <h2 className="section-title">
          <span>お知らせ</span>
        </h2>
        <ul className="item-wrapper">
          {this.state.infoItems.map((item, i) => {
            if (i <= 2) {
              return (
                <InfoItem key={i} item={item} />
              );
            } else if (i > 2 && i <= 4) {
              // SP時3件目以降を非表示
              return (
                <InfoItem key={i} item={item} isPCOnly={true} />
              );
            } else {
              return null;
            }
          })}
        </ul>
      </section>
    );
  }
}

class CautionInfo extends Component {
  constructor(props) {
    super(props);
    // console.log("Top props", props);
    this.state = {
      cautionInfo: [],
    }
  }

  updateState (data) {
    this.setState({
      cautionInfo: data,
    });
  }

  render() {
    return (
      <section className="caution-info">
      { this.state.cautionInfo.length > 0 &&
        <div className="caution-item">
          { this.state.cautionInfo.map((item, i) => {
            return (
              <a key={i} href={item.linkTo} target="_blank" rel="noopener noreferrer">{formatInfoDate(item.date)} {item.text}</a>
          )
          })}
        </div>
      }
      </section>
    );
  }
}
