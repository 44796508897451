// import { handleActions } from 'redux-actions';
import { TOP_SUCCESS, TOP_FAILURE } from '../actions/actions';

const initialState = {
  topBannerList: [],
  seriesList: [],
  endSeriesList: [],
};

export default function top(state = initialState, action) {
  switch (action.type) {
    case TOP_SUCCESS:
      console.log(action.type, action);
      state = action.payload;
      return state;
    case TOP_FAILURE:
      console.log(action.type, action);
      state = action.payload;
      return state;
    default:
      return state;
  }
}
