import React, { Component } from 'react';
import { history } from '../configureStore';
import { withRouter } from 'react-router'

class NormalButton extends Component {
  constructor(props) {
    super(props);
    console.log("NormalButton props => ", props);
    this.setReferrer = this.setReferrer.bind(this);
  }
  setReferrer(e) {
    console.log("this.history => ", this.props.history);
    if(this.props.history != null) {
      console.log("this.props.history  参照できてる\n\n");
      this.props.history.push({ pathname: this.props.linkTo, state: { referrer: this.props.location.pathname }});
    } else {
      console.log("this.props.history  参照でき無い!!!!!!! \n\n");
      history.push(this.props.linkTo);
    }
  }

  render() {
    const buttonColor = this.props.buttonColor;
    return (
        <button type="button" className={"button-" + buttonColor} onClick={this.setReferrer}>{this.props.text}</button>
    );
  }
}
export default withRouter(NormalButton);