import React, { Component } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import NormalButton from '../components/normal-button';
import PasswordCard from '../components/password-card';
import { connect } from 'react-redux';
import { sendGA } from '../common/helper';

class PasswordSettingComplete extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { login } = this.props;

    var btnLabel = login.isLogin? "トップページへ": "ログイン";
    var btnLink  = login.isLogin? "/": "/login";
    var text     = login.isLogin? "新しいパスワードでログインしています。": "次回から新しいパスワードでログインができます。";

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <PasswordCard heading="パスワードの設定が完了しました。">
          <p className="resetting-conplete-text">{text}</p>
          <div className="button-wrapper">
            <NormalButton buttonColor="red" text={btnLabel} linkTo={btnLink} />
          </div>
          </PasswordCard>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login } = state;

  return {
    login: login,
  };
}

export default connect(
  mapStateToProps,
)(PasswordSettingComplete);