import React, { Component } from 'react';
import SNSIconTwitter from '../images/common/sns_icon_twitter.png';
import SNSIconFacebook from '../images/common/sns_icon_facebook.png';
// import SNSIconLine from '../images/common/sns_icon_line.png';

class OfficialSNS extends Component {
  render() {
    return (
      <section className="official-sns">
        <span className="official-sns__icon-wrapper">
          <a href="https://twitter.com/kujifes" className="icon" target="_blank" rel="noopener noreferrer"><img src={SNSIconTwitter} alt="Twitter"/></a>
          <a href="https://fb.me/kujifes" className="icon" target="_blank" rel="noopener noreferrer"><img src={SNSIconFacebook} alt="Facebook"/></a>
          {/*<a href="#" className="icon"><img src={SNSIconLine} alt="LINE"/></a>*/}
        </span>
      </section>
    );
  }
}
export default OfficialSNS;