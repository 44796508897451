import { REGISTER_VALUE, REGISTER_SUCCESS, REGISTER_FAILURE } from '../actions/actions';

const initialState = {};

export default function register(state=initialState, action) {
  switch (action.type) {
    case REGISTER_VALUE:
      state = action.payload;
      return state;
    case REGISTER_SUCCESS:
      return state;
    case REGISTER_FAILURE:
      return Object.assign({},
        state,
        {
          errMsg: action.payload.errMsg
        });
    default:
      return state;
  }
}
