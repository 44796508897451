import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import UserEditConfirmForm from '../components/user-edit-confirm-form';
import SectionTitle from '../components/section-title';
import { Redirect } from 'react-router-dom';
import { sendGA } from '../common/helper';

class UserEditConfirm extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    if (typeof this.props.location.state === 'undefined')
    {
      return <Redirect to="/mypage" />;
    }
    const editForm = this.props.location.state.editForm;
    const login = this.props.login;

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="user-edit-confirm">
            <SectionTitle title="登録情報変更確認" />
            <p className="pc">下記の内容で変更してよろしいでしょうか？<br />よろしければ、一番下の「変更する」ボタンをクリックしてください。</p>
            <p className="sp">下記の内容で送信してもよろしいでしょうか？</p>
            <UserEditConfirmForm history={this.props.history} login={login} editForm={editForm} />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default withRouter(UserEditConfirm);