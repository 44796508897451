
import { history } from '../configureStore';

function redirect(status) {
  let url= "/";

  switch(status) {
    case 500:
    case 502:
    case 404:
      url += 404;
      break;
    default:
      url += 404;
      break;
  }
  console.log("call push", url);
  history.push(url);

}

export default redirect;