import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import {RenderField, postalCodeFields, AddressField, BaseCheckbox } from './render-field';
import {required, addressRequired, requiredPrefectures, extendValidator, number } from '../common/validate';
import { prefectures } from '../common/const';
import { unixTime2Date } from '../common/utile'
import BackButton from './back-button';
import SubmitButton from './submit-button';

const prefecturesOption = [];
for(let key in prefectures) {
  prefecturesOption.push(<option key={key} value={prefectures[key]}>{prefectures[key]}</option>);
}

let initial = {
  postalCode1: "",
  postalCode2: "",
  prefectures: "",
  address1: "",
  address2: "",
  olist: [],
};

class ContactAddressForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      checks: null,
      isCheckBoxError : false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleAllChange = this.handleAllChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // 確認画面に入力情報を送信
  handleSubmit(e) {
    const { login, account, invalid, formValues, dispatchFormRequest, api, reset } = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    let param = Object.assign({},
      formValues,
      login
    );


    console.log("formValues.olist ==> ", formValues.olist + '\n\n');
    param.olist = formValues.olist.join(',');

    const keyList = ['surname', 'name', 'phoneticSurname', 'phoneticName', 'phone1', 'phone2', 'phone3'];
    for(let key of keyList) {
      param[key] = account[key];
    }

    if(invalid === false && param.olist !== ""){
      // リクエストを投げる
      if(dispatchFormRequest != null) {
        dispatchFormRequest(param, api);
        reset();
      }
    }

    this.setState({
      isCheckBoxError: (param.olist.length === ""),
    });
  }

  // 単品選択時の処理
  handleChange = e => {
    const { formValues } = this.props;
    let target = e.target;

    const num = formValues.olist.indexOf(target.value);

    if(target.checked) {
      if(num === -1) {
        formValues.olist.push(target.value);
      }
    } else {
      if (num >= 0) {
        formValues.olist.splice(num, 1);
      }
    }

    let checks = this.state.checks;
    checks.allSelect.flag = false;
    checks[target.name].flag = target.checked;

    this.setState({
      checks: checks,
      isCheckBoxError: (formValues.olist.length === 0),
    });
  };

  // 全て選択処理
  handleAllChange = e => {
    const { formValues, pendingShipment  } = this.props;

    let targetChecked = e.target.checked;
    let checks = this.state.checks;

    for(let key in checks) {
      checks[key].flag = targetChecked;
    }

    formValues.olist.splice(0, formValues.olist.length);

    if(targetChecked) {
      for(let value in pendingShipment.olist) {
        formValues.olist.push(pendingShipment.olist[value].oid);
      }
    }

    this.setState({
      checks: checks,
      isCheckBoxError: (formValues.olist.length === 0),
    });
  }

  componentDidMount() {
    if(window.YubinBango != null) {
      new window.YubinBango.MicroformatDom();
    }
  }

  componentDidUpdate(prevProps) {
    const { pendingShipment } = this.props;

    if(this.props.pendingShipment.olist.length > 0 && this.state.checks == null) {
      let obj = {};

      obj['allSelect'] = {
        flag: false,
        label: '<div class="fw-bold">全て選択</div>',
      };
      for(let value of this.props.pendingShipment.olist) {
        let date = unixTime2Date(value.purchaseDate);
        let dateFormat = date.year + "/" + date.month + "/" + date.day + " (" + date.week + ") " + date.hour + ":" + date.min + ":" + date.sec;

        obj[value.oid] = {
          flag: false,
          label: '<div class="select-item">' +
                 '<div class="fw-bold">' + value.title + '</div>' +
                 '<div><p class="d-block fs14">ご注文日時&nbsp;:&nbsp;'+ dateFormat + '</p></div>' +
                 '<div class="d-flex"><p class="d-block fs14">ご注文番号&nbsp;:&nbsp;</p><p class="d-block fs14">'+ value.oid +'</p></div>' +
                 '</div>',
        };
      }
      // calc(14px + 0.25vw)
      this.setState({
        checks: obj,
      });
    }
  }

  render() {
    const { invalid, formValues } = this.props;

    let sendDisabled = true;

    if(formValues != null) {
      sendDisabled = !(!invalid && formValues.olist.length > 0);
    }

    let checkBoxList = [];

    for(let key in this.state.checks) {
      // let label = (key === 'allSelect' )? "全て選択": key;
      let onChange = (key === 'allSelect' )? this.handleAllChange: this.handleChange;
      checkBoxList.push(
        <div key={key}>
          <Field
            name={key}
            label={this.state.checks[key].label}
            onChange={ onChange }
            key={key}
            id={key}
            checked={this.state.checks[key].flag}
            component={BaseCheckbox}
          />
        </div>,
      );
    }


    return (
      <form name="contactAddressForm" id="contactAddressForm" className="entry-form h-adr">
        <input type="hidden" className="p-country-name" style={{display: 'none'}} value="Japan"></input>

        <div>
          <p>商品の発送処理状況により、配送先の変更を受け付けられない可能性がございます。何卒ご了承ください。<br /><br /></p>
        </div>

        <div className="form-inner">
          <dl>
            <dt>郵便番号<span className="required">※</span></dt>
            <dd className="form-inner__postalcode">
              <Fields names={['postalCode1', 'postalCode2']}
                component={postalCodeFields}
                alldata={this.props.formValues}
                allMeta={this.props.formMeta}
                validate={{
                  postalCode1: [
                    (value) => extendValidator('郵便番号1が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号1 半角数字を入力してください')(number(value)),
                  ],
                  postalCode2: [
                    (value) => extendValidator('郵便番号2が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号2 半角数字を入力してください')(number(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>住所<span className="required">※</span></dt>
            <dd className="form-inner__address">
              <Field id={'prefectures'} name="prefectures" component={RenderField} validate={[requiredPrefectures]} type="select" className={'p-region select-pref-wrapper__select--pref'} >
                {prefecturesOption}
              </Field>
              <div className="form-inner__address-wrapper">
                <Field id={'address1'} name="address1" component={AddressField} validate={[addressRequired]} type="text" placeholder="市区町村名" className={'p-locality p-street-address p-extended-address form-inner__input--long'} />
                <Field id={'address2'} name="address2" component={AddressField} validate={[addressRequired]}　type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} />
                {/* <Field id={'address2'} name="address2" component={RenderField} validate={[required]} type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} /> */}
              </div>
            </dd>
          </dl>

          <dl>
            <dt>
              ご注文番号選択
              <span className="required">※</span>
              <p className="fs12">ご注文番号がない商品は、その他よりお問い合わせください。</p>
            </dt>
            <dd>
              {this.state.isCheckBoxError &&
                <p className="text-error">1つ以上選択してください</p>
              }
              <div className='select-item-wrap'>
                {checkBoxList}
              </div>
            </dd>
          </dl>

        </div>

        <section className="delivery-address">
        <div className="button-wrapper">
          <div className="button-wrap">
            <BackButton linkTo="/contact" text="戻る" />
          </div>
          <div className="button-wrapper--centering">
            <SubmitButton onClick={this.handleSubmit} text="送信" disabled={sendDisabled} />
          </div>
        </div>
        </section>
      </form>
    );
  }
}

ContactAddressForm = reduxForm({
  form: 'contactAddressForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: initial,
})(ContactAddressForm);


ContactAddressForm = connect(state => {
  return {
    formValues: getFormValues('contactAddressForm')(state),
    formMeta: getFormMeta('contactAddressForm')(state),
  }
})(ContactAddressForm);

export default ContactAddressForm;
