import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectContact } from '../actions/actions';

import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';

import { contactItem } from '../common/const'

class Contact extends Component {

  constructor(props) {
    super(props);
    // const { login } = this.props;

    this.state = {
      contact: {
        selectValue: 0,
      },
      canContact: false,
    }

    this.handleChange = this.handleChange.bind(this);
  }

  // 問い合わせ項目選択の変更を監視
  handleChange(event) {
    let contactSelect = this.state.contact;

    // let obj = document.selectContact.selectItem;
    let index = event.target.selectedIndex;

    // console.log("obj =>", event.target);
    // console.log("obj.options[index].value =>",obj.options[index].value);
    // console.log("event.target.name =>", event.target.name);
    switch (event.target.name) {
      case 'selectItem':
        console.log("test => ", event.target.options[index].value);
        contactSelect.selectValue = event.target.options[index].value;
        break;
      default:
        break;
    }

    // 状態を更新
    this.setState({
      contact: contactSelect,
      canContact: (event.target.value > 0)
    });
  }

  componentDidMount() {
    document.getElementById('button-submit').disabled = !this.state.canContact;
  }
  componentDidUpdate() {
    document.getElementById('button-submit').disabled = !this.state.canContact;
  }

  onSubmit() {
    const { dispatchRequest, login } = this.props;
    let param = login;

    param.key= this.state.contact.selectValue;
    console.log("param ==> ", param);
    dispatchRequest(param);
  }

  render() {
    let contactList = [];

    for(let key in contactItem) {
      contactList.push(<option key={key} value={key}>{contactItem[key].label}</option>);
    }
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="contact">
            <SectionTitle title="お問い合わせ" />
            <p>
              お問い合わせありがとうございます。<br />
              返信には最大5営業日ほどお時間をいただく場合があります。<br />
              営業時間：10:00〜19:00（土日祝を除く）<br /><br />
            </p>
            <p>
              お問い合わせの返信につきましては info@kujifes.com よりメールにてお送りいたしますので、
              ドメイン設定の解除などをお願いいたします。<br /><br />
            </p>

            <form name="selectContact">
              <div className="form-inner">
                <dl>
                  <dt className="d-flex d-flex-a-center">お問い合わせ項目</dt>
                  <dd>
                    <span className="select-wrapper">
                      <select name="selectItem" className="select-wrapper__select--contact" type="select" onChange={this.handleChange}>
                        {contactList}
                      </select>
                    </span>
                  </dd>
                </dl>
              </div>

              <div className="d-flex d-flex-center">
                <button type="button" id="button-submit" className="button-submit" onClick={e => this.onSubmit()}>お問い合わせ内容入力へ</button>
              </div>
            </form>

          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
  }
}
function mapDispatchToProps(dispatch) {
  return { dispatchRequest: param => dispatch(selectContact(param, 'login-check')) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);