import React, { Component } from 'react';

class ProductTitle extends Component {
  render() {
    const labelKind = this.props.labelKind;
    const title = this.props.title;

    let titleClassType = "";
    switch(labelKind) {
      case "New":
      case "販売中":
      titleClassType = "sale"
      break;
      case "まもなく終了":
      titleClassType = "closing-soon"
      break;
      case "Coming Soon":
      titleClassType = "coming-soon"
      break;
      case "販売終了":
      titleClassType = "sold-out"
      break;
      default:
      break;
    }
    return (
      <h4 className={`item-card__contents--title item-card__contents--${titleClassType}`}>{title}</h4>
    );
  }
}
export default ProductTitle;