import React, { Component } from 'react';
import { connect } from 'react-redux';
import { registerRequest } from '../actions/actions';
import { questions } from '../common/const';
import { HashLink } from 'react-router-hash-link';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import ButtonIconBack from '../images/common/button_icon_back.png';
import { restfulApiConfig } from '../common/config';
import ReCAPTCHA from "react-google-recaptcha";
import { sendGA } from '../common/helper';

var recaptchaID = "";

class EntryConfirmForm extends Component {

  constructor(props) {
    super(props);
    const { entryForm } = this.props;
    entryForm.errMsg = null;

    this.state = {
      isRecaptcha: false,
    }
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(){
    document.getElementById('button-entry').disabled = !this.state.isRecaptcha;
  }

  componentDidUpdate () {
    document.getElementById('button-entry').disabled = !this.state.isRecaptcha;
  }

  onChange(value){
    recaptchaID = value;
    this.setState({
      isRecaptcha: true,
    });
  }

  onSubmit(){
    const { entryForm, dispatchRequest, recaptchaRef } = this.props;
    entryForm.recaptcha = recaptchaID;
    dispatchRequest(entryForm);
    this.setState({
      isRecaptcha: false,
    });
    recaptchaRef.current.reset();
    recaptchaID = "";
  }

  render(){

    const { entryForm, recaptchaRef } = this.props;

    return (
      <form className="confirm-form" action="#" method="post">
        <div className="form-inner">
          <dl>
            <dt>お名前</dt>
            <dd>{entryForm.surname + " " + entryForm.name}</dd>
          </dl>
          <dl>
            <dt>お名前(フリガナ)</dt>
            <dd>{entryForm.phoneticSurname + " " + entryForm.phoneticName}</dd>
          </dl>
          <dl>
            <dt>郵便番号</dt>
            <dd>{"〒 " + entryForm.postalCode1 + " - " + entryForm.postalCode2}</dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>{entryForm.prefectures  + " " + entryForm.address1 + entryForm.address2}</dd>
          </dl>
          <dl>
            <dt>電話番号</dt>
            <dd>{entryForm.phone1 + " - " + entryForm.phone2 + " - " + entryForm.phone3}</dd>
          </dl>
          <dl>
            <dt>メールアドレス</dt>
            <dd>{entryForm.email}</dd>
          </dl>
          <dl>
            <dt>生年月日</dt>
            <dd>{entryForm.birthYear + "年 " + entryForm.birthMonth + "月 " + entryForm.birthDay + "日"}</dd>
          </dl>
          <dl>
            <dt>パスワード</dt>
              {/* <dd>{entryForm.phash}</dd> */}
            <dd>{entryForm.phash.replace(/./g, '*')}</dd>
          </dl>
          <dl>
            <dt>パスワードを忘れた時のヒント</dt>
            <dd>
              質問&nbsp;:&nbsp;{questions[entryForm.questionType]}<br />
              答え&nbsp;:&nbsp;{entryForm.ahash}
            </dd>
          </dl>
          <dl>
            <dt>お知らせ</dt>
            <dd>
              { entryForm.announce ? "受け取る" : "受け取らない" }
            </dd>
          </dl>
        </div>

        {entryForm.errMsg &&
          <div className="error-box text-error">{entryForm.errMsg}</div>
        }

        <div className="recaptcha">
          <ReCAPTCHA
            className="recaptcha"
            ref={recaptchaRef}
            sitekey={restfulApiConfig.recaptchaID}
            onChange={this.onChange}
          />
        </div><br />

        <div className="button-wrapper">
          <HashLink to="/login#entry" className="header-nav__entry" scroll={el => el.scrollIntoView({ block: 'start' })}>
          <button type="button" className="button-back">
            <img className="button-back__icon" src={ButtonIconBack} alt="<" />
            <span>戻る</span>
          </button>
          </HashLink>
          <div className="button-wrapper--centering">
            <button id="button-entry" onClick={e => this.onSubmit()}  type="button" className={"button-red center"}>会員登録をする</button>
          </div>
        </div>
      </form>
    );
  }
}

class EntryConfirm extends Component {
  componentDidMount(){
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { entryForm, dispatchRequest } = this.props;
    const recaptchaRef = React.createRef();

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="entry-confirm">
            <SectionTitle title="新規会員登録確認ページ" />
            <p className="pc">下記の内容で送信してもよろしいでしょうか？<br />よろしければ、一番下の「会員登録をする」ボタンをクリックしてください。</p>
            <p className="sp">下記の内容で送信してもよろしいでしょうか？</p>
            <EntryConfirmForm entryForm={entryForm} recaptchaRef={recaptchaRef} dispatchRequest={dispatchRequest} />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

// export default EntryConfirm;
function mapStateToProps(state) {
  return {
    entryForm: state.register,
  }
}
function mapDispatchToProps(dispatch) {
  return { dispatchRequest: entryData => dispatch(registerRequest(entryData, 'regist')) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntryConfirm);
