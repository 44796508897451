import store from '../configureStore';
import { PURCHASE_HISTORY_BY_RANGE_SUCCESS, PURCHASE_HISTORY_BY_RANGE_FAILURE } from '../actions/actions';
import { push } from 'react-router-redux';

const initialState = {
  "usid": null,
  "seid": null,
  "hlist":[],
  "sc": null,
};

export default function purchaseHistoryByRange(state=initialState, action) {
  //console.log(action.type, action);

  switch (action.type) {
    case PURCHASE_HISTORY_BY_RANGE_SUCCESS:
      console.log("purchaseHistoryByRange state", state);
      console.log("purchaseHistoryByRange action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload,
      );
    case PURCHASE_HISTORY_BY_RANGE_FAILURE:
      store.dispatch(push('/404'));
      return state;
    default:
      return state;
  }
}