import { CATEGORY_SERIES_LIST_SUCCESS, CATEGORY_SERIES_LIST_FAILURE } from '../actions/actions';

const initialState = {
  seriesList: [],
  endSeriesList: [],
};

export default function top(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_SERIES_LIST_SUCCESS:
      return Object.assign({},
        state,
        action.payload
      );
    case CATEGORY_SERIES_LIST_FAILURE:
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}