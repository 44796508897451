import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import EditForm from '../components/edit-form';
import BackButton from '../components/back-button';

import { connect } from 'react-redux';
import { accountRequest } from '../actions/actions';
import { restfulApiConfig } from '../common/config';
import { sendGA } from '../common/helper';

class UserEditSection extends Component {
  render () {
    return (
      <section className="user-edit">
        <SectionTitle title="登録情報変更" />
        <EditForm account={this.props.account} />
      </section>
    );
  }
}

class CardEditSection extends Component {
  render () {
    const { login, account } = this.props;
    var inputItems = [];
    const param = {
      SiteID: restfulApiConfig.siteID,
      MemberID: login.usid,
      MemberName: "" + account.surname + account.name,
      ShopID: restfulApiConfig.shopID,
      MemberPassString: "" + account.cmp,
      RetURL: restfulApiConfig.apiURL + "mypage-card-edit-complete",
      CancelURL: restfulApiConfig.appURL + "#/user/edit",
      SessionTimeout: 600,
      DateTime: "" + account.cdate,
      UserInfo: (window.innerWidth > 769)? "pc":"sb",
    }
    Object.keys(param).forEach((key, i) => {
      console.log("key", key);
      inputItems.push(<input key={i} type="hidden" name={key} value={param[key]}></input>);
    });
    const url = restfulApiConfig.cardEditURL;
    return (
      <section className="card-edit">
        <SectionTitle title="カード情報編集" />
        <div className="panel">
          <p>カード情報を編集するにはこちらから<br />外部サイトへ遷移します。</p>
          <form action={url} method="post">
            {inputItems}
            <button type="submit" className="button-red">カード情報編集ページへ</button>
          </form>
        </div>
      </section>
    );
  }
}

function UnsubscribeSection() {
  return (
    <section className="unsubscribe">
      <SectionTitle title="退会手続き" />
      <div className="panel">
        <p>削除手続きが完了した時点で、現在保存されている購入履歴や、<br className="pc" />お届け先等の情報は全てなくなりますのでご注意ください。</p>
        <a href="/#/contact"><button className="button-white">お問い合わせへ</button></a>
      </div>
    </section>
  );
}

class UserEdit extends Component {
  constructor(props) {
    super(props);
    console.log("UserEdit props", props);
    const data = {
      usid: this.props.login.usid,
      seid: this.props.login.seid,
    };
    this.props.dispatchRequest(data);
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { login, account } = this.props;
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <UserEditSection account={account} />
          <CardEditSection login={login} account={account} />
          <UnsubscribeSection />
          <div className="edit-back-button-wrapper">
            <BackButton linkTo="/mypage" text="戻る" />
          </div>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {login, account} = state;
  return {
    login: login,
    account: account,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: data => dispatch(accountRequest(data, 'account')),
   }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserEdit));
