import { OID_REGISTER, OID_CLEAR } from '../actions/actions';
const initialState = {
  oid: null,
};


export default function oid(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case OID_REGISTER:
      return Object.assign({},
        state,
        action.payload
      );
    case OID_CLEAR:
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}