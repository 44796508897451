import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import { sendGA } from '../common/helper';
import PCAboutInfo from '../images/pc/pc_about_info.jpg';
import PCAboutCard from '../images/pc/pc_about_card.jpg';
import PCAboutWebMoney from '../images/pc/pc_about_webmoney.jpg';
import SPAboutInfo from '../images/sp/sp_about_info.jpg';
import SPAboutCard from '../images/sp/sp_about_card.jpg';

import PCCvsSe from '../images/pc/pc_cvs_logo_seveneleven.gif';
import PCCvsFm from '../images/pc/pc_cvs_logo_familymart.gif';
import PCCvsLs from '../images/pc/pc_cvs_logo_lawson.gif';
import PCCvsMs from '../images/pc/pc_cvs_logo_ministop.gif';
import PCCvsSm from '../images/pc/pc_cvs_logo_seicomart.gif';

import PCCareerDOCOMO from '../images/pc/pc_docomo.jpg';
import PCCareerAU from '../images/pc/pc_au.jpg';
import PCCareerSOFTBANK from '../images/pc/pc_softbank.jpg';
import SPCareerDOCOMO from '../images/sp/sp_docomo.jpg';
import SPCareerAU from '../images/sp/sp_au.jpg';
import SPCareerSOFTBANK from '../images/sp/sp_softbank.jpg';
import SPAboutWebMoney from '../images/sp/sp_about_webmoney.jpg';

class Contents extends Component {

  render() {
    return (
      <div className="about__contents">
        <SectionTitle title="くじフェス！とは" />
        くじフェス！とは、1回からカンタンに購入できるオンラインくじサービスです。<br />
        ここでしか手に入らないオリジナル商品が当たります。<br />
        <br />
        <img className="pc img-info" src={PCAboutInfo} alt="くじフェス！とは"/><img className="sp img-info" src={SPAboutInfo} alt="くじフェス！とは"/><br />
        <br className="pc" />
        <div style={{textAlign: "right"}}>※プレミアムチャンスがないくじもございます。</div>
        <br />
        <SectionTitle title="お支払い方法" />
        下記のお支払い方法がご利用いただけます。<br />
        ※GMOペイメントゲートウェイ株式会社のページへ遷移します
        <br />
        <br />
        <div className="container">
          <div className="container__child">
            <h3>クレジットカード決済</h3>
            <img className="pc img-card" src={PCAboutCard} alt="クレジットカード決済"/><img className="sp img-card" src={SPAboutCard} alt="クレジットカード決済"/><br />
          </div>
          <div className="container__child">
            <h3 className="career-title">コンビニエンスストア決済</h3>
            <div className="career--wrap">
              <div className="career"><img className="pc" src={PCCvsSe} alt="cvs"/><img className="sp img-career" src={PCCvsSe} alt="セブンイレブン"/></div>
              <div className="career"><img className="pc" src={PCCvsFm} alt="cvs"/><img className="sp img-career" src={PCCvsFm} alt="ファミリーマート"/></div>
              <div className="career"><img className="pc" src={PCCvsLs} alt="cvs"/><img className="sp img-career" src={PCCvsLs} alt="ローソン"/></div>
              <div className="career"><img className="pc" src={PCCvsMs} alt="cvs"/><img className="sp img-career" src={PCCvsMs} alt="ミニストップ"/></div>
              <div className="career"><img className="pc" src={PCCvsSm} alt="cvs"/><img className="sp img-career" src={PCCvsSm} alt="セイコーマート"/></div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="container__child">
            <h3 className="career-title">キャリア決済</h3>
            <div className="career--wrap">
              <div className="career"><img className="pc img-career" src={PCCareerDOCOMO} alt="docomo"/><img className="sp img-career" src={SPCareerDOCOMO} alt="docomo"/></div>
              <div className="career"><img className="pc img-career" src={PCCareerAU} alt="au"/><img className="sp img-career" src={SPCareerAU} alt="au"/></div>
              <div className="career"><img className="pc img-career" src={PCCareerSOFTBANK} alt="softbank"/><img className="sp img-career" src={SPCareerSOFTBANK} alt="softbank"/></div>
            </div>
          </div>
          <div className="container__child">
            <h3>WebMoney（ウェブマネー）</h3>
            <div className="career--wrap">
              <img className="pc img-webmoney" src={PCAboutWebMoney} alt="WebMoney（ウェブマネー）"/><img className="sp img-webmoney" src={SPAboutWebMoney} alt="WebMoney（ウェブマネー）"/>
            </div>
          </div>
        </div>
        <br />
        <br className="pc" />
        <SectionTitle title="運営会社について" />
        <a href="https://goodroid.co.jp/" target="_blank" rel="noopener noreferrer">株式会社GOODROID</a>
      </div>
    );
  }
}

class About extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;
    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="about">
            <Contents />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default About;