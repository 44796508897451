import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { RenderField } from '../components/render-field';
import { required, password, passwordConfirmation } from '../common/validate';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import NormalButton from '../components/normal-button';
import PasswordCard from '../components/password-card';
import { checkCertificateResetRequest, certificateResetRequest } from '../actions/actions';
import { sendGA } from '../common/helper';

class PasswordSetting extends Component {
  constructor(props) {
    super(props);

    const { params } = props.match;
    props.dispatchCheckRequest({rstn: params.rstn});

    this.state = {
      errMsg: null,
      inputForm: {
        rstn: params.rstn,
        phash: "",
      },
    }
    // this.handleChange = this.handleChange.bind(this);
    this.handleSend = this.handleSend.bind(this);
  }

  resetFormFlag = false;
  failureFlag = false;

  handleSend(e) {
    const {invalid, formValues} = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    if(invalid === false) {
      const inputForm = this.state.inputForm;
      inputForm.phash = formValues.phash;

      this.props.dispatchResetRequest(inputForm);
    }
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { checkCertificateReset } = this.props;

    if(checkCertificateReset.errMsg == null){
      this.resetFormFlag = true;
      this.failureFlag = false;
    } else {
      this.resetFormFlag = false;
      this.failureFlag = true;
    }

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <PasswordCard heading="新しいパスワードの入力">
            { this.resetFormFlag &&
              <form name="passwordForm" id="passwordForm" className="password-form">
                <div className="summary">新しいパスワードを入力してください。</div>
                {this.state.errMsg != null && <p className="error-message">{this.state.errMsg}</p>}
                <h4 className="password-card__subheading">新しいパスワード</h4>
                <Field id={'phash'} name="phash" component={RenderField} validate={[required, password]} type="password" className={'password-card__input--normal'} placeholder="6~16文字で半角英字・半角数字の各1文字を含む" />
                <h4 className="password-card__subheading">新しいパスワード確認</h4>
                <Field id={'confirmPhash'} name="confirmPhash" component={RenderField} validate={[required, passwordConfirmation]} type="password" className={'password-card__input--normal'} placeholder="確認のため2度入力してください" />
                <div className="button-wrapper">
                  <button type="button" onClick={this.handleSend} className="button-red">新しいパスワードを設定する</button>
                </div>
              </form>
            }
            { this.failureFlag &&
              <React.Fragment>
                <p>認証メールの有効期限が切れているか不正なURLです。<br />お手数ですが、再度パスワード設定をお願いいたします。</p>
                <NormalButton buttonColor="red" text="パスワード再設定ページへ" linkTo="/password/reissue-mail" />
              </React.Fragment>
            }
          </PasswordCard>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { checkCertificateReset, certificateReset } = state;

  return {
    formValues: getFormValues('passwordForm')(state),
    checkCertificateReset: checkCertificateReset,
    certificateReset: certificateReset,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchCheckRequest: rstn => dispatch(checkCertificateResetRequest(rstn, 'check-certificate-reset')),
    dispatchResetRequest: inputForm => dispatch(certificateResetRequest(inputForm, 'certificate-reset')),
   }
}

PasswordSetting = reduxForm({
  form: 'passwordForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // initialValues: initial,
})(PasswordSetting);


PasswordSetting = connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordSetting);

export default PasswordSetting;