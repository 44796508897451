import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import { push } from 'react-router-redux';
import checkResponse from '../common/checkResponse';
import { LOGOUT_REQUEST, loginClear, purchaseHistoryListClear} from '../actions/actions';

function* handleRequest(action) {
  // console.log("logout action =>",action);

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sxp || payload.sc === 1 ) {
      console.log("success => ",payload);
      yield put(loginClear());
      yield put(purchaseHistoryListClear());
      yield put(push("/login"));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const logoutSaga = [takeEvery(LOGOUT_REQUEST, handleRequest)];
export default logoutSaga;