import React, { Component } from 'react';
import SubmitButton from '../components/submit-button';
import BackButton from '../components/back-button';
import { questions } from '../common/const';
import Sha256 from 'js-sha256';

import { connect } from 'react-redux';
import { userEditRequest } from '../actions/actions';

class UserEditConfirmForm extends Component {
  constructor(props) {
    super(props);
    console.log("UserEditConfirmForm props", this.props);

    const { editForm, userEdit } = this.props;
    this.state = {
      editForm: editForm,
    }

    userEdit.errMsg = null;
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {

    const { login, editForm } = this.props;

    let request = Object.assign({}, editForm, login);
    request.birthYear= ('0000' + request.birthYear).slice(-4);
    request.birthMonth= ('00' + request.birthMonth).slice(-2);
    request.birthDay= ('00' + request.birthDay).slice(-2);
    request.phash = Sha256(request.phash);
    request.ahash = Sha256(request.ahash);
    // var request = {
    //   usid: login.usid,
    //   seid: login.seid,
    //   surname: editForm.surname,
    //   name: editForm.name,
    //   phoneticSurname: editForm.phoneticSurname,
    //   phoneticName: editForm.phoneticName,
    //   phash: Sha256(editForm.phash),
    //   postalCode1: editForm.postalCode1,
    //   postalCode2: editForm.postalCode2,
    //   prefectures: editForm.prefectures,
    //   address1: editForm.address1,
    //   address2: editForm.address2,
    //   phone1: editForm.phone1,
    //   phone2: editForm.phone2,
    //   phone3: editForm.phone3,
    //   announce:	0,
    //   birthYear: ('0000' + editForm.birthYear).slice(-4),
    //   birthMonth: ('00' + editForm.birthMonth).slice(-2),
    //   birthDay: ('00' + editForm.birthDay).slice(-2),
    //   questionType: editForm.questionType,
    //   ahash: Sha256(this.props.editForm.ahash),
    // }
    this.props.dispatchRequest(request);
  }

  componentDidUpdate(prevProps){
    if(prevProps.login !== this.props.login){
      this.props.history.push({ pathname: '/mypage', state: {updateFlag: true}});
    }
  }

  render () {
  const { editForm, userEdit } = this.props;
    return (
      <form className="confirm-form">
        <div className="form-inner">
          <dl>
            <dt>お名前</dt>
            <dd>{editForm.surname + " " + editForm.name}</dd>
          </dl>
          <dl>
            <dt>お名前(フリガナ)</dt>
            <dd>{editForm.phoneticSurname + " " + editForm.phoneticName}</dd>
          </dl>
          <dl>
            <dt>郵便番号</dt>
            <dd>{"〒 " + editForm.postalCode1 + " - " + editForm.postalCode2}</dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>{editForm.prefectures  + " " + editForm.address1 + editForm.address2}</dd>
          </dl>
          <dl>
            <dt>電話番号</dt>
            <dd>{editForm.phone1 + " - " + editForm.phone2 + " - " + editForm.phone3}</dd>
          </dl>
          <dl>
            <dt>メールアドレス</dt>
            <dd>{editForm.email}</dd>
          </dl>
          <dl>
            <dt>生年月日</dt>
            <dd>{editForm.birthYear + "年" + editForm.birthMonth + "月" + editForm.birthDay + "日"}</dd>
          </dl>
          <dl>
            <dt>パスワード</dt>
            <dd>{editForm.phash.replace(/./g, '*')}</dd>
          </dl>
          <dl>
            <dt>秘密の質問</dt>
            <dd>
              質問&nbsp;:&nbsp;{questions[editForm.questionType]}<br />
              答え&nbsp;:&nbsp;{editForm.ahash.replace(/./g, '*')}
            </dd>
          </dl>
          <dl>
            <dt>お知らせ</dt>
            <dd>
              { editForm.announce ? "受け取る" : "受け取らない" }
            </dd>
          </dl>
        </div>

        {userEdit.errMsg &&
          <div className="error-box text-error">{userEdit.errMsg}</div>
        }

        <div className="button-wrapper">
          <BackButton linkTo="/user/edit" text="戻る" />
            <div className="button-wrapper--centering">
              <SubmitButton onClick={this.handleSubmit} text="変更する"/>
            </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    entryForm: state.editForm,
    userEdit: state.userEdit,
    login:state.login,
  }
}
function mapDispatchToProps(dispatch) {
  return { dispatchRequest: entryData => dispatch(userEditRequest(entryData, 'update-account')) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditConfirmForm);