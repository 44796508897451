import { all } from 'redux-saga/effects';
import accountSaga from './accountSaga';
import accountRequestSaga from './accountRequestSaga';
import certificateSaga from './certificateSaga';
import checkSeriesSaga from './checkSeriesSaga';
import loginCheckSaga from './loginCheckSaga';
import loginSaga from './loginSaga';
import logoutSaga from './logoutSaga';
import checkEmailSaga from './checkEmailSaga';
import checkCertificateResetSaga from './checkCertificateResetSaga';
import endSeriesListSaga from './endSeriesListSaga';
import categorySeriesListSaga from './categorySeriesListSaga';
import certificateResetSaga from './certificateResetSaga';
import sendResetPasswordSaga from './sendResetPasswordSaga'
import resultSaga from './resultSaga';
import orderSaga from './orderSaga';
import productSaga from './productSaga';
import registerSaga from './registerSaga';
import topSaga from './topSaga';
import userEditSaga from './userEditSaga';
import purchaseHistorySaga from './purchaseHistorySaga';
import purchaseHistoryByRangeSaga from './purchaseHistoryByRangeSaga';
import purchaseHistoryDetailSaga from './purchaseHistoryDetailSaga';
import purchaseConfirmSaga from './purchaseConfirmSaga';
import contactRequestSaga from './contactRequestSaga';
import inquiryContactSaga from './inquiryContactSaga';
import pendingShipmentSaga from './pendingShipmentSaga';


export default function* rootSaga() {
  yield all([
    ...accountRequestSaga,
    ...accountSaga,
    ...certificateSaga,
    ...checkSeriesSaga,
    ...checkCertificateResetSaga,
    ...certificateResetSaga,
    ...loginCheckSaga,
    ...loginSaga,
    ...logoutSaga,
    ...checkEmailSaga,
    ...categorySeriesListSaga,
    ...endSeriesListSaga,
    ...sendResetPasswordSaga,
    ...resultSaga,
    ...orderSaga,
    ...productSaga,
    ...registerSaga,
    ...topSaga,
    ...userEditSaga,
    ...purchaseHistorySaga,
    ...purchaseHistoryByRangeSaga,
    ...purchaseHistoryDetailSaga,
    ...purchaseConfirmSaga,
    ...contactRequestSaga,
    ...inquiryContactSaga,
    ...pendingShipmentSaga,
  ]);
}
