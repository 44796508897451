import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resultRequest, purchaseHistoryListClear } from '../actions/actions';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import NormalButton from '../components/normal-button';
import PrizeCard from '../components/prize-card';
import CanvasComponent from '../components/canvas-component';
import { getPaymentTypeText, getDateFormat } from '../common/helper';
import { restfulApiConfig } from '../common/config';
import { separate, shuffleOrder } from '../common/utile';
import { imgPath, getLotteryAwardText } from '../common/helper';
import { sendGA } from '../common/helper';

const canvasProps = {
  animeName: 'KujiAnimation',
  atlasName: '',
  times: 0,
  animeData: {},
  additionalManifest: [],
}

///////////////////////////
// Animation用のデータ生成
///////////////////////////
const createAnimeData = function(data){
  let animeData = {
    ltp: data.ltp,
    num: data.num,
    specialEffectTarget: data.specialEffectTarget,
    itemList: [],
    premiumItemList:[],
  };

  // Anime用のitemListデータ生成
  for(let item of data.itemList) {
    let obj = {
      lotteryAwardType: getLotteryAwardText(item.lotteryAwardType),
      rscid: item.rscid,
    };

    let num = item.num;
    while(num--){
      animeData.itemList.push(obj);
    }
  }
  // データシャッフル
  shuffleOrder(animeData.itemList);

  // Anime用のpremiumListデータ生成
  for(let premiumItem of data.premiumItemList) {
    let obj = {
      rscid: premiumItem.rscid,
      hitList: [],
    };

    // プレミアム当選した総数
    let num = premiumItem.num;
    if(num > 0){
      let numList = [...Array(animeData.itemList.length).keys()];
      // console.log("Before: numList ==>", numList);
      // データシャッフル
      shuffleOrder(numList);

      for(let i=0; i<num; i++ ) {
        obj.hitList.push(numList[i]);
      }
    }
    animeData.premiumItemList.push(obj);
  }

  return animeData;
}

const createManifest = function(data, rscdir){
  let addManifest = [];

  let targetList = [data.itemList, data.premiumItemList];

  for(let target of targetList) {
    for(let item of target) {
      addManifest.push({src: imgPath(item.rscid+".jpg", rscdir, "item"), id: item.rscid});
    }
  }

  // 各カードパックのパッケージ画像
  addManifest.push({src: imgPath(data.ltp+".jpg", rscdir, "product"), id: "productImg"});

  return addManifest;
}


class ResultList extends Component {
  render() {
    const { rscdir, lotteryResult } = this.props;
    if(lotteryResult == null) return;
    const { itemList, premiumItemList} = lotteryResult;

    console.log("premiumItemList", premiumItemList);

    let hasPremium = false;
    for(let item of premiumItemList){
      if(item.num > 0) {
        hasPremium = true;
        break;
      }
    }

    return (
      <div className="result-list">

        {/* 特賞がある場合 */}
        {hasPremium &&
          <ul className="result-list__wrapper--special">
            {premiumItemList.map((item, i) => {
              if(item.num > 0){
                return <PrizeCard key={i} rscdir={rscdir} isSpecial={true} isOnce={false} item={item} />;
              }
              return null;
            })}
          </ul>
        }

        {/* 特賞以外(複数回の場合) */}
        {(itemList.length > 1) &&
          <ul className="result-list__wrapper--other">
            {itemList.map((item, i) => {
              return <PrizeCard key={i} rscdir={rscdir} isSpecial={false} isOnce={false} item={item} />;
            })}
          </ul>
        }

        {/* 特賞以外(1回の場合) */}
        {(itemList.length === 1) &&
          <ul className="result-list__wrapper--other-once">
            <PrizeCard rscdir={rscdir} isSpecial={false} isOnce={true} item={itemList[0]}/>
          </ul>
        }
      </div>
    );
  }
}

class Result extends Component {
  render() {
    const { url, login, result } = this.props;
    // console.log("render this.props",this.props);

    let inputItems = [];
    const param = {
      SiteID: restfulApiConfig.siteID,
      MemberID: login.usid,
      MemberName: "" + result.surname + result.name,
      ShopID: restfulApiConfig.shopID,
      OrderID: "" + result.oid,
      MemberPassString: "" + result.smsp,
      RetURL: restfulApiConfig.apiURL + "purhcase-card-edit-complete",
      CancelURL: restfulApiConfig.appURL + "#/result/" + result.oid,
      DateTime: "" + result.sdate,
      UserInfo: (window.innerWidth > 769)? "pc":"sb",
    }
    Object.keys(param).forEach((key, i) => {
      inputItems.push(<input key={i} type="hidden" name={key} value={param[key]}></input>);
    });

    const againURL = "/purchase/confirm/" + result.srid + "/" + result.lotteryResult.ltp + "/" + result.lotteryResult.num;

    return (
      <section className="result">
        <SectionTitle title="抽選結果" />
        <ResultList rscdir={result.rscdir} lotteryResult={result.lotteryResult} />
        <div className="button-wrapper">
          {result.paymentType === 0 &&
            <React.Fragment>
            <div className="mb15">
              <form action={url} method="post">
                {inputItems}
                <button type="submit" className="button-white-middle">今回使用したクレジットカードを登録する</button>
              </form>
            </div>
            <p>次回以降再入力の必要がなくなります。</p>
            </React.Fragment>
          }

          <NormalButton linkTo={againURL} buttonColor="red" text="もう一度くじを引く！" />
        </div>
        <p>
          ご注文ありがとうございます。<br />
          ただいまご注文完了メールを送らせていただきました。<br />
          数時間経ってもメールが届かない場合は、お問い合わせフォームよりお問い合わせください。<br />
          また「迷惑メールフォルダ」などに入っている場合がございますので、ご確認のほどよろしくお願いいたします。
        </p>
      </section>
    );
  }
}

class OrderInfo extends Component {
  render() {
    const orderInfo = this.props.orderInfo;
    return (
      <section className="order-info">
        <SectionTitle title="ご注文情報" />
        <div className="form-inner">
          <dl>
            <dt>ご注文番号</dt>
            <dd>{orderInfo.no}</dd>
          </dl>
          <dl>
            <dt>ご注文日時</dt>
            <dd>{orderInfo.orderDate}</dd>
          </dl>
          <dl>
            <dt>ご注文金額合計</dt>
            <dd>{separate(orderInfo.total)}円</dd>
          </dl>
          <dl>
            <dt>お支払い方法</dt>
            <dd>{orderInfo.paymentMethod}<br className="sp" />{orderInfo.paymentNo && "(" + orderInfo.paymentNo + ")"}</dd>
          </dl>
          <dl>
            <dt>配送先</dt>
            <dd>{orderInfo.addressee}</dd>
          </dl>
          <dl>
            <dt>お届け目安</dt>
            <dd>{orderInfo.scheduledDate}</dd>
          </dl>
        </div>
        <div className="button-wrapper">
          <NormalButton linkTo="/" buttonColor="red" text="トップページへ" />
          <NormalButton linkTo="/mypage" buttonColor="white" text="マイページへ" />
        </div>
      </section>
    );
  }
}

class LotteryResult extends Component {
  constructor(props) {
    super(props);

    console.log("props => ", props);
    console.log("props.isShowAnime => ", props.isShowAnime + "\n\n");
    const { login, match } = this.props;

    if(match.params.oid == null)
      this.props.history.push("/404");

    this.state = {
      isShowAnime: (props.registerOid.oid !== match.params.oid),
    }

    this.props.dispatchHistoryClear();

    const param = {
      oid: match.params.oid,
      usid: login.usid,
      seid: login.seid,
    }
    this.props.dispatchLotteryResultRequst(param);

    this.handleShowAnime = this.handleShowAnime.bind(this);
  }

  handleShowAnime(flag) {
    console.log("call handleShowAnime ------------------- \n\n");

    window.createjs.Ticker.reset();
    //0.7.1だと以下のプロパティが初期化されずに再スタートできなくなる
    //masterだと修正されているのでそのうちいらなくなるっぽい
    window.createjs.Ticker._timerId = null;
    window.createjs.Ticker._inited = false;

    this.setState({isShowAnime: false});
    if(window.exportRoot == null) return;
    //window.exportRoot.funcs.skipAnime();
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { login, result } = this.props;
    const url = restfulApiConfig.cardEditURL;
    const orderInfo = {
      no: result.oid,
      orderDate: getDateFormat(result.purchaseDate),
      total: result.totalPrice,
      paymentMethod: getPaymentTypeText(result.paymentType),
      paymentNo: result.cnb,
      addressee: "〒"+ result.postalCode1 + "-" + result.postalCode2 + " " + result.prefectures + result.address1 + result.address2,
      scheduledDate: result.shippingSchedule,
    }

    // console.log("LotteryResult result ==> ",result, "\n\n");
    // console.log("LotteryResult result ==> ",result.lotteryResult, "\n\n");

    canvasProps.animeData = createAnimeData(result.lotteryResult);
    canvasProps.additionalManifest = createManifest(result.lotteryResult, result.rscdir);
    canvasProps.isShowAnime = this.state.isShowAnime;
    console.log("animeData ==> ", canvasProps.animeData);
    console.log("additionalManifest ==> ", canvasProps.additionalManifest);
    // console.log("url : ",restfulApiConfig.cardEditURL);
    // canvasProps.handler = this.handleShowAnime;

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          {/* <div className="testAnim"></div> */}
          {this.state.isShowAnime &&
            <CanvasComponent {...canvasProps} result={result} onShowAnime={this.handleShowAnime} />
          }
          <div className={[this.state.isShowAnime && 'dNone'].join(' ')}>
            <Result url={url} login={login} result={result} />
            <OrderInfo orderInfo={orderInfo} />
          </div>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login, result, oid } = state;
  return {
    login: login,
    result: result,
    registerOid: oid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchLotteryResultRequst: data => dispatch(resultRequest(data, 'lottery-result')),
    dispatchHistoryClear: () => dispatch(purchaseHistoryListClear()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotteryResult);