import React, { Component } from 'react';

class PasswordCard extends Component {
  render() {
    return (
      <div className="password-card">
        <h3 className="password-card__heading">{this.props.heading}</h3>
        {this.props.children}
      </div>
    );
  }
}
export default PasswordCard;