import { CHECK_SERIES_REQUEST, CHECK_SERIES_SUCCESS, CHECK_SERIES_FAILURE } from '../actions/actions';
const initialState = {
  premiumList: [],
};

export default function checkSeries(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case CHECK_SERIES_REQUEST:
    // console.log("call CHECK_SERIES_REQUEST", action.payload);
      return state;
    case CHECK_SERIES_SUCCESS:
    case CHECK_SERIES_FAILURE:
      console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    default:
      return state;
  }
}