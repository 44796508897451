import { call, put, takeEvery, select} from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { HISTORY_ACCOUNT_REQUEST, historyAccountRequest, accountSuccess, accountFailure, purchaseHistoryRequest, loginClear, CHECK_ACCOUNT_REQUEST } from '../actions/actions';

function* handleLoginCheck(action) {
  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sxp === false && payload.errMsg == null ) {
      const { login } = yield select();
      const param = {
        usid: login.usid,
        seid: login.seid,
      };
      yield put(historyAccountRequest(param, 'account'));
    } else {
      yield put(loginClear(payload));
      yield put(push("/login"));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

function* handleHistoryAccount(action) {
  // const action = yield take(HISTORY_ACCOUNT_REQUEST);
  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(accountSuccess(payload));
      const { login } = yield select();
      const param = {
        usid: login.usid,
        seid: login.seid,
      };
      yield put(purchaseHistoryRequest(param,'purchase-history'));
    } else {
      yield put(loginClear(payload));
      yield put(accountFailure(payload));
      yield put(push("/login"));
    }

  } catch(error) {
    console.log("error ==> ",error);

  }
}

const accountRequestSaga = [
  takeEvery(CHECK_ACCOUNT_REQUEST, handleLoginCheck),
  takeEvery(HISTORY_ACCOUNT_REQUEST, handleHistoryAccount),
  // fork(handleHistoryAccount),
];
export default accountRequestSaga;