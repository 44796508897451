import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { restfulApiConfig } from '../common/config';
import ReCAPTCHA from "react-google-recaptcha";

var recaptchaID = "";
class LoginForm extends Component {

  constructor(props) {
    super(props);
    const { login } = this.props;

    login.errMsg = null;
    this.state = {
      // errors: [
      //   // { name: "mail", message: "メールアドレスを入力してください。" },
      //   // { name: "password", message: "パスワードを入力してください。" }
      // ],
      loginForm: {
        email: "",
        phash: "",
        recaptcha: "",
      },
      isRecaptcha: false,
    }

    // this.setErrors = this.setErrors.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  // setErrors() {
  //   this.setState({
  //   });
  // }

  handleBack() {
    this.props.history.goBack();
  }

  // ログイン入力内容の変更を監視
  handleChange(event) {
    let loginForm = this.state.loginForm;
    switch (event.target.name) {
      case 'login-email':
        loginForm.email = event.target.value;
        break;
      case 'login-phash':
        loginForm.phash = event.target.value;
        break;
      default:
        break;
    }

    // 状態を更新
    this.setState({
      loginForm: loginForm
    });
  }

  onChange(value){
    recaptchaID = value;
    this.setState({
      isRecaptcha: true,
    });
  }

  componentDidMount(){
    let loginForm = this.state.loginForm;
    this.setState({
      loginForm: loginForm
    });
    document.getElementById('button-login').disabled = !this.state.isRecaptcha;
  }

  componentDidUpdate () {
    document.getElementById('button-login').disabled = !this.state.isRecaptcha;
  }

  onSubmit() {
    const { dispatchRequest, recaptchaRef } = this.props;
    let loginForm = this.state.loginForm;
    loginForm.recaptcha = recaptchaID;
    this.setState({
      loginForm: loginForm,
      isRecaptcha: false,
    });
    recaptchaID = "";
    dispatchRequest(this.state.loginForm);
    recaptchaRef.current.reset();
  }

  render() {
    const { login, recaptchaRef } = this.props;

    // console.log("errorMessage ======> ",login.errorMessage);
    return (
      <form>
        <div className="login-form-normal">
          <h3>登録がお済みのお客様</h3>
          <div className="input-wrapper">
            { login.errMsg != null && <div className="text-center text-error">※{login.errMsg}</div> }
            <input type="email" className="input-wrapper__input--normal" name="login-email" placeholder="メールアドレス" value={this.state.loginForm.email} onChange={this.handleChange} />
            <input type="password" className="input-wrapper__input--normal" name="login-phash" placeholder="パスワード" value={this.state.loginForm.phash} onChange={this.handleChange} />
          </div>
          <div className="button-wrapper">
            <ReCAPTCHA
              className="recaptcha"
              ref={recaptchaRef}
              sitekey={restfulApiConfig.recaptchaID}
              onChange={this.onChange}
            /><br />
            <button id="button-login" type="button" onClick={e => this.onSubmit()} className={"button-red"}>ログイン</button>
            {/* SP用ページ内アンカー */}
            <div id="entry-anchor"></div>
            <span>
              <Link to="/password/reissue-mail" className="password-reissue">パスワードをお忘れの方はこちら</Link>
            </span>
          </div>
        </div>
      </form>
    );
  }
}

export default LoginForm;