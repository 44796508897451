import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import Sha256 from 'js-sha256';
import checkResponse from '../common/checkResponse';
import { LOGIN_REQUEST, loginSuccess, loginFailure } from '../actions/actions';

function* handleRequest(action) {
  // console.log("login action =>",action);

  if(action.payload.email === ""){
    action.payload.errMsg = "メールアドレスとパスワードの組み合わせが一致しません";
    yield put(loginFailure(action.payload));
    return;
  }

  let requestData = Object.assign({}, action);
  requestData.payload = Object.assign({}, requestData.payload, action.payload);
  requestData.payload.phash = Sha256(requestData.payload.phash);

  try {
    const response = yield call(api, requestData);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.authorized && payload.errMsg == null ) {
      yield put(loginSuccess(payload));
    } else {
      yield put(loginFailure(payload));
    }

  } catch(error) {
    console.log("error ==> ",error);

  }
}

const loginSaga = [takeEvery(LOGIN_REQUEST, handleRequest)];
export default loginSaga;