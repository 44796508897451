import { push } from 'react-router-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { CHECK_EMAIL_REQUEST, checkEmailReceive, sendResetPasswordValue } from '../actions/actions';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      payload.errMsg = null;
      const data = Object.assign(action.payload, payload);
      yield put(sendResetPasswordValue(data));
      yield put(checkEmailReceive(payload));
      yield put(push('/password/reissue-hint'));
    } else {
      yield put(checkEmailReceive(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const checkEmailSaga = [takeEvery(CHECK_EMAIL_REQUEST, handleRequest)];
export default checkEmailSaga;