import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { imgPath } from '../common/helper';
import CommonModal from '../components/common-modal';
import { comingSoonText } from '../common/const';
import { unixTime2Date } from '../common/utile';

// スライド用アイテム(MAX8)
var slideItems = [];

class TopSlider extends Component {
  constructor(props) {
    super(props);
    this.autoHover = this.autoHover.bind(this);
  }

  // hover時swiperのautoplayを止める
  autoHover() {
    const mySwiper = document.querySelector('.swiper-container').swiper;
    document.querySelector('.swiper-container').addEventListener('mouseenter', function(e){
      if(mySwiper.autoplay != null) {
        mySwiper.autoplay.stop();
      }
    })
    document.querySelector('.swiper-container').addEventListener('mouseleave', function(e){
      if(mySwiper.autoplay != null) {
        console.log("mySwiper.autoplay ==> ", mySwiper.autoPlay);
        mySwiper.autoplay.start();
      }
    })
  }

  render() {
    slideItems = [];
    const params = {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      loop: true,
      speed: 800,
      autoplay: {delay :3000, disableOnInteraction: false},
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides : true,
      onTouchStart: this.hoverSlider,
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      }
    }

    const sc = this.props.sc;
    const topBannerList = this.props.topBannerList;
    const seriesList = this.props.seriesList;


    console.log("sc ==> ", sc);
    for(var i in topBannerList) {
      var imgNum = topBannerList[i];

      for (var j in seriesList) {
        const series = seriesList[j];
        if (series.srid === imgNum) {
          slideItems.push({imgSrc: imgPath(series.rhash + "/img/top/top_header.jpg"), imgAlt: series.title, startTime: series.startTime, linkTo: "/lp/" + series.rscdir + "/"});
          break;
        }
      }
    }

    return (

      <section className="top-slider">
        { (() => {
          if(sc !== 0) return;

          if (slideItems.length > 1) {
            return <Swiper {...params} ref={this.autoHover}>
              {slideItems.map((item, i) => {
                if (item.linkTo === "/lp//") {
                  const startDate = unixTime2Date(item.startTime);
                  return (
                    <div key={i}><CommonModal title={comingSoonText["title"]} color={"green"} message={comingSoonText["message"] + "\n\n公開予定日:\n" + startDate.year + "年" + startDate.month + "月" + startDate.day + "日 (" + startDate.week + ") " + startDate.hour + ":" + startDate.min + "\n\n\n※公開予定日は変更となる場合がございます。"}><img src={item.imgSrc} alt={item.imgAlt} /></CommonModal></div>
                  );
                } else {
                  return <div key={i}><a href={item.linkTo}><img src={item.imgSrc} alt={item.imgAlt} /></a></div>
                }
              })}
            </Swiper>
          } else if (slideItems.length === 1) {
            const item = slideItems[0];
            if (item.linkTo === "/lp//") {
              const startDate = unixTime2Date(item.startTime);
              return <div><CommonModal title={comingSoonText["title"]} color={"green"} message={comingSoonText["message"] + "\n\n公開予定日:\n" + startDate.year + "年" + startDate.month + "月" + startDate.day + "日 (" + startDate.week + ") " + startDate.hour + ":" + startDate.min + "\n\n\n※公開予定日は変更となる場合がございます。"}><img src={item.imgSrc} alt={item.imgAlt} className="single-top-image" /></CommonModal></div>
            } else {
              return <div><a href={item.linkTo}><img src={item.imgSrc} alt={item.imgAlt} className="single-top-image" /></a></div>
            }
          } else {
            return <div><img src={imgPath("default/img/top/top_header.jpg")} alt="" className="single-top-image" /></div>
          }
        }) () }
      </section>
    );
  }
}
export default TopSlider;
