import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import NormalButton from '../components/normal-button';
import { connect } from 'react-redux';
import { certificateRequest } from '../actions/actions';
import { HashLink } from 'react-router-hash-link';
// import ReactGA from 'react-ga';
import { sendGA } from '../common/helper';

function MessageSection(props) {
  let message = "", btn = null;
console.log("props", props);
  if(props.sc != null) {
    if(props.errMsg == null) {
      message = <p>会員登録が完了しました。<br />それではお買い物をお楽しみください。</p>;
      btn     = <NormalButton buttonColor="red" text="トップページへ" linkTo="/" />;
    } else {
      message = <p>認証メールの有効期限が切れています。<br />お手数ですが、再度会員登録をお願いいたします。</p>;
      btn     = <HashLink to="/login#entry" scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}><button type="button" className={"button-red"}>新規会員登録ページへ</button></HashLink>;
    }
  }

  return (
    <section className="entry-pre-complete">
      <SectionTitle title="新規会員登録" />
      {message}
      <div className="button-wrapper">
        {btn}
      </div>
    </section>
  );
}

class Registration extends Component {

  componentDidMount() {
    const match = this.props.match;

    const rgtn = match.params.token;
    console.log('token', match.params.token);
    this.props.dispatchRequest({rgtn});

    const { pathname } = this.props.location;
    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <MessageSection {...this.props} />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  console.log("state",state.certificate);
  return state.certificate;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: token => dispatch(certificateRequest(token, 'certificate'))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);