import { call, put, takeEvery } from 'redux-saga/effects';
import { CATEGORY_SERIES_LIST_REQUEST, categorySeriesListSuccess, categorySeriesListFailure } from '../actions/actions';
// import { CATEGORY_SERIES_LIST_REQUEST, topSuccess, topFailure } from '../actions/actions';
import api from '../common/api';
import checkResponse from '../common/checkResponse';

function* handleRequest(action) {
  console.log("CATEGORY_SERIES_LIST_REQUEST 呼ばれた!!!! ");
  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(categorySeriesListSuccess(payload));
    } else {
      yield put(categorySeriesListFailure(payload));
    }

    // if( payload.sc === 0 && payload.errMsg == null ) {
    //   yield put(topSuccess(payload));
    // } else {
    //   yield put(topFailure(payload));
    // }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const categorySeriesListSaga = [takeEvery(CATEGORY_SERIES_LIST_REQUEST, handleRequest)];
export default categorySeriesListSaga;