import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

let canvas, anim_container, dom_overlay_container;
let AdobeAn;
let createjs;
let animeName = '';

class CanvasComponent extends Component {
  constructor(props) {
    super(props);

    animeName = props.animeName;

    // 指定のアニメーションファイルの読み込み
    // let script = document.createElement("script");
    // script.setAttribute("src", "./anime/" + this.props.animeName + ".js");
    // document.body.appendChild(script);

    this._onShowAnime = this._onShowAnime.bind(this);
  }

  // componentDidMount() {
  // }

  componentDidUpdate() {
    if(canvas != null)  return;

    const { result } = this.props;
    let price = result.totalPrice - (result.shipping + result.tax);

    // GAにe-コマース情報を送る
    ReactGA.plugin.require('ecommerce');
    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: result.oid,
      affiliation: result.seriesTitle,
      revenue: result.totalPrice,
      shipping: result.shipping,
      tax: result.tax,
    });
    ReactGA.plugin.execute('ecommerce', 'addItem', {
      id: result.oid,
      name: result.seriesTitle,
      sku: result.lotteryResult.productId,
      price: price,
      quantity: 1
    });
    ReactGA.plugin.execute('ecommerce', 'send');
    // console.log("ecommerce情報を送信==>", result);

    canvas = document.getElementById("canvas");
    anim_container = document.getElementById("animation_container");
    dom_overlay_container = document.getElementById("dom_overlay_container");
    // console.log("canvas ==> ", canvas);
    // console.log("anim_container ==> ", anim_container);
    // console.log("dom_overlay_container ==> ", dom_overlay_container);
    this.init(this.props);
  }

  _onShowAnime = function(){
    console.log("_onShowAnime this.props => ", this.props);
    this.props.onShowAnime(false);
  }

  render() {
    return (
      <div className={['canvas-wrap', !this.props.isShowAnime && 'dNone'].join(' ')}>
        <div id="animation_container" className="canvas-container">
          <canvas id="canvas" className="canvas" width="100%" height="100%"></canvas>
          <div id="dom_overlay_container" className="canvas-overlay " ></div>
        </div>
      </div>
    );
  }

  // 初期化処理
  init(data) {

    console.log("アニメーション init 呼ばれた");
    AdobeAn = window.AdobeAn;
    createjs = window.createjs;

    let comp=AdobeAn.getComposition(animeName);
    let lib=comp.getLibrary();

    let loader = new createjs.LoadQueue(false);
    const funcObj = {
      skip: this._onShowAnime,
    }
    let fileload = this.handleFileLoad;
    let loadComplete = this.handleComplete;
    loader.addEventListener("fileload", function(evt){fileload(evt,comp)});
    loader.addEventListener("complete", function(evt){loadComplete(evt, comp, funcObj)});
    lib=comp.getLibrary();

    let manifest = lib.properties.manifest.slice(0,1);
    console.log("Before: manifest ==> ",manifest);
    lib.properties.manifest = manifest.concat(data.additionalManifest);
    lib.properties.data = data;

    console.log("lib.properties.manifest ==> ",lib.properties.manifest);
    loader.loadManifest(lib.properties.manifest);
  }

  // imageとidの読み込み
  handleFileLoad(evt, comp) {
    let images=comp.getImages();
    // console.log("images => ",images);
    if (evt && (evt.item.type === "image")) {
      // console.log("evt => ",evt);
      images[evt.item.id] = evt.result;
    }
  }

  // 読み込み完了
  handleComplete(evt, comp, funcObj) {
    //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
    let lib=comp.getLibrary();
    let ss=comp.getSpriteSheet();
    let queue = evt.target;
    let ssMetadata = lib.ssMetadata;

    // console.log("ssMetadata", ssMetadata);
    for(let i=0; i<ssMetadata.length; i++) {
      ss[ssMetadata[i].name] = new createjs.SpriteSheet( {"images": [queue.getResult(ssMetadata[i].name)], "frames": ssMetadata[i].frames} )
    }

    //　文字列で呼び出し
    window.exportRoot = new lib[animeName]();

    console.log("lib.properties.data.animeData",lib.properties.data.animeData);
    window.exportRoot.animeData = lib.properties.data.animeData;
    window.exportRoot.animFunc = funcObj;

    console.log("evt", evt);
    // console.log("_onShowAnime",this._onShowAnime);
    console.log("window.exportRoot.animFunc  => ", window.exportRoot.animFunc, '\n\n\n' );

    // canvasをstageとして登録
    // let stage = new lib.Stage(canvas);
    window.stage = new lib.Stage(canvas);
    let stage = window.stage;

    // console.log("stage",stage);

    //Registers the "tick" event listener.
    // "tick" event listenerの登録(アニメの開始処理)
    let fnStartAnimation = function() {
      // console.log("exportRoot", window.exportRoot);
      stage.addChild(window.exportRoot);
      createjs.Ticker.setFPS(lib.properties.fps);
      createjs.Ticker.addEventListener("tick", stage);
      stage.addEventListener("tick", handleTick);

      function getProjectionMatrix(container, totalDepth) {
        let focalLength = 528.25;
        let projectionCenter = { x : lib.properties.width/2, y : lib.properties.height/2 };
        let scale = (totalDepth + focalLength)/focalLength;
        let scaleMat = new createjs.Matrix2D();
        scaleMat.a = 1/scale;
        scaleMat.d = 1/scale;
        let projMat = new createjs.Matrix2D();
        projMat.tx = -projectionCenter.x;
        projMat.ty = -projectionCenter.y;
        projMat = projMat.prependMatrix(scaleMat);
        projMat.tx += projectionCenter.x;
        projMat.ty += projectionCenter.y;
        return projMat;
      }

      function handleTick(event) {
        let cameraInstance = window.exportRoot.___camera___instance;
        if(cameraInstance !== undefined && cameraInstance.pinToObject !== undefined)
        {
          cameraInstance.x = cameraInstance.pinToObject.x + cameraInstance.pinToObject.pinOffsetX;
          cameraInstance.y = cameraInstance.pinToObject.y + cameraInstance.pinToObject.pinOffsetY;
          if(cameraInstance.pinToObject.parent !== undefined && cameraInstance.pinToObject.parent.depth !== undefined)
          cameraInstance.depth = cameraInstance.pinToObject.parent.depth + cameraInstance.pinToObject.pinOffsetZ;
        }
        applyLayerZDepth(window.exportRoot);
      }
      function applyLayerZDepth(parent)
      {
        let cameraInstance = parent.___camera___instance;
        let focalLength = 528.25;
        var projectionCenter = { 'x' : 0, 'y' : 0};
        if(parent === window.exportRoot)
        {
          let stageCenter = { 'x' : lib.properties.width/2, 'y' : lib.properties.height/2 };
          projectionCenter.x = stageCenter.x;
          projectionCenter.y = stageCenter.y;
        }
        for(var child in parent.children)
        {
          let layerObj = parent.children[child];
          if(layerObj === cameraInstance)
            continue;
          applyLayerZDepth(layerObj, cameraInstance);
          if(layerObj.layerDepth === undefined)
            continue;
          if(layerObj.currentFrame !== layerObj.parent.currentFrame)
          {
            layerObj.gotoAndPlay(layerObj.parent.currentFrame);
          }

          let matToApply = new createjs.Matrix2D;
          let cameraMat = new createjs.Matrix2D;
          let totalDepth = layerObj.layerDepth ? layerObj.layerDepth : 0;
          let cameraDepth = 0;

          if(cameraInstance && !layerObj.isAttachedToCamera)
          {
              let mat = cameraInstance.getMatrix();
              mat.tx -= projectionCenter.x;
              mat.ty -= projectionCenter.y;
              cameraMat = mat.invert();
              cameraMat.prependTransform(projectionCenter.x, projectionCenter.y, 1, 1, 0, 0, 0, 0, 0);
              cameraMat.appendTransform(-projectionCenter.x, -projectionCenter.y, 1, 1, 0, 0, 0, 0, 0);
              if(cameraInstance.depth)
                cameraDepth = cameraInstance.depth;
          }
          if(layerObj.depth)
          {
            totalDepth = layerObj.depth;
          }
          //Offset by camera depth
          totalDepth -= cameraDepth;
          if(totalDepth < -focalLength)
          {
            matToApply.a = 0;
            matToApply.d = 0;
          }
          else
          {
            if (layerObj.layerDepth) {
              let sizeLockedMat = getProjectionMatrix(parent, layerObj.layerDepth);
              if (sizeLockedMat) {
                sizeLockedMat.invert();
                matToApply.prependMatrix(sizeLockedMat);
              }
            }
            matToApply.prependMatrix(cameraMat);
            let projMat = getProjectionMatrix(parent, totalDepth);
            if (projMat) {
              matToApply.prependMatrix(projMat);
            }
          }
          layerObj.transformMatrix = matToApply;
        }
      }
    }

    // Code to support hidpi screens and responsive scaling.
    // hidpi画面と反応的なスケーリングをサポートするコード。
    function makeResponsive(isResp, respDim, isScale, scaleType) {
      var lastW, lastH, lastS=1;
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
      function resizeCanvas() {
        let w = lib.properties.width, h = lib.properties.height;
        let iw = window.innerWidth, ih=window.innerHeight;
        let pRatio = window.devicePixelRatio || 1, xRatio=iw/w, yRatio=ih/h, sRatio=1;
        if(isResp) {
          if((respDim==='width'&&lastW===iw) || (respDim==='height'&&lastH===ih)) {
            sRatio = lastS;
          }
          else if(!isScale) {
            if(iw<w || ih<h)
              sRatio = Math.min(xRatio, yRatio);
          }
          else if(scaleType===1) {
            sRatio = Math.min(xRatio, yRatio);
          }
          else if(scaleType===2) {
            sRatio = Math.max(xRatio, yRatio);
          }
        }
        canvas.width = w*pRatio*sRatio;
        canvas.height = h*pRatio*sRatio;
        // canvas.style.width = dom_overlay_container.style.width = anim_container.style.width =  w*sRatio+'px';
        // canvas.style.height = anim_container.style.height = dom_overlay_container.style.height = h*sRatio+'px';
        stage.scaleX = pRatio*sRatio;
        stage.scaleY = pRatio*sRatio;
        lastW = iw; lastH = ih; lastS = sRatio;
        stage.tickOnUpdate = false;
        stage.update();
        stage.tickOnUpdate = true;
      }
    }
    makeResponsive(false,'both',false,1);

    AdobeAn.compositionLoaded(lib.properties.id);
    fnStartAnimation();
  }
}

CanvasComponent.propTypes = {
  onShowAnime: PropTypes.func.isRequired,
};

export default CanvasComponent;