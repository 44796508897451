import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ButtonIconBack from '../images/common/button_icon_back.png';

class BackButton extends Component {
  render() {
    return (
      <Link to={this.props.linkTo}>
        <button type="button" className="button-back">
          <img className="button-back__icon" src={ButtonIconBack} alt="<" />
          <span>{this.props.text}</span>
        </button>
      </Link>
    );
  }
}
export default BackButton;