import React, { Component } from 'react';

class FlashMessage extends Component {
  render () {
    return (
      <div className="flash-message">
        <div className="flash-message__inner">
          <span>{this.props.message}</span>
        </div>
      </div>
    );
  }
}

export default FlashMessage;