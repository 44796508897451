import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import NormalButton from '../components/normal-button';
import PageTop from '../components/page-top';
import iconPageError from '../images/common/roid.png';
import { sendGA } from '../common/helper';

function MessageSection() {
  return (
    <section className="entry-complete">
      <div className="error-inner">
        <h2>404 Not Found</h2>
        <h4>お探しのページは見つかりませんでした</h4>
        <h6>あなたがアクセスしようとしたページは削除されたか、URLが変更されているため<br />見つけることができません。<br />お手数ですが、トップページより再度お試しください。</h6>
        <img className="sp error-icon-sp" src={iconPageError} alt="" />
        <img className="pc error-icon-pc" src={iconPageError} alt="" />
        <div className="button-wrapper">
          <NormalButton buttonColor="white" text="トップページへ" linkTo="/" />
        </div>
      </div>
    </section>
  );
}

class Error extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <MessageSection />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default Error;