import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { ORDER_REQUEST, orderSuccess, orderFailure, loginClear } from '../actions/actions';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if(payload.sxp) {
      yield put(loginClear());
      yield put(push("/login"));
    }

    if(payload.errMsg == null ) {
      yield put(orderSuccess(payload));
    } else {
      yield put(orderFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const orderSaga = [takeEvery(ORDER_REQUEST, handleRequest)];
export default orderSaga;