import React, { Component } from 'react';
import ButtonTwitterIcon from '../images/common/button_twitter_icon.png';
// import BadgeSP from '../images/common/badge_special.png';
// import BadgeS from '../images/common/badge_s.png';
// import BadgeA from '../images/common/badge_a.png';
// import BadgeB from '../images/common/badge_b.png';
// import BadgeC from '../images/common/badge_c.png';
// import BadgeD from '../images/common/badge_d.png';
// import BadgeE from '../images/common/badge_e.png';
// import BadgeF from '../images/common/badge_f.png';
// import BadgeG from '../images/common/badge_g.png';
// import BadgeH from '../images/common/badge_h.png';

// import { TwitterShareButton } from 'react-share';
import { TWITTER_USER } from '../common/const';
import { restfulApiConfig } from '../common/config';
import { dynamicLinksApi } from '../common/dynamicLinksApi';

const shareHandleClick = function(e, data) {
  dynamicLinksApi(data);
}

class PrizeCardDetail extends Component {
  render() {
    // const badges = {
    //   "SP": BadgeSP,
    //   "S": BadgeS,
    //   "A": BadgeA,
    //   "B": BadgeB,
    //   "C": BadgeC,
    //   "D": BadgeD,
    //   "E": BadgeE,
    //   "F": BadgeF,
    //   "G": BadgeG,
    //   "H": BadgeH,
    // }
    const item = this.props.item;
    // const shareUsername = TWITTER_USER;
    // const shareMessage = item.title + 'があたったよ！';
    // const shareUrl = restfulApiConfig.apiURL + 'share?rscdir=' + item.rscdir + '&rscid=' + item.rscid;

    let tagText = "";
    for(let i in item.snsTagList) {
      tagText += '%23' + item.snsTagList[i] + '%20';
    }
    const shareData = {
      username: '@' + TWITTER_USER,
      message: item.title + 'があたったよ！',
      url: restfulApiConfig.apiURL + 'share?rscdir=' + item.rscdir + '%26rscid=' + item.rscid,
      hashTag: tagText,
    };

    return (
      <li className="prize-card-detail">
        <div className="prize-card-detail__img">
          <img src={item.imgSrc} alt={item.prizeCode + "賞"} />
          {/* <div className="prize-card-detail__img--badge">
            <img src={badges[item.prizeCode]} alt={item.prizeCode + "賞"} />
          </div> */}
        </div>
        <div className="prize-card-detail__contents">
          <h3 className="prize-card-detail__prize-name">{item.prizeName}賞</h3>
          <h4 className="prize-card-detail__title">{item.title}</h4>
            <span className="pc prize-card-detail__amount"><span className="prize-card-detail__amount--bold">{item.amount}</span>個</span>
            <div className="sp prize-card-detail__button-wrapper">
              <span className="prize-card-detail__amount"><span className="prize-card-detail__amount--bold">{item.amount}</span>個</span>
              {/* <TwitterShareButton title={shareMessage} via={shareUsername} url={shareUrl} hashtags={item.snsTagList}>
                <img src={ButtonTwitterIcon} alt="" />
                <span>シェアする</span>
              </TwitterShareButton> */}
              <button className="share-button share-button--size_s share-button--twitter" onClick={e=> shareHandleClick(e, shareData)}>
                <img src={ButtonTwitterIcon} alt="" />
                <span>シェアする</span>
              </button>
            </div>
            { item.note ?
              <p className="prize-card-detail__note">{item.note}</p>
              :
              null
            }
          </div>
          <div className="pc prize-card-detail__button-wrapper">
            {/* <TwitterShareButton title={shareMessage} via={shareUsername} url={shareUrl} hashtags={item.snsTagList}>
              <img src={ButtonTwitterIcon} alt="" />
              <span>シェア</span>
            </TwitterShareButton> */}
            <button className="share-button share-button--size_s share-button--twitter" onClick={e=> shareHandleClick(e, shareData)}>
              <img src={ButtonTwitterIcon} alt="" />
              <span>シェアする</span>
            </button>
        </div>
      </li>
    );
  }
}
export default PrizeCardDetail;