import React, { Component } from 'react';
import { formatInfoDate } from '../common/utile';

class InfoItem extends Component {
  render() {
    const item = this.props.item;
    return (
      <React.Fragment>
        {this.props.isPCOnly ?
        <li className="pc info__item">
          <a href={item.linkTo} target="_blank" rel="noopener noreferrer">
            <span className="info__item--date">{formatInfoDate(item.date)}</span>
            <span className="info__item--text">{item.text}</span>
          </a>
        </li>
        :
        <li className="info__item">
          <a href={item.linkTo} target="_blank" rel="noopener noreferrer">
            <span className="info__item--date">{formatInfoDate(item.date)}</span>
            <span className="info__item--text">{item.text}</span>
          </a>
        </li>
        }
      </React.Fragment>
    );
  }
}
export default InfoItem;