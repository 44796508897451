import store from '../configureStore';
import { RESULT_SUCCESS, RESULT_FAILURE } from '../actions/actions';
import { push } from 'react-router-redux';

const initialState = {
  lotteryResult: {
    specialEffectTarget: "",
    itemList: [],
    premiumItemList: [],
  }
};

export default function result(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case RESULT_SUCCESS:
      action.payload.showModal = true;
      return Object.assign({},
        state,
        action.payload
      );
    case RESULT_FAILURE:
      store.dispatch(push('/404'));
      return state;
    default:
      return state;
  }
}