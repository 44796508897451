import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { CHECK_SERIES_REQUEST, checkSeriesSuccess, checkSeriesFailure } from '../actions/actions';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(checkSeriesSuccess(payload));
    } else {
      yield put(checkSeriesFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const checkSeriesSaga = [takeEvery(CHECK_SERIES_REQUEST, handleRequest)];
export default checkSeriesSaga;