import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import ContactAddressForm from '../components/contact-address-form';
import ContactEmailForm from '../components/contact-email-form';
import ContactWithdrawForm from '../components/contact-withdraw-form';
import ContactOtherForm from '../components/contact-other-form';

import { contactItem } from '../common/const'
import { inquiryContactRequest, pendingShipmentLoginCheck } from '../actions/actions';

// 登録情報
class RegistrationInfoSection extends Component {
  render () {

    // console.log("account: this.props" , this.props);
    const account = this.props.account;
    return (
      <section className="registration-info">
        <h2 className="section-title">
          <span>登録情報</span>
        </h2>
        <div className="form-inner-wrap">
          <div className="form-inner">
            <dl>
              <dt>お名前</dt>
              <dd>{account.surname + " " + account.name}</dd>
            </dl>
            <dl>
              <dt>住所</dt>
              <dd>{"〒" + account.postalCode1 + "-" + account.postalCode2 + " " + account.prefectures + account.address1 + account.address2}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{account.phone1 + "-" + account.phone2 + "-" + account.phone3}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{account.email}</dd>
            </dl>
            <dl>
              <dt>生年月日</dt>
              <dd>{account.birthYear + "年" + account.birthMonth + "月" + account.birthDay + "日"}</dd>
            </dl>
          </div>
        </div>
      </section>
    );
  }
}


class ContactReception extends Component {
  constructor(props) {
    super(props);

    const { login, match, } = this.props;
    const { key } = match.params;

    if((key <= 0) || (contactItem[key] == null)) {
      this.props.history.push("/404");
      return;
    }

    this.state = {
      key: key,
    }

    const { usid, seid } = login;
    const data = {
      usid: usid,
      seid: seid,
    };
    props.dispatchPendingShipmentLoginCheckRequest(data);
  }


  render() {
    const { login, account, pendingShipment, dispatchFormRequest} = this.props;
    const key = this.state.key;
    const isShowInfo = (key === "1" || key === "2");


    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          {isShowInfo &&
            <RegistrationInfoSection account={account} />
          }
          <SectionTitle title={contactItem[key].label} />

          { key === '1' ?
            <ContactAddressForm dispatchFormRequest={dispatchFormRequest} api={contactItem[key].api} login={login} account={account} pendingShipment={pendingShipment} />
          : key === '2' ?
            <ContactEmailForm dispatchFormRequest={dispatchFormRequest} api={contactItem[key].api} login={login} />
          : key === '3' ?
            <ContactWithdrawForm dispatchFormRequest={dispatchFormRequest} api={contactItem[key].api} login={login} />
          : key === '100' ?
            <ContactOtherForm dispatchFormRequest={dispatchFormRequest} api={contactItem[key].api} login={login} />
          :
            <div></div>
          }
        </div>
        <PageTop />
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { login, account, pendingShipment } = state;

  return {
    login: login,
    account: account,
    pendingShipment: pendingShipment,
  }
}
function mapDispatchToProps(dispatch) {
  console.log("mapDispatchToProps => ", this.state);
  return {
    dispatchFormRequest: (data, api) => dispatch(inquiryContactRequest(data, api)),
    dispatchPendingShipmentLoginCheckRequest: data => dispatch(pendingShipmentLoginCheck(data, 'login-check')),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactReception);