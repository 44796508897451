import { CHECK_EMAIL_RECEIVE } from '../actions/actions';
const initialState = {
  errMsg: null,
};

export default function login(state=initialState, action) {

  switch (action.type) {
    case CHECK_EMAIL_RECEIVE:
      console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    default:
      return state;
  }
}
