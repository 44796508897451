import { call, put, takeEvery, select} from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { SELECT_CONTACT, accountRequest, accountSuccess, accountFailure, loginClear, CHECK_ACCOUNT_REQUEST, loginSuccess } from '../actions/actions';

// ログインcheck
function* handleLoginCheck(action) {

  const key = action.payload.key;
console.log("ログインチェック リクエスト", action);
  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if( payload.sxp === false && payload.errMsg == null ) {
      // const { login } = yield select();
      // const param = {
      //   usid: login.usid,
      //   seid: login.seid,
      // };

      yield put(loginSuccess(payload));
      console.log("push 遷移");
      yield put(push("/contact/reception/" + key));

      console.log('payload==>', payload);
      // yield put(accountRequest(param, 'account'));
    } else {
      yield put(loginClear(payload));
      yield put(push("/login"));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

// function* handleRequestAccount(action) {
//   // const action = yield take(HISTORY_ACCOUNT_REQUEST);
//   try {
//     const response = yield call(api, action);
//     const payload = yield response.json();
//     if(!checkResponse(response, payload)) return;

//     if( payload.sc === 0 && payload.errMsg == null ) {
//       yield put(accountSuccess(payload));
//       const { login } = yield select();
//       const param = {
//         usid: login.usid,
//         seid: login.seid,
//       };
//       yield put(purchaseHistoryRequest(param,'purchase-history'));
//     } else {
//       yield put(loginClear(payload));
//       yield put(accountFailure(payload));
//       yield put(push("/login"));
//     }

//   } catch(error) {
//     console.log("error ==> ",error);
//   }
// }

const contactRequestSaga = [
  takeEvery(SELECT_CONTACT, handleLoginCheck),
  // takeEvery(HISTORY_ACCOUNT_REQUEST, handleRequestAccount),
  // fork(handleHistoryAccount),
];
export default contactRequestSaga;