import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export class RenderField extends Component {
  render() {
    const { id, className, type, input, placeholder, maxLength, meta: {touched, error} } = this.props;

    // console.log("this.props =>",this.props);
    // console.log("error =>", error);
    // console.log("input =>", input);
    return (
      <React.Fragment>
        { type === 'textarea' ?
          <textarea id={id} className={className} {...input} type={type} placeholder={placeholder} maxLength={maxLength} />
        : type === 'select' ?
          <span className="select-wrapper">
            <select id={id} className={className} {...input} type={type} placeholder={placeholder}>{this.props.children}</select>
          </span>
        :
          <input id={id} className={className} {...input} type={type} placeholder={placeholder} />
        }
        {touched && error && <p className="text-error">{error}</p>}
      </React.Fragment>
    );
  }
};

export const renderCheckbox = ({ input, label }) => (
  <Checkbox label={label}
    checked={input.value ? true : false}
    onChange={input.onChange}/>
)


export class BaseCheckbox extends Component {

  render() {
    const { input, label, id, checked, onChange, meta: { touched, error }, ...custom } = this.props;
    // console.log("input", input);
// console.log("custom", custom);
// console.log("onChange", onChange);

    return (
      <FormControlLabel
        control={<Checkbox
          // {...custom}
          name={input.name}
          checked={checked}
          // checked={input.value ? true : false}
          id={id}
          onChange={input.onChange}
          value={id}
        />}
        label={<div className="mh2" dangerouslySetInnerHTML={{ __html: label }} />}
      />
    );
  }
}
BaseCheckbox.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape().isRequired,
};


export class ConfirmField extends Component {
  render() {
    const { id, className, type, input, placeholder, meta: {touched, error} } = this.props;

    const handlerClipBord= (e) => {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }

    return (
      <React.Fragment>
        <input id={id} className={className} {...input} type={type} placeholder={placeholder} onCopy={handlerClipBord} onPaste={handlerClipBord} onContextMenu={handlerClipBord} />
        {touched && error && <p className="text-error">{error}</p>}
      </React.Fragment>
    );
  }
}

export const postalCodeFields = (fields) => (

// export class postalCodeFields extends Component {
//   render() {
//       return (
  <React.Fragment>
    <div className="form-inner__postalcode-wrapper">
      <label className="postal-code-label" htmlFor="postal-code">〒&nbsp;</label>
      <input {...fields.postalCode1.input} className="p-postal-code form-inner__input--postalcode" maxLength="3"
      onBlur={
        e => {
          // e.preventDefault();
          console.log("onBlur CALL 1 ====================== ");
          console.log("fields ==> ", fields)
          // console.log("forms ==> ",document.forms);
          let pref = document.getElementById('prefectures');
          let add1 = document.getElementById('address1');
          // let add2 = document.getElementById('address2');

          let prefform = pref.attributes.id.ownerElement;
          let addform1 = add1.attributes.value.ownerElement;
          // let addform2 = add2.attributes.value.ownerElement;

          pref.setAttribute('value', prefform.value);
          add1.setAttribute('value', addform1.value);
          // add2.setAttribute('value', addform2.value);

          // console.log("touched ==> ", fields.prefectures.meta.touched);
          fields.alldata.prefectures = prefform.value;
          fields.alldata.address1 = addform1.value;
          // fields.alldata.address2 = "";


          if(!(prefform.value === "都道府県" || prefform.value === "")) {
            fields.allMeta.prefectures = { visited: true };
          }
          if(addform1.value !== "") {
            fields.allMeta.address1 = { visited: true };
          }
          console.log("fields.allMeta => ", fields.allMeta);

          fields.postalCode1.input.onBlur(e);
        }
      }/>
      <span>-</span>
      <input {...fields.postalCode2.input} className="p-postal-code form-inner__input--postalcode" maxLength="4"
      onBlur={
        e => {
          // e.preventDefault();
          console.log("onBlur CALL 2 ====================== ");
          let pref = document.getElementById('prefectures');
          let add1 = document.getElementById('address1');
          // let add2 = document.getElementById('address2');

          let prefform = pref.attributes.id.ownerElement;
          let addform1 = add1.attributes.value.ownerElement;
          // let addform2 = add2.attributes.value.ownerElement;

console.log("addform1.value => ", addform1.value);

          pref.setAttribute('value', prefform.value);
          add1.setAttribute('value', addform1.value);
          // add2.setAttribute('value', addform2.value);

          fields.alldata.prefectures = prefform.value;
          fields.alldata.address1 = addform1.value;
          // fields.alldata.address2 = "";

          console.log("prefform.value ==> ", prefform.value);

          if(!(prefform.value === "都道府県" || prefform.value === "")) {
            fields.allMeta.prefectures = { visited: true };
          }
          if(addform1.value !== "") {
            console.log("meta更新 ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝");
            fields.allMeta.address1 = { visited: true };
          }


          console.log("allMeta ==> ", fields.allMeta);


          fields.postalCode2.input.onBlur(e);
        }
      }/>
    </div>
    {fields.postalCode1.meta.touched && fields.postalCode1.meta.error && <p className="text-error">{fields.postalCode1.meta.error}</p>}
    {fields.postalCode2.meta.touched && fields.postalCode2.meta.error && <p className="text-error">{fields.postalCode2.meta.error}</p>}
    {/* {fields.address2.meta.touched && fields.address2.meta.error && <p className="text-error">{fields.address2.meta.error}</p>} */}
  </React.Fragment>

//     )
//   }
// };
)

export class AddressField extends Component {
  render() {
    const { id, className, type, input, placeholder, meta: {touched, error} } = this.props;
    console.log("AddressField this.props => ",this.props);
    return (
      <React.Fragment>
        <input id={id} className={className} {...input} type={type} placeholder={placeholder}
        onBlur={
          e => {
            // const o = {};
            // o['address2'] = e.target.value;
            // console.log("address this.props ==> ", this.props);
            console.log("address e ==> ", e);
            // this.setState(o);
            input.onChange(e);

            // input.onBlur(e);
          }
        }
        />
        {touched && error && <p className="text-error">{error}</p>}
      </React.Fragment>
    );
  }
};

export const phoneFields = (fields) => (
  <React.Fragment>
    <div className="form-inner__postalcode-wrapper">
      <input {...fields.phone1.input} className="form-inner__input--tel" maxLength="5" type="tel"/>
      <span>-</span>
      <input {...fields.phone2.input} className="form-inner__input--tel" maxLength="4" type="tel"/>
      <span>-</span>
      <input {...fields.phone3.input} className="form-inner__input--tel" maxLength="4" type="tel"/>
    </div>
    {fields.phone1.meta.touched && fields.phone1.meta.error && <p className="text-error">{fields.phone1.meta.error}</p>}
    {fields.phone2.meta.touched && fields.phone2.meta.error && <p className="text-error">{fields.phone2.meta.error}</p>}
    {fields.phone3.meta.touched && fields.phone3.meta.error && <p className="text-error">{fields.phone3.meta.error}</p>}
  </React.Fragment>
)

export const nameFields = (fields) => (
  <React.Fragment>
    {console.log("fields",fields)}
    { fields.names.map((item, i ) => {
      return (
        <input {...fields[item].input} key={i} className={fields.className} maxLength={fields.maxLength} type={fields.type} placeholder={fields.data[i].placeholder} />
      );
    }) }
    { fields.names.map((item, i ) => {
      return (
        fields[item].meta.touched && fields[item].meta.error && <p key={i} className="text-error">{fields[item].meta.error}</p>
      );
    }) }
    {/* <input {...fields.surname.input} className="form-inner__input--name" maxLength="fields.maxLength" type="text" placeholder="姓" />
    <input {...fields.name.input} className="form-inner__input--name" maxLength="fields.maxLength" type="text" placeholder="名" /> */}
    {/* {fields.surname.meta.touched && fields.surname.meta.error && <p className="text-error">{fields.surname.meta.error}</p>}
    {fields.name.meta.touched && fields.name.meta.error && <p className="text-error">{fields.name.meta.error}</p>} */}
  </React.Fragment>
)

const years = Array.from({ length: 119 }, (v, k) => k + 1900).reverse();
const months = Array.from({ length: 12 }, (v, k) => k + 1);
const days = Array.from({ length: 31 }, (v, k) => k + 1);

export const birthFields = (fields) => (
  <React.Fragment>
  <div className="form-inner__birthday-wrapper">
    <span className="select-number-wrapper">
      <span className="select-number-bg">
        {/* <select {...fields[fields.names[0]].input} id={fields.names[0]} name={fields.names[0]} component="select" className={'select-number-bg__select--number'}> */}
        <select {...fields[fields.names[0]].input} type={fields.type} className={'select-number-bg__select--number'}>
          <option value=""></option>
          {years.map((year, i) => {
            return <option key={i} value={year}>{year}</option>;
          })}
        </select>
      </span>
      <span className="text">年</span>
    </span>
    <span className="select-number-wrapper">
      <span className="select-number-bg">
        {/* <select id={fields.names[1]} name={fields.names[1]} component="select" className={'select-number-bg__select--number'}> */}
        <select {...fields[fields.names[1]].input} type={fields.type} className={'select-number-bg__select--number'}>
          <option value=""></option>
          {months.map((month, i) => {
            return <option key={i} value={month}>{month}</option>;
          })}
        </select>
      </span>
      <span className="text">月</span>
    </span>
    <span className="select-number-wrapper">
      <span className="select-number-bg">
        {/* <select id={fields.names[2]} name={fields.names[2]} component="select" className={'select-number-bg__select--number'}>
       */}
        <select {...fields[fields.names[2]].input} type={fields.type} className={'select-number-bg__select--number'}>
          <option value=""></option>
          {days.map((day, i) => {
            return <option key={i} value={day}>{day}</option>;
          })}
        </select>
      </span>
      <span className="text">日</span>
    </span>
  </div>
  { fields.names.map((item, i ) => {
    console.log("item ==>", fields[item]);
    return (
      fields[item].meta.touched && fields[item].meta.error && <p key={i} className="text-error">{fields[item].meta.error}</p>
    );
  }) }
  </React.Fragment>
)

export const inputFields = (fields) => (
  <React.Fragment>
    { fields.names.map((item, i ) => {
      return (
        <input {...fields[item].input} key={i} className={fields.className} maxLength={fields.maxLength} type={fields.type} placeholder={fields.data[i].placeholder} />
      );
    }) }
    { fields.names.map((item, i ) => {
      return (
        fields[item].meta.touched && fields[item].meta.error && <p key={i} className="text-error">{fields[item].meta.error}</p>
      );
    }) }
    {/* <input {...fields.surname.input} className="form-inner__input--name" maxLength="fields.maxLength" type="text" placeholder="姓" />
    <input {...fields.name.input} className="form-inner__input--name" maxLength="fields.maxLength" type="text" placeholder="名" /> */}
    {/* {fields.surname.meta.touched && fields.surname.meta.error && <p className="text-error">{fields.surname.meta.error}</p>}
    {fields.name.meta.touched && fields.name.meta.error && <p className="text-error">{fields.name.meta.error}</p>} */}
  </React.Fragment>
)

/// 削除予定
// export const inputAdressFields = (fields) => (
//   <React.Fragment>
//     <input {...fields.surname.input} className="form-inner__input--name" maxLength="15" type="text" placeholder="姓" />
//     {fields.surname.meta.touched && fields.surname.meta.error && <p className="text-error">{fields.surname.meta.error}</p>}
//   </React.Fragment>
// )

export class horizontalFields extends Component {
  render() {
    const {fields} = this.props;
    console.log("fields ====>", fields);
    return (
      <React.Fragment>
        <input {...fields.surname.input} className="form-inner__input--name" maxLength="15" type="text" placeholder="姓" />
        <input {...fields.name.input} className="form-inner__input--name" maxLength="15" type="text" placeholder="名" />
        {fields.surname.meta.touched && fields.surname.meta.error && <p className="text-error">{fields.surname.meta.error}</p>}
        {fields.name.meta.touched && fields.name.meta.error && <p className="text-error">{fields.name.meta.error}</p>}
      </React.Fragment>
    )
  };
}











// var t=[],YubinBango;!function(YubinBango){var n=function(){function n(t,n){if(void 0===t&&(t=""),
// this.URL="https://yubinbango.github.io/yubinbango-data/data",
// this.g=[null,"北海道","青森県","岩手県","宮城県","秋田県","山形県","福島県","茨城県","栃木県","群馬県","埼玉県","千葉県","東京都","神奈川県","新潟県","富山県","石川県","福井県","山梨県","長野県","岐阜県","静岡県","愛知県","三重県","滋賀県","京都府","大阪府","兵庫県","奈良県","和歌山県","鳥取県","島根県","岡山県","広島県","山口県","徳島県","香川県","愛媛県","高知県","福岡県","佐賀県","長崎県","熊本県","大分県","宮崎県","鹿児島県","沖縄県"],t)
// {var e=t.replace(/[０-９]/g,function(t){return String.fromCharCode(t.charCodeAt(0)-65248)}),
// r=e.match(/\d/g),
// o=r.join(""),
// i=this.h(o);
// i?this.i(i,n):n(this.j())}}return n.prototype.h=function(t){if(7===t.length)return t},n.prototype.j=function(t,n,e,r,o){return void 0===t&&(t=""),void 0===n&&(n=""),void 0===e&&(e=""),void 0===r&&(r=""),void 0===o&&(o=""),{k:t,region:n,l:e,m:r,o:o}},n.prototype.p=function(t){return t&&t[0]&&t[1]?this.j(t[0],this.g[t[0]],t[1],t[2],t[3]):this.j()},n.prototype.q=function(t,n){window.$yubin=function(t){return n(t)};var e=document.createElement("script");e.setAttribute("type","text/javascript"),e.setAttribute("charset","UTF-8"),e.setAttribute("src",t),document.head.appendChild(e)},n.prototype.i=function(n,e){var r=this,o=n.substr(0,3);return o in t&&n in t[o]?e(this.p(t[o][n])):void this.q(this.URL+"/"+o+".js",function(i){return t[o]=i,e(r.p(i[n]))})},n}();YubinBango.Core=n}(YubinBango||(YubinBango={}));var n=["Japan","JP","JPN","JAPAN"],
// e=["p-region-id","p-region","p-locality","p-street-address","p-extended-address"],
// YubinBango;!function(YubinBango){var t=function(){function t(){this.s()}return t.prototype.s=function(){var n=this,e=document.querySelectorAll(".h-adr");[].map.call(e,function(e){if(n.t(e)){var r=e.querySelectorAll(".p-postal-code");
// r[r.length-1].addEventListener("keyup",function(e){t.prototype.u(n.v(e.target.parentNode))},!1)}})},t.prototype.v=function(t){return"FORM"===t.tagName||t.classList.contains("h-adr")?t:this.v(t.parentNode)},t.prototype.t=function(t){var e=t.querySelector(".p-country-name"),r=[e.innerHTML,e.value];return r.some(function(t){return n.indexOf(t)>=0})},t.prototype.u=function(t){var n=this,e=t.querySelectorAll(".p-postal-code");new YubinBango.Core(this.A(e),function(e){return n.B(t,e)})},t.prototype.A=function(t){return[].map.call(t,function(t){return t.value}).reduce(function(t,n){return t+n})},t.prototype.B=function(t,n){var r=[this.C,this.D];r.map(function(r){return e.map(function(e){return r(e,t,n)})})},t.prototype.C=function(t,n,e){if(e){var r=n.querySelectorAll("."+t);[].map.call(r,function(t){return t.value=""})}},t.prototype.D=function(t,n,e){var r={"p-region-id":e.k,"p-region":e.region,"p-locality":e.l,"p-street-address":e.m,"p-extended-address":e.o},o=n.querySelectorAll("."+t);[].map.call(o,function(n){return n.value+=r[t]?r[t]:""})},t}();YubinBango.MicroformatDom=t}(YubinBango||(YubinBango={})),document.addEventListener("DOMContentLoaded",function(){new YubinBango.MicroformatDom},!1);
