import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import ReactStringReplace from "react-string-replace";
import { sendGA } from '../common/helper';

class TOCList extends Component {
  render() {
    const anchor = "#" + this.props.anchor;
    return (
      <li><AnchorLink href={anchor} offset="50"><span className="Q">Q.</span>{this.props.headline}</AnchorLink></li>
    );
  }
}

class TOC extends Component {
  render() {
    const TOCItems = this.props.TOCItems;
    return (
      <ul className="faq__toc">
        {TOCItems.map((item, i) => {
          return <TOCList key={i} anchor={item.anchor} headline={item.headline} />
        })}
      </ul>
    );
  }
}

class ContentsList extends Component {
  render() {
    const TOCItem = this.props.TOCItem;
    return (
      <React.Fragment>
        <h4 id={TOCItem.anchor} className="faq__headline">{TOCItem.headline}</h4>
        <div className="faq__contents-inner">
        {TOCItem.items.map((item, i) => {
          var answer = ReactStringReplace(item.answer, "注意事項", (match, i) => (<a key={i} href="/#/notes">{match}</a>));
          answer = ReactStringReplace(answer, "info@kujifes.com", (match, i) => (<a key={i} href="mailto:&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;">&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;</a>));
          answer = ReactStringReplace(answer, "お問い合わせはこちら", (match, i) => (<a key={i} href="/#/contact">お問い合わせはこちら</a>));
          answer = ReactStringReplace(answer, "「くじフェス！とは」", (match, i) => (<a key={i} href="/#/about">くじフェス！とは</a>));
          answer = ReactStringReplace(answer, "「パスワードの再発行」", (match, i) => (<a key={i} href="/#/password/reissue-mail">パスワードの再発行</a>));
          answer = ReactStringReplace(answer, "「ログイン出来ない」", (match, i) => (<a key={i} href="mailto:&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;">こちら</a>));
          answer = ReactStringReplace(answer, "「メールアドレス変更」", (match, i) => (<a key={i} href="/#/contact">お問い合わせ</a>));
          answer = ReactStringReplace(answer, "「退会連絡先」", (match, i) => (<a key={i} href="/#/contact">お問い合わせ</a>));
          answer = ReactStringReplace(answer, "「個人情報保護方針」", (match, i) => (<a key={i} href="https://goodroid.co.jp/policy/" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>));
          return (
            <div key={i} className="faq__item-list">
              <p className="faq__item-list--question"><span className="Q">Q.</span>{item.question}</p>
              <p className="faq__item-list--answer"><span className="A">A.</span><span>{answer}</span></p>
            </div>
          );
        })}
        </div>
      </React.Fragment>
    );
  }
}

class Contents extends Component {
  render() {
    const TOCItems = this.props.TOCItems;
    return (
      <div className="faq__contents">
        {TOCItems.map((TOCItem, i) => {
          return <ContentsList key={i} TOCItem={TOCItem} />
        })}
      </div>
    );
  }
}

class FAQ extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const TOCItems = [
      {anchor: "about", headline: "くじフェス！について",
      items: [
        {
          question: "くじフェス！とは？",
          answer: "くじフェス！とは、1枚から購入できるはずれなしのオンラインくじです。\nくじフェス！でしか手に入らないオリジナルコラボ賞品が当たります。"
        },
        {
          question: "各くじの販売期間は？",
          answer: "基本的には1ヶ月間ですが、くじによって期間が異なるものがあります。\nまた、販売期間は延長される場合もあります。\nそれぞれのくじ詳細ページ及び公式ブログ・公式Twitterよりご確認ください。"
        },
        {
          question: "推奨環境は？",
          answer: "PC及びスマートフォンに対応しております。\n詳しくは注意事項をご確認ください。"
        },
        {
          question: "年齢制限は？",
          answer: "20歳未満の方は保護者の許可を得ない限り、当サービスをご利用いただけません。また、20歳未満の方がご注文された場合においても、保護者の許可を得たものとみなし、ご注文完了後のキャンセル・返品の受け付けはいたしかねますので予めご了承ください。"
        },
        {
          question: "商品・サービスへの問い合わせ方法は？",
          answer: "お問い合わせは下記より承っております。\nお問い合わせはこちら"
        },
      ]},
      {anchor: "delivery", headline: "配送について",
      items: [
        {
          question: "配送手数料はいくら？",
          answer: "各くじにより異なります。\nまた、同一くじ/同一配送先に限り、1回分の配送手数料で、各くじ規定のくじ数まで1回分の送料で配送が可能です。\n\n※ご注文が1回分の送料でお届ける個数上限を超えた場合、以降くじの個数上限ごとに送料が発生します。\n\n例）くじ10個まで1回分の送料でお届けの場合\n【くじ 1個～10個購入のとき】同一くじ/同一配送先に限り、1回分の送料でお届け！\n【くじ11個～20個購入のとき】同一くじ/同一配送先に限り、2回分の送料でお届け！\n\n※同一配送先は一つ前の購入情報を元に判定いたします。"
        },
        {
          question: "商品は必ず各注文ごとに送られて来る？",
          answer: "ご当選された商品によっては、購入くじ数以上の配送個数または、購入くじ数以下の同梱配送になることがあります。配送種類も、メール便・宅配便など複数となる場合もあります。"
        },
        {
          question: "配送はいつ？",
          answer: "各くじの「お届け目安」の時期に配送いたします。\n各くじ詳細ページおよびマイページの購入履歴に記載されています。\nまた、配送完了した時点で、ご登録いただいたメールアドレスへお知らせいたします。"
        },
        {
          question: "注文完了後にお届け先を変えられる？",
          answer: "配送先は、ご注文時に登録されていた住所へお届けいたします。\n変更される場合はお手数ですが、お問い合わせよりご連絡をお願いいたします。"
        },
        {
          question: "商品の再配送は出来る？",
          answer: "別途送料をご負担いただく形で、再配送が可能です。\n詳しくは注意事項の「商品の再配送について」と「商品の保管期間について」をご確認ください。"
        },
        {
          question: "同時期に購入した別のくじをまとめて配送できる？",
          answer: "別くじの商品をまとめて配送することはできません。"
        },
      ]},
      {anchor: "payment", headline: "お支払いについて",
      items: [
        {
          question: "支払い方法の種類は？",
          answer: "お支払いは、クレジットカード、コンビニエンスストア決済、キャリア決済、WebMoney（ウェブマネー）がご利用いただけます。\nまた、お支払いはGMOペイメントゲートウェイを介して行われます。\n詳しくは「くじフェス！とは」のお支払い方法をご確認ください。"
        },
        {
          question: "送料・手数料はかかる？",
          answer: "各くじごとに決められた配送手数料をいただいております。\n決済時の各画面にてご確認いただけます。"
        },
        {
          question: "購入代金はいつ決済される？",
          answer: "購入代金はくじ購入時に決済させていただきます。\nくじ抽選後のキャンセルはできません。"
        },
        {
          question: "領収書は発行できる？",
          answer: "領収書の発行は承っておりません。"
        },
      ]},
      {anchor: "order", headline: "ご注文内容について",
      items: [
        {
          question: "注文後の注文内容の確認は出来る？",
          answer: "正常に決済が完了すると、抽選アニメーションが表示され、抽選結果画面にて各当選商品をご確認いただけます。\nまた、ご登録いただいたメールアドレス宛てに「注文完了のご案内メール」をお送りいたします。\nログイン後の「マイページ」＞「購入履歴一覧」からもご確認いただけます。\n※ご案内メールの送信および、マイページの注文履歴の反映には、少しお時間をいただく場合がございます。\n※コンビニエンスストア決済、WebMoney決済の場合、抽選アニメーションは表示されませんが、プレミアムチャンス・くじ商品の抽選は他決済同様に行われております。抽選結果はマイページの購入履歴一覧、または「注文完了のご案内メール」をご確認ください。"
        },
        {
          question: "返品・交換・キャンセルはできる？",
          answer: "抽選結果を伴うサービスの特性上、お客様都合による返品・交換・キャンセルはできません。\nお届け後の商品に不良があった場合は、お手数ではございますが、注意事項の「返品について」をご確認の上、お受け取り後7日以内にこちらにご連絡ください。\nまた、お届けした商品が当選した商品と異なっていた場合も、お手数ではございますが、お受け取り後7日以内にこちらにご連絡ください。"
        },
        {
          question: "商品が商品ページの画像と異なっているのでは？",
          answer: "商品画像と実際の商品は多少異なる場合がございます。\n色や形の微妙な違いにつきましては、ご容赦いただけますよう何卒よろしくお願いいたします。"
        },
      ]},
      {anchor: "premium", headline: "プレミアムチャンスについて",
      items: [
        {
          question: "プレミアムチャンスとは？",
          answer: "プレミアムチャンスとは、各くじ購入ごとに追加で抽選され、当選する可能性のある賞です。\nまとめてくじを購入された際は、その購入回数分抽選が行われます。\n大人買いセットはセット内容の個数分、抽選が行われます。\nくじごとに当選する商品は異なります。\nまた、プレミアムチャンスがないくじもございます。\nプレミアムチャンスは販売終了後、ご購入者の中から補欠抽選を行う場合があります。\n詳しくは各くじ商品ページをご確認ください。"
        },
      ]},
      {anchor: "regist", headline: "会員登録について",
      items: [
        {
          question: "会員登録をせずにくじを購入することは出来る？",
          answer: "確実に皆様へ商品をお届けするため、会員登録は必須となっております。\n新規会員登録後、登録情報にてログインいただくと、くじのご購入が可能となります。"
        },
        {
          question: "IDを忘れた場合は？",
          answer: "当サービスのIDはメールアドレスとなります。\nお手持ちのメールアドレスをご確認ください。"
        },
        {
          question: "パスワードを忘れた場合は？",
          answer: "「パスワードの再発行」にてお手続きできます。\nご登録いただいているメールアドレス宛てに「パスワード再発行メール」をお送りしますので、手順にのっとって新たなパスワードの設定をお願いいたします。"
        },
        {
          question: "ログインが出来ない場合は？",
          answer: "パソコンやスマートフォンを機種変更された場合、機種や利用環境によってはログインできなくなることがあります。お手数ですが「ログイン出来ない」にご連絡ください。"
        },
        {
          question: "メールアドレスを変更したい場合は？",
          answer: "メールアドレスを変更したい場合は、「メールアドレス変更」より承っております。"
        },
        {
          question: "退会したい場合は？",
          answer: "退会される場合は「退会連絡先」よりご連絡をお願いいたします。\n退会されますと、購入履歴などが閲覧できなくなりますのでご注意ください。\nただし、お取引中のご注文がある場合、注文者情報を削除されましてもお取引完了まで継続されます。"
        },
        {
          question: "個人情報の取り扱いはどうなっている？",
          answer: "「個人情報保護方針」をご確認ください。"
        },
      ]},
    ];
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="faq">
            <SectionTitle title="よくあるご質問" />
            <TOC TOCItems={TOCItems} />
            <Contents TOCItems={TOCItems} />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default FAQ;
