import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { REGISTER_REQUEST, registerFailure} from '../actions/actions';
import api from '../common/api';
import Sha256 from 'js-sha256';
import checkResponse from '../common/checkResponse';

function* handleRequest(action) {

  let requestData = Object.assign({}, action);
  requestData.payload = Object.assign({}, requestData.payload, action.payload);
  requestData.payload.phash = Sha256(requestData.payload.phash);
  requestData.payload.ahash = Sha256(requestData.payload.ahash);
  requestData.payload.confirmPhash = "";
  requestData.payload.birthYear = ('0000' + requestData.payload.birthYear).slice(-4);
  requestData.payload.birthMonth = ('00' + requestData.payload.birthMonth).slice(-2);
  requestData.payload.birthDay = ('00' + requestData.payload.birthDay).slice(-2);

  try {
    const response = yield call(api, requestData);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(push('/entry/pre-complete'));
    } else {
      yield put(registerFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const registerSaga = [takeEvery(REGISTER_REQUEST, handleRequest)];
export default registerSaga;