import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import NormalButton from '../components/normal-button';
import CategoryEndList from '../components/category-end-list';
import { sendGA } from '../common/helper';

import { connect } from 'react-redux';
import { endSeriesListRequest } from '../actions/actions';

class ItemEndList extends Component {
  // constructor(props) {
  //   super(props);
    // this.state = {
    //   topResponse: {}
    // }
  // }

  componentDidUpdate(){
    Array.from(document.getElementsByClassName('item-card__img--price')).forEach((el, index) => {
      el.setAttribute('style', 'display:none');
      setTimeout(() =>
      el.removeAttribute('style'),1000)
    })
  }

  componentDidMount(){
    // this.props.dispatchRequest(this.state.topResponse);
    var date = new Date();
    date = date.getTime();

    var param = {
      edt: date,
      count: 100,
    };
    this.props.dispatchEndSeriesRequest(param);

    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
console.log("end render ===================");
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <SectionTitle title="販売終了" />
          <section className="category-list">
            <div className="sold-item-list">
              <CategoryEndList endSeriesList={ this.props.endSeriesList } endSeriesLimitCount={0} />
            </div>
            <div className="button-wrapper">
              <NormalButton linkTo="/" buttonColor="white-red" text="トップページへ"/>
            </div>
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { endSeriesList } = state;
  return endSeriesList;
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchEndSeriesRequest: param => dispatch(endSeriesListRequest(param, 'end-series-list')),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemEndList));