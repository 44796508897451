import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import { RenderField, inputField } from './render-field';
import { required, halfUpper, email, emailConfirmation } from '../common/validate';
import BackButton from './back-button';
import SubmitButton from './submit-button';
// import CommonModal from '../components/common-modal';
// import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

let initial = {
  contents: "",
};

class ContactWithdrawForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // 確認画面に入力情報を渡す
  handleSubmit(e) {
    const { login, invalid, formValues, dispatchFormRequest, api, reset } = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    let param = Object.assign({},
      formValues,
      login
    );

    console.log("param ==> ", param);

    if(invalid === false){
      // リクエストを投げる
      if(dispatchFormRequest != null){
        dispatchFormRequest(param, api);
        reset();
      }
    }
  }

  submit = values => {
    // print the form values to the console
    console.log("submit ==>", values);
  }

  render() {

    return (
      <form name="contactWithdrawForm" id="contactWithdrawForm" className="entry-form h-adr" onSubmit={this.submit}>
        <div>
          <p>
          【注意】<br />
          お取引中のご注文がある場合、退会されましてもお取引完了まで処理は継続されますが、同じメールアドレスでの期間中の再登録はできなくなります。
          退会されますと、過去の購入履歴などが閲覧できなくなります。<br /><br />
          </p>
        </div>
        <div className="form-inner">
          <dl>
            <dt>退会理由</dt>
            <dd>
              <Field id={'contents'} name="contents" component={RenderField} type="textarea" validate={[]} maxLength="200" className={'form-inner__input--long form-inner__textarea'} />
            </dd>
          </dl>
        </div>

        <section class="delivery-address">
        <div className="button-wrapper">
          <div className="button-wrap">
            <BackButton linkTo="/contact" text="戻る" />
          </div>
          <div className="button-wrapper--centering">
            <SubmitButton onClick={this.handleSubmit} text="送信" />
          </div>
        </div>
        </section>

      </form>
    );
  }
}

ContactWithdrawForm = reduxForm({
  form: 'contactWithdrawForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: initial,
})(ContactWithdrawForm);


ContactWithdrawForm = connect(state => {
  return {
    formValues: getFormValues('contactWithdrawForm')(state),
    formMeta: getFormMeta('contactWithdrawForm')(state),
  }
})(ContactWithdrawForm);

export default ContactWithdrawForm;
