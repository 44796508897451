import { call, put, takeEvery, select} from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { PENDING_SHIPMENT_LOGIN_CHECK, PENDING_SHIPMENT_REQUEST, pendingShipmentRequest, pendingShipmentSuccess, pendingShipmentFailure, accountRequest, loginClear } from '../actions/actions';

function* handleLoginCheck(action) {
  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sxp === false && payload.errMsg == null ) {
      const { login } = yield select();
      const param = {
        usid: login.usid,
        seid: login.seid,
      };
      yield put(pendingShipmentRequest(param, 'pending-shipment'));
      yield put(accountRequest(param, 'account'));
    } else {
      yield put(loginClear(payload));
      yield put(push("/login"));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

function* handlePendingShipment(action) {
  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(pendingShipmentSuccess(payload));
    } else {
      yield put(pendingShipmentFailure(payload));
    }

  } catch(error) {
    console.log("error ==> ",error);

  }
}

const pendingShipmentSaga = [
  takeEvery(PENDING_SHIPMENT_LOGIN_CHECK, handleLoginCheck),
  takeEvery(PENDING_SHIPMENT_REQUEST, handlePendingShipment),
  // fork(handleHistoryAccount),
];
export default pendingShipmentSaga;
