import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import PasswordCard from '../components/password-card';
import { questions } from '../common/const';
import { sendResetPasswordRequest } from '../actions/actions';
import { sendGA } from '../common/helper';

class PasswordReissueHint extends Component {
  constructor(props) {
    super(props);

    const { sendResetPassword } = props;

    // TODO: sendResetPassword.emailの値がnullならメール入力ページにリダイレクト処理

    console.log("constructor ==> ", props);
    this.state = {
      inputForm: {
        email: sendResetPassword.email,
        surname: sendResetPassword.surname,
        name: sendResetPassword.name,
        ahash: "",
      },
    }
    this.handleChange = this.handleChange.bind(this);
  }

  // 入力内容の変更を監視
  handleChange(event) {
    let inputForm = this.state.inputForm;
    switch (event.target.name) {
      case 'input-ahash':
        inputForm.ahash = event.target.value;
        break;
      default:
        break;
    }

    // 状態を更新
    this.setState({
      inputForm: inputForm
    });
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { dispatchRequest, sendResetPassword } = this.props;
    const isError = (sendResetPassword.errMsg != null);

    console.log("render this.props ====> ", this.props);
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <PasswordCard heading="秘密の質問の確認">
          <p>ご登録時に入力した質問の答えを入力してください。<br/>
          ※質問の答えをお忘れになられた場合は<a href="mailto:&#105;&#110;&#102;&#111;&#64;&#107;&#117;&#106;&#105;&#102;&#101;&#115;&#46;&#99;&#111;&#109;">お問い合わせ</a>よりご連絡ください。</p>
          {isError && <p className="error-message">{sendResetPassword.errMsg.toString()}</p>}
          <h4 className="password-card__subheading">{questions[sendResetPassword.questionType]}</h4>
          <input type="text" className="password-card__input--normal" name="input-ahash" placeholder="質問の答え" value={this.state.inputForm.ahash} onChange={this.handleChange} />
          <div className="button-wrapper">
            <button type="button" onClick={e => dispatchRequest(this.state.inputForm)} className="button-red">メールを送信する</button>
          </div>
          </PasswordCard>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}


function mapStateToProps(state) {
  console.log("state",state);

  const {sendResetPassword} = state;
  return {
    sendResetPassword: sendResetPassword,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchRequest: inputForm => dispatch(sendResetPasswordRequest(inputForm, 'send-reset-password')),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReissueHint);