import React, { Component } from 'react';
import { connect } from 'react-redux';
import { purchaseConfirmRequest, productRequest, orderRequest, orderFailure } from '../actions/actions';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import CustomModal from '../components/custom-modal'
import CommonModal from '../components/common-modal';
import SectionTitle from '../components/section-title';
import Accordion from '../components/accordion';
import PurchaseModal from '../components/purchase-modal';
import ButtonIconBack from '../images/common/button_icon_back.png';
import MiniButtonRound from '../components/mini-button-round';
import { getLotteryAwardText } from '../common/helper';
import { isEmpty } from '../common/utile';
import { imgPath } from '../common/helper';
import { termStatus } from '../common/const';
import { sendGA } from '../common/helper';

// lotteryType(ltp)により引ける回数を返す
const getLimitTimes = function(lotteryType){
  switch(lotteryType){
    case 1: return 9;
    case 2: return 9;
    case 3: return 5;
    default: return 1;
  }
}

// 商品購入情報component
class PurchaseConfirmDetail extends Component {
  constructor(props) {
    super(props);
    // this.state = this.props;

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    switch (event.target.name) {
      case 'number':

        const { login, product, dispatchProductRequst } = this.props;
        const param = ({
          usid: login.usid,
          seid: login.seid,
          srid: product.srid,
          ltp: product.ltp,
          num: event.target.value,
        });
        dispatchProductRequst(param);

        break;
      default:
        break;
    }
  }

  render () {
    const { product } = this.props;

    const numbers = Array.from({ length: getLimitTimes(product.ltp) }, (v, k) => k + 1);
    const setListItems = product.itemSetList.map( (obj, i) =>
      <li key={i}>【{getLotteryAwardText(obj.lotteryAwardType)+"賞"}】{obj.lotteryItemTitle} x{obj.num}</li>
    );

    const thumbImg = (product.rscdir!=null)? imgPath(product.rscdir + "/img/product/product_" + product.ltp.toLocaleString() + ".jpg") : null;

    return (
      <section className="product">
        <SectionTitle title="ご購入内容の確認" />
        <div className="product__inner">
          <div className="product__thumbnail"><img src={thumbImg} alt="" /></div>
          <div className="product__container">
            <h3 className="product__title">{product.title}</h3>
            <p className="product__price">1回<span className="product__price--big">{ product.price > 0 ? product.price.toLocaleString() : "- " }円</span><span className="product__price--small">(税抜)</span></p>
            <p className="product__description">{product.detail}</p>

            { (() => {if(product.itemSetList.length > 0) {
              return <Accordion title="セット内商品一覧">
                <ul className="accordion__list">
                  {setListItems}
                </ul>
              </Accordion>
             }})()}

            <div className="product__amount">
              <div className="product__amount--wrapper">
                <span>購入数</span>
                <span className="select-number-wrapper">
                  <span className="select-number-bg">
                    <select className="select-number-bg__select--number" name="number" value={product.num} onChange={this.handleChange}>
                      {numbers.map((number, i) => {
                        return <option key={i} value={number}>{number}</option>;
                      })}
                    </select>
                  </span>
                </span>
              </div>
              <span>配送手数料 {product.shipping.toLocaleString()}円</span>
            </div>
            <p className="product__total">合計<span className="product__total--big-red">{product.totalPrice > 0 ? product.totalPrice.toLocaleString() : "- "}円</span><span className="product__total--small">(税・配送手数料込)</span></p>
            <p className="delivery-notes">※配送手数料は{product.freeShippingNum.toLocaleString()}個まで一律{product.properShipping}円です。以降{product.freeShippingNum.toLocaleString()}個ごとに{product.properShipping}円追加料金がかかります。</p>
          </div>
        </div>
      </section>
    );
  }
}

// ユーザー情報component
class DeliveryAddress extends Component {
  constructor (props) {
    super(props);

    this.state = {
      agree: false
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange() {
    this.setState({
      agree: !this.state.agree
    });
  }
  render () {
    const { account, checkSeries, login, product, order, dispatcOrderRequst, dispatcOrderFailure } = this.props;
    const premiumItems = checkSeries.premiumList.map( (obj, i) =>
      (obj.termStatus == null || obj.termStatus === termStatus.being) ?
        (obj.remain>0)?
          <p key={i} className="sold-out-premium">{obj.itemAwardName}『{obj.lotteryItemTitle}』は<br className="sp" />残りあと{obj.remain.toLocaleString()}個。</p>
          :
          <p key={i} className="sold-out-premium">{obj.itemAwardName}『{obj.lotteryItemTitle}』は<br className="sp" />予定販売数を終了しました。</p>
      :
      ''
    );

    return (
      <section className="delivery-address">
        <h2 className="section-title">
          <span>お届け先</span>
          <MiniButtonRound linkTo="/user/edit" text="変更する"/>
        </h2>
          <div className="form-inner">
            <dl>
              <dt>お名前</dt>
              <dd>{account.surname + " " + account.name}</dd>
            </dl>
            <dl>
              <dt>住所</dt>
              <dd>{"〒" + account.postalCode1 + "-" + account.postalCode2 + " " + account.prefectures + account.address1 + account.address2}</dd>
            </dl>
            <dl>
              <dt>電話番号</dt>
              <dd>{account.phone1 + "-" + account.phone2 + "-" + account.phone3}</dd>
            </dl>
            <dl>
              <dt>メールアドレス</dt>
              <dd>{account.email}</dd>
            </dl>
          </div>
          <div className="agree-wrapper">
            <input type="checkbox" name="agree" id="agree" value={this.state.agree} onChange={this.handleChange} />
            <label htmlFor="agree" className="agree-wrapper__checkbox checkbox"><span>注意事項・個人情報保護方針に同意する</span></label>
            <p className="agree-wrapper__text">※<Link to="/notes">注意事項</Link>・<a href="https://goodroid.co.jp/policy/" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>をご確認ください</p>
          </div>
          {premiumItems}
          <div className="button-wrapper">
            {/* <div className="button-wrap"><BackButton buttonColor="white" text="戻る" linkTo={"/lp/" + product.rscdir}/></div> */}
            <div className="button-wrap">
            <a href={"/lp/" + product.rscdir + "/"}><button type="button" className="button-back"><img className="button-back__icon" src={ButtonIconBack} alt="<" /><span>戻る</span></button></a>
            </div>
            {this.state.agree ?
            <CustomModal buttonText="購入手続きへ" login={login} product={product} order={order} dispatcOrderRequst={dispatcOrderRequst} dispatcOrderFailure={dispatcOrderFailure}>
              <PurchaseModal login={login} product={product} order={order} />
            </CustomModal>
            :
            <div className="button-wrapper--centering">
              <CommonModal title="" message="注意事項・個人情報保護方針にご同意の上、&#10;チェックボックスにチェックを入れてください。">
                <button type="button" id="submit" className="button-disabled">購入手続きへ</button>
              </CommonModal>
            </div>
            }
        </div>
      </section>
    );
  }
}

class PurchaseConfirm extends Component {

  constructor (props) {
    super(props);

    const { login, product, match, dispatcPurchaseConfirmRequest } = this.props;

    const targetData = (isEmpty(match.params))? product: match.params;
    const { srid, ltp, num } = targetData;

    console.log("srid ==> ", srid, "ltp ==> ", ltp, "num ==> ", num);
    if(ltp == null || srid == null || num == null ) {
      this.props.history.push("/404");
      return;
    }

    // ログイン状態かチェック
    const { usid, seid } = login;
    if(usid == null || seid == null) {
      this.props.history.push("/login");
      return;
    }

    Object.assign(product,
      {
        ltp: parseInt(ltp,10),
        srid: parseInt(srid,10),
        num: parseInt(num,10),
      }
    );

    const param = ({
      usid: login.usid,
      seid: login.seid,
    });

    // チェインAPI呼び出し
    dispatcPurchaseConfirmRequest(param);
  }

  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    const { login, product, order, account, history, checkSeries, dispatchProductRequst, dispatcOrderRequst, dispatcOrderFailure } = this.props;

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <form className="purchase-confirm-form">
            <PurchaseConfirmDetail login={login} product={product} dispatchProductRequst={dispatchProductRequst} />
          </form>
          <DeliveryAddress login={login} product={product} order={order} account={account} history={history} checkSeries={checkSeries} dispatcOrderRequst={dispatcOrderRequst} dispatcOrderFailure={dispatcOrderFailure} />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login, account, checkSeries, order, product } = state;
  return {
    login: login,
    account: account,
    checkSeries: checkSeries,
    order: order,
    product: product,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatcPurchaseConfirmRequest: data => dispatch(purchaseConfirmRequest(data, 'login-check')),
    dispatchProductRequst: data => dispatch(productRequest(data, 'product')),
    dispatcOrderRequst: data => dispatch(orderRequest(data, 'order')),
    dispatcOrderFailure: () => dispatch(orderFailure()),
   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseConfirm);
