import React, { Component } from 'react';
import Label from '../components/label';
import ProductTitle from '../components/product-title';
import CommonModal from '../components/common-modal';
import { comingSoonText } from '../common/const';
import { unixTime2Date } from '../common/utile';

class ItemCard extends Component {
  render() {
    const { item } = this.props;

    // ちらつき修正完了後に削除予定
    const animationStyle = {
      animation: `fadein 0.5s linear ${(this.props.index * 0.1)}s 1 forwards`
    }

    const startDate = unixTime2Date(item.startTime);
    const endDate = unixTime2Date(item.endTime);

    return (
      <React.Fragment>
      <li style={animationStyle} className={this.props.isPCOnly ? "pc item-card" : "item-card"}>
        {item.linkTo === "/lp//" ?
        <CommonModal title={comingSoonText["title"]} color={"green"} message={comingSoonText["message"] + "\n\n公開予定日:\n" + startDate.year + "年" + startDate.month + "月" + startDate.day + "日 (" + startDate.week + ") " + startDate.hour + ":" + startDate.min + "\n\n\n※公開予定日は変更となる場合がございます。"}>
          <div className="item-card__img">
            <img src={item.imgSrc} alt={item.imgAlt} />
            { null }
          </div>
          <div className="item-card__contents">
            <Label labelKind={item.labelKind} />
            <ProductTitle labelKind={item.labelKind} title={item.title} />
          </div>
          <div className="item-card__sales-period">
            <p>
              <span className="label-sales-period">公開日時</span>{this.props.isPCOnly ? null : <br className="sp" />}
              <span className="item-card__sales-period--text">{startDate.year}.{startDate.month}.{startDate.day} ({startDate.week}) から</span>
            </p>
          </div>
        </CommonModal>
        :
        <a href={item.linkTo}>
          <div className="item-card__img">
            <img src={item.imgSrc} alt={item.imgAlt} />
            {item.salesLabelType === 3 && <i className="item-card__icon--new"></i>}
          </div>
          <div className="item-card__contents">
            {item.price != null?
              <div className="item-card__info">
                <Label labelKind={item.labelKind} />
                <p className="item-card__price">1回：<span>{item.price.toLocaleString()}円</span></p>
              </div>
            :
              <Label labelKind={item.labelKind} />
            }
            <ProductTitle labelKind={item.labelKind} title={item.title} />
          </div>

          <div className="item-card__sales-period">
            <p>
              <span className="label-sales-period">販売期間</span>{this.props.isPCOnly ? null : <br className="sp" />}
              <span className="item-card__sales-period--text">{endDate.year}.{endDate.month}.{endDate.day} ({endDate.week}) まで</span>
            </p>
          </div>
        </a>
        }
      </li>
      </React.Fragment>
    );
  }
}
export default ItemCard;