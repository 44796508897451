import store from '../configureStore';
import { PRODUCT_SUCCESS, PRODUCT_FAILURE } from '../actions/actions';
import { push } from 'react-router-redux';

const initialState = {
  "pid": null,
  "srid": null,
  "title":"",
  "detail":"",
  "price":0,
  "ltp": null,
  "freeShippingNum":0,
  "shipping":0,
  "num":1,
  "amount":0,
  "taxShipping":0,
  "totalPrice":0,
  "rscid":"0",
  "itemSetList":[
    // {"lotteryItemId":1,"num":1,"lotteryItemTitle":"まっつんのプロマイド写真","itemAwardName":"S-1","lotteryAwardId":1,"lotteryAwardType":1,"lotteryAwardTitle":"まっつんのプロマイド写真"}
  ],
  "sc": null,
};

export default function product(state=initialState, action) {
  // console.log(action.type, action);

  switch (action.type) {
    case PRODUCT_SUCCESS:
      console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload.product
      );
    case PRODUCT_FAILURE:
      store.dispatch(push('/login'));
      return state;
    default:
      return state;
  }
}