import React, { Component } from 'react';
// import NormalButton from '../components/normal-button';
import PageTop from '../components/page-top';
import LogoImg from '../images/common/logo.svg';
import IconPageMaintenance from '../images/common/meintenance_img.png';
import { sendGA } from '../common/helper';

function MessageSection() {
  const logoAlt = 'くじフェス!';

  return (
    <section className="entry-complete">
      <div className="maintenance-inner">
        <img className="sp maintenance-logo-sp" src={LogoImg} alt={logoAlt} />
        <img className="pc maintenance-logo-pc" src={LogoImg} alt={logoAlt} />
        <h1>ただいまメンテナンス中です</h1>
        <img className="maintenance-icon" src={IconPageMaintenance} alt="" />
        <h5>ただいまサイトのメンテナンスをしています。</h5>
        <h5>対応内容および終了目安については<br />公式Twitterにてお知らせいたします。</h5>
          <a href="https://twitter.com/kujifes" target="blank">
            <button type="button" className="button--tw">公式Twitter</button>
          </a>
      </div>
    </section>
  );
}

class Maintenance extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <div className="container">
          <MessageSection />
        </div>
        <PageTop />
      </div>
    );
  }
}
export default Maintenance;