import { LOGIN_SUCCESS, LOGIN_PRE_SUCCESS, LOGIN_FAILURE, LOGIN_CLEAR } from '../actions/actions';
const initialState = {
  isLogin:false,
  seid: null,
  usid: null,
};

export default function login(state=initialState, action) {

  switch (action.type) {
    case LOGIN_SUCCESS:
      console.log("LOGIN_SUCCESS action.payload", action.payload);
      action.payload.isLogin = true;
      return Object.assign({},
        state,
        action.payload
      );
    case LOGIN_PRE_SUCCESS:
      console.log("LOGIN_SUCCESS action.payload", action.payload);
      action.payload.isLogin = false;
      return Object.assign({},
        state,
        action.payload
      );
    case LOGIN_FAILURE:
      console.log("LOGIN_FAILURE action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    case LOGIN_CLEAR:
      console.log(action.type, action.payload);
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}
