import { restfulApiConfig } from './config';

function api(data) {
  return window.fetch(restfulApiConfig.apiURL + "api/" + data.meta.api, {
    method:'post',
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    },
    body: JSON.stringify(data.payload)
  });
}

export default api;