import React, { Component } from 'react';
import { restfulApiConfig } from '../common/config';

class PurchaseModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      param: {
        SiteID: restfulApiConfig.siteID,
        ShopID: restfulApiConfig.shopID,
        RetURL: restfulApiConfig.apiURL + "receive-result",
        MemberID: null,
        CancelURL: document.URL,
        ServiceName: "くじフェス！",
        Commodity: "くじフェス！利用代金",
        ItemName: "くじフェス！利用代金",
        JobCd: "CAPTURE",
        Tax: null,
        Amount: null,
        ShopPassString: null,
        DateTime: null,
        OrderID: null,
        MemberPassString: null,
        ServiceTel: "03-6328-4040",
        UseCredit: "1",
        UseCvs: "1",
        UseSb: "1",
        UseAu: "1",
        UseDocomo: "1",
        UseWebMoney: "1",
        UserInfo: null,
        ReserveNo: null,
        MemberNo: null,
        RegisterDisp1: "くじフェス",
        ReceiptsDisp1: "ご利用ありがとうございました。",
        ReceiptsDisp11: "info@kujifes.com",
        ReceiptsDisp12: "03-6328-4040",
        ReceiptsDisp13: "10:00-18:00",
        PaymentTermDay: 1,
      }
    }
  }

  render() {
    const { login, product, order } = this.props;
    let inputItems = [];

    console.log("this.state.param => ", this.state.param);

    let _clientField3 = "0";
    if( product.totalPrice < 300000 ) {
      _clientField3 = (product.totalPrice < 50000)? "1": "2";
    }

    let _userInfo = (window.innerWidth > 769)? "pc": "sb";

    Object.assign(this.state.param,
      {
        MemberID: login.usid,
        Tax: product.taxShipping,
        ClientField3: _clientField3,
        Amount: product.amount,
        ShopPassString: order.sps,
        DateTime: order.orderDate,
        OrderID: order.oid,
        SessionTimeout: 600,
        MemberPassString: order.msp,
        UserInfo: _userInfo,
        ReserveNo: order.oid.substr(0, 19),
        MemberNo: login.usid.substr(0, 20),
      }
    );

    const { param } = this.state;

    Object.keys(param).forEach((key, i) => {
      inputItems.push(<input key={i} type="hidden" name={key} value={param[key]}></input>);
    });

    const url = restfulApiConfig.purchaseURL;
    return (
      <div className="modal-wrap">
        <div className="purchase-modal">
          <h3 className="modal__headline">次の画面で<br />お支払い方法を選択してください</h3>
          <div className="modal__description">
            <p>※GMOペイメントゲートウェイへ遷移します</p>
            <p>未成年の方は必ず保護者の同意を得てから<br />購入してください。</p>
          </div>
          <form action={url} method="post">
            {inputItems}
            <button type="submit" className="button-red">OK</button>
          </form>
        </div>
      </div>
    );
  }
}
export default PurchaseModal;