
// storeの設定ファイル
import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import { persistStore } from 'redux-persist';
import createHistory from 'history/createHashHistory'
import rootReducer from './reducers/index';
import rootSaga from './sagas/index';
import ReactGA from 'react-ga';
import { restfulApiConfig } from './common/config';

ReactGA.initialize(restfulApiConfig.gaID);
// ReactGA.outboundLink({
//   label: 'Clicked Create an Account'
// }, function () {
//   console.log('redirect here');
// });
export const history = createHistory();
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});
const middleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(middleware, sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

// store.subscribe(() =>
//   console.log("store", store.getState(), '\n\n')
// );


export const persistor = persistStore(store);
export default store;