import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import { RenderField, ConfirmField } from './render-field';
import { required, halfUpper, email, emailConfirmation } from '../common/validate';
import BackButton from './back-button';
import SubmitButton from './submit-button';

let initial = {
  usid: "",
  seid: "",
  email: "",
};

class ContactEmailForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // 確認画面に入力情報を渡す
  handleSubmit(e) {
    const { login, invalid, formValues, dispatchFormRequest, api, reset } = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    let param = Object.assign({},
      formValues,
      login
    );

    console.log("param ==> ", param);

    if(invalid === false){
      // リクエストを投げる
      if(dispatchFormRequest != null) {
        dispatchFormRequest(param, api);
        reset();
      }
    }
  }

  render() {
    const { invalid } = this.props;

    return (
      <form name="contactEmailForm" id="contactEmailForm" className="entry-form h-adr">
        <input type="hidden" className="p-country-name" style={{display: 'none'}} value="Japan"></input>
        <div>
          <p>変更をご希望のメールアドレスをご記入ください。<br /><br /></p>
        </div>
        <div className="form-inner">
          <dl>
            <dt>メールアドレス<span className="required">※</span></dt>
            <dd className="form-inner__mail">
              <Field id={'email'} name="email" component={RenderField} type="email" validate={[required, halfUpper, email]} className={'form-inner__input--long'} />
              <Field id={'confirmEmail'} name="confirmEmail" component={ConfirmField} type="email" validate={[required, emailConfirmation]} className={'form-inner__input--long'} placeholder="確認のため2度入力してください" />
            </dd>
          </dl>
        </div>

        <section className="delivery-address">
          <div className="button-wrapper">
            <div className="button-wrap">
              <BackButton linkTo="/contact" text="戻る" />
            </div>
            <div className="button-wrapper--centering">
              <SubmitButton onClick={this.handleSubmit} text="送信" disabled={invalid} />
            </div>
          </div>
        </section>
      </form>
    );
  }
}

ContactEmailForm = reduxForm({
  form: 'contactEmailForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: initial,
})(ContactEmailForm);


ContactEmailForm = connect(state => {
  return {
    formValues: getFormValues('contactEmailForm')(state),
    formMeta: getFormMeta('contactEmailForm')(state),
  }
})(ContactEmailForm);

export default ContactEmailForm;
