import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import { sendGA } from '../common/helper';

class Contents extends Component {
  render() {
    return (
      <div className="notes__contents">
        <SectionTitle title="推奨環境について" />
        くじフェス！をご利用いただくにあたり、下記のブラウザおよび環境を推奨しております。<br/>
        <br/>
        ＜推奨ブラウザ＞<br/>
        ・Internet Explorer 11以上<br/>
        ・Microsoft Edge 最新版<br/>
        ・Google Chrome 最新版<br/>
        ・Mozilla Firefox 最新版<br/>
        ・Safari 最新版<br/>
        <br/>
        ＜スマートフォン＞<br/>
        ・iOS（iPhone）…iOS 8以上<br/>
        ・Android…Android 4.4以上<br/>
        <br/>
        ＜ブラウザ設定＞<br/>
        ・JavaScript を『有効』<br/>
        ・Cookie を『有効』<br/>
        ・ポップアップを『有効』<br/>
        <br/>
        ※正常に作動しない場合は、ブラウザの設定でCookieおよびポップアップを有効にする設定をしてください。<br/>
        ※フィーチャーフォンは対応しておりません。予めご了承ください。<br/>
        <br/>
        <SectionTitle title="ご利用の年齢制限について" />
        お客様が20歳未満の未成年者の場合、保護者の許可を得ない限り、くじフェス！はご利用いただけません。未成年者がご注文の場合でも、保護者の許可を得たものと見なし、ご注文のキャンセル・返品の受け付けはいたしかねます。予めご了承ください。<br/>
        <br/>
        <SectionTitle title="価格について" />
        くじフェス！の価格表記は、消費税抜の本体価格となります。※消費税込の金額表記の場合はその旨を記載しております。<br/>
        <br/>
        <SectionTitle title="送料（配送手数料）について" />
        同一くじ/同一配送先に限り、1回分の配送手数料で各くじの規定回数分まで配送が可能です。<br />
        （同一配送先かどうかは、直前の購入情報のみ参照されます。それ以前の配送先は参照されません）<br/>
        <br/>
        <SectionTitle title="お支払について" />
        お支払いは、クレジットカード（VISA、MasterCard、JCB、AMEX、DINERSほかGMOペイメントゲートウェイ対応のクレジットカード）、コンビニエンスストア決済（セブンイレブン、ファミリーマート、ローソン、ミニストップ、セイコーマート）、キャリア決済（ドコモ、au、SoftBank）、WebMoney（ウェブマネー）にて承っております。決済は決済代行業者「株式会社GMOペイメントゲートウェイ」を通じて行われます。<br/>
        ご利用するクレジットカード等は、お客様の本人名義である必要があります。ご請求は「くじフェス！」という表示でさせていただきます。<br/>
        <br/>
        ご請求はご注文完了後（くじの抽選結果の表示後）、即時となります。お支払いの回数は１回払いとさせていただきます。<br/>
        ※クレジットカードやキャリア決済をご利用の場合、お客様の口座からの引き落とし時期につきましては、ご利用のクレジットカード会社・キャリアに準じます。<br/>
        <br/>
        決済時に各種お支払い方法で承認が得られなかった場合はご注文いだだけません。<br/>
        （クレジットカード等の有効期限外、カード名義が違う、利用限度額の超過　等）<br/>
        <br/>
        領収書の発行は承っておりません。予めご了承ください。<br/>
        <br/>
        <SectionTitle title="会員登録（ログイン機能のご利用）のご注意" />
        フリーメールアドレス(gmail.com　yahoo.co.jp　hotmail.com　hotmail.co.jp等の、無料で取得可能なドメインのメールアドレス）をご利用の場合、くじフェス！からのメールが「迷惑メール」フォルダに振り分けられてしまうことがございます。「受信箱」に見当たらない場合、「迷惑メール」フォルダもご確認ください。<br/>
        メールが届かない場合は、迷惑メールボックスの設定解除、mail.kujifes.comからのドメイン着信許可の設定をご確認ください。<br />
        <br/>
        <SectionTitle title="会員登録について" />
        くじフェス！のご利用には会員情報の登録が必要となります。「<a href="https://goodroid.co.jp/policy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>」及び「注意事項」（本ページ）をご確認の上、ご登録ください。<br/>
        ※ご登録いただいた個人情報の取扱い<br/>
        会員登録時にご登録いただいた個人情報は当社のプライバシーポリシーに基づき、善良な管理者の注意義務に従い取り扱うとともに、次の各号の目的でのみ使用します。<br/>
        ・くじフェス！の運営<br/>
        ・商品の購入・発送及び購入商品に付随するキャンペーンに関する連絡<br/>
        ・くじフェス！の運営に著しく影響を及ぼす事柄（サービス内容の大幅な変更、一時停止等）に関する連絡<br/>
        <br/>
        <SectionTitle title="ログイン時のID／パスワードについて" />
        ID・パスワードは第三者に知られぬよう大切に保管してください。<br/>
        IDをお忘れの場合につきましては、IDはお客様のメールアドレスとなり個人情報となりますため、お教えすることができかねます。お手持ちのメールアドレスをご確認ください。<br/>
        パスワードをお忘れの場合は、パスワードの再発行が可能です。下記よりお手続きください。<br/>
        <br/>
        パスワードの再発行は<Link to="password/reissue-mail">こちら</Link><br/>
        <br/>
        <SectionTitle title="退会手続きについて" />
        下記お問い合わせよりご連絡ください。<br/>
        退会されますと、過去の注文情報の閲覧ができなくなりますのでご注意ください。<br/>
        <br/>
        退会されてもお取引中の商品（くじ）がある場合には、お取引は完了まで継続されます。<br/>
        <br/>
        <a href="/#/contact">お問い合わせはこちら</a><br/>
        <br/>
        <SectionTitle title="ご注文について" />
        商品（くじ）のご購入には新規会員登録が必要です。<br/>
        新規会員登録後、当サイト上で、購入したい商品(くじ)とその数量を選択し、「購入手続きへ」ボタンを押すと、ポップアップが表示されます。<br/>
        ポップアップでOKを押すと、GMOペイメントゲートウェイのお支払い方法選択ページへ遷移します。<br/>
        お支払い方法を選択し、「進む」ボタンを押すとそれぞれの必要事項記入フォームが表示されます。<br/>
        必要事項を記入し「決済する」ボタンを押した時点で注文が確定します。<br/>
        <br/>
        ご注文完了時には「注文完了のご案内メール」を登録されたメールアドレス宛にお送りしますので、商品がお手元に届くまで当該メールを大切に保管されますようお願いします。<br/>
        <br/>
        ※くじフェス！では封書・Eメール・電話・FAXでのご注文は受け付けておりません。当Webサイトからのみご注文が可能となります。予めご了承ください。<br/>
        <br/>
        <SectionTitle title="変更・交換・キャンセルについて" />
        ご注文確定後（くじの抽選後）の、変更・交換・キャンセルは、お受けしておりません。よくご検討の上、ご注文ください。<br/>
        <br/>
        くじの抽選結果と異なる商品との交換はいたしかねます。賞に複数種の商品がある場合におきましても、くじの抽選結果と異なる同一賞の別商品への交換もいたしかねますので予めご了承ください。<br/>
        <br/>
        <SectionTitle title="決済時の誤操作による重複注文のご注意" />
        「注文する」ボタン押下時および、各決済会社の決済画面内の「決済する」ボタンを押下時に、回線状態によっては次のページが表示されるまで長く時間がかかる場合がありますが、そのときは続けてボタンを押さず1回押したままでしばらくお待ちください。<br/>
        <br/>
        注文ボタンや決済ボタンの二度押し等の誤操作による重複注文でもご注文のキャンセルは出来ません。 予めご了承ください。<br/>
        <br/>
        <SectionTitle title="くじに付随する各種特別賞について" />
        くじで獲得できる景品とは別に、プレミアムチャンスやキリ番賞などを設ける場合がございます。特典がイベントの場合、お客様のご都合により、本イベントサービスを受領しなかったとき、または途中離団された場合は、お客様の権利放棄とみなし、当社は一切の払い戻しをいたしません。<br/>
        イベント運営上当社の予期せぬ地震・風水害・荒天候の天災、事件・事故等による中止をすることがございます。その場合での当社からの返金はいたしかねます。但し、当社の都合による、本イベントの中止または中断に関しては、この限りではありません。<br/>
        <br/>
        <SectionTitle title="配送について" />
        各くじにあります「商品の配送目安」に準じて配送いたします。配送完了した時点で、ご登録いただいたメールアドレスに「配送のご案内メール」をお送りいたします。<br/>
        お届けする商品が複数の場合、商品によっては同日のお届けとならず時期が若干ずれることがあります。予めご了承ください。<br/>
        <br/>
        当選したくじ商品によっては、購入くじ数以上の配送個数または、購入くじ数以下の同梱配送になることがあります。配送種類も、メール便・宅配便など複数となる場合もあります。<br/>
        <br/>
        ※配送業者・配送日時等の指定は出来ません。 <br/>
        ※配送先は国内に限ります。<br/>
        <br/>
        <SectionTitle title="ご注文後のお届け先の変更について" />
        ご注文のくじが販売期間中であればお届け先住所の変更が可能です。<br/>
        下記、お問い合わせページからご連絡ください。<br/>
        <br/>
        <a href="/#/contact">お問い合わせはこちら</a><br/>
        <br/>
        <SectionTitle title="商品の再配送について" />
        ご入力いただいたお届け先ご住所に誤りがあったり、長期のご不在で運送会社の保管期間が超過しますと、商品は当方に返送されます。<br/>
        <br/>
        当方まで返送された商品の再配送をご希望の場合、ご注文時の送料とは別に再配送の送料をご負担いただきます。再配送時には送料着払いで配送いたしますので、お届けした運送会社に再配送分の送料をお支払ください。以上のことをご了承いただいた上でお問い合わせからご依頼ください。<br/>
        <br/>
        <a href="/#/contact">お問い合わせはこちら</a><br/>
        <br/>
        <div>＜ご注意＞再配送のご手配は一度とさせていただきます。再配送をご希望され、再び商品をお受け取りいただけなかった場合には、ご注文をキャンセルとさせていただきます。受け取り不可によりキャンセルとなった場合、商品代金の返金はいたしませんので予めご了承ください。</div><br/>
        <br/>
        <SectionTitle title="商品の保管期間について" />
        当方まで返送された商品の保管期間は、発送日から60日間とさせていただきます。<br/>
        保管期限を過ぎた商品の再発送はいたしかねます。代金の返金もいたしかねますので予めご了承ください。<br/>
        <br/>
        <SectionTitle title="お問い合わせについて" />
        「お問い合わせ」より、24時間受け付けております。お手数ではございますが、より適切且つ迅速な対応をさせていただくために「よくあるご質問（FAQ）」をご確認の上でのお問い合わせにご協力賜りますようお願いいたします。<br/>
        <br/>
        <a href="/#/contact">お問い合わせはこちら</a><br/>
        <br/>
        <SectionTitle title="返品について" />
        サービスの特性上、お客様都合による商品の返品は承りかねます。商品が破損・汚損している場合、不良を起こして商品本来の役割をなさない場合（音声再生商品の機械部分の不具合・DVD商品の映像データ破損等）、くじの抽選結果と異なった商品が届いた場合、内容物や付属品等に欠品がある場合は返品を承ります。<br/>
        <br/>
        大変お手数ではございますが、商品到着から7日以内に「お問い合わせ」より、ご注文ID・不良がある商品名・不良状況をご連絡ください。当方でお調べした後、改めてお客様にご連絡をいたします。<br/>
        <br/>
        <a href="/#/contact">お問い合わせはこちら</a><br/>
        <br/>
        商品交換となった場合、お客様が不良品をお送りいただきます際の、送料・手数料は当方にて負担いたします。<br/>
        不良品をお送りいただくときに、梱包材が必要となりますので、お手数ではございますが、開封時に破棄なさらないようお願いいたします。<br/>
        <br/>
        破損や汚損につきましては、一般的な流通商品と比べて著しく状態の悪いもののみが交換対象となります。<br/>
        <br/>
        当方で販売に支障のない商品と判断させていただいた場合は、交換をお受けできないこともございますので、予めご了承ください。<br/>
        <br/>
        <SectionTitle title="Twitterキャンペーン注意事項" />
        厳正な抽選の上、当選者様にはキャンペーン終了後に「<a href="https://twitter.com/kujifes" target="_blank" rel="noopener noreferrer">@kujifes</a>」より、ダイレクトメッセージをお送りいたします。<br/>
        ダイレクトメッセージ内に記載してある必要事項をのご返信をお願いいたします。なお、ダイレクトメッセージをお送りしてから7日間以内にご返信がない場合は、当選を無効とさせていただきますのでご了承ください。<br/>
        <br/>
        ※配送先の住所に誤りがないようご注意ください。<br/>
        ※都合により賞品の発送が遅れる場合もございます。予めご了承ください。<br/>
        ※賞品のお届け先は日本国内に限らせていただきます。<br/>
        <br/>
        キャンペーンに関係ないと思われるリツイートについては、キャンペーン参加対象外とさせていただきます。<br/>
        <br/>
        下記の場合は当選を無効とさせていただきますので注意ください。<br/>
        <br/>
        1）「<a href="https://twitter.com/kujifes" target="_blank" rel="noopener noreferrer">@kujifes</a>」のフォローを解除した場合<br/>
        2）当選で獲得した権利を他人に譲渡した場合<br/>
        3）住所不明、転居先不明、長期不在などにより賞品をお届けできない場合<br/>
        4）不適切な行為を行われた場合、またはその可能性が高いと当社が判断した場合<br/>
        なお、キャンペーンに参加いただいた場合は、下記の諸条件に、ご同意いただいたものとさせていただきますので、あらかじめご了承ください。<br/>
        <br/>
        ※応募者は、キャンペーンの運用について事務局の運用方法に従うものとし、一切異議申立てを行わないものとします。<br/>
        ※本キャンペーンサイトの利用・利用停止・もしくは不能による損害について事務局は一切責任を負いかねます。（Twitter等、各種Webサービスのサーバダウン等も含む）<br/>
        ※当選者の賞品送付先として同一の住所が複数ご申告された場合、ご申告の日時が一番早い宛先のみを有効とし、以降は無効とさせていただきます。<br/>
        ※当社は、当サイトの利用によるいかなるトラブル・損害（直接、間接の損害別を問わず）が発生したとしても一切の責任を負いません。<br/>
        ※予告なくキャンペーン内容を変更する場合がございます。<br/>
        <br/>
        取得した個人情報に関しては、<a href="https://goodroid.co.jp/policy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に基づいてお取り扱いいたします。
      </div>
    );
  }
}

class Notes extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <section className="notes">
            <SectionTitle title="注意事項" />
            <Contents />
          </section>
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default Notes;