import React, { Component } from 'react';
import { connect } from 'react-redux';
import { purchaseHistoryDetailRequest } from '../actions/actions';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import PrizeCardDetail from '../components/prize-card-detail';
import BackButton from '../components/back-button';
import { imgPath } from '../common/helper';
import { getPaymentTypeText, getDateFormat, getShippingStatusTypeText } from '../common/helper';
import ReactGA from 'react-ga';
import { sendGA } from '../common/helper';

var historyItem = {}

class HistoryDetailSection extends Component {
  render () {
    const historyItem = this.props.historyItem;
    const orderItems = this.props.historyItem.orderItems;
    console.log("historyItem", historyItem);
    return (
      <section className="history-detail">
        <SectionTitle title="購入履歴詳細" />
        <div className="history-item-detail">
          <div className="history-item-detail__img">
            <img src={historyItem.imgUrl} alt={historyItem.title} />
          </div>
          <div className="history-item-detail__contents">
            <div className="history-item-detail__contents-wrapper">
              <p className="history-item-detail__no">ご注文番号 : {historyItem.no}</p>
              <h4 className="history-item-detail__title"><a href={ historyItem.prizeItems.length > 0 ? "/lp/" + historyItem.prizeItems[0].rscdir + "/" : "/" }>{historyItem.title}</a></h4>
              <span className="history-item-detail__amount">購入数 : {historyItem.amount}個</span>
              <span className="history-item-detail__total">合計 : {historyItem.total.toLocaleString()}円</span><br className="sp" />
              <span className="history-item-detail__date">ご注文日時 : {historyItem.date}</span>
            </div>
            <div className="pc">
              <div className="form-inner">
                <dl>
                  <dt>配送先</dt>
                  <dd>{orderItems.addressee}</dd>
                </dl>
                <dl>
                  <dt>お支払い方法</dt>
                  <dd>{orderItems.paymentMethod}<br className="sp" />{orderItems.paymentNo && "(" + orderItems.paymentNo + ")"}</dd>
                </dl>
                <dl>
                  <dt>ステータス</dt>
                  <dd>{orderItems.status}</dd>
                </dl>
                <dl>
                  <dt>お届け目安</dt>
                  <dd>{orderItems.scheduledDate}</dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="sp">
          <hr />
            <div className="form-inner">
              <dl>
                <dt>配送先</dt>
                <dd>{orderItems.addressee}</dd>
              </dl>
              <dl>
                <dt>お支払い方法</dt>
                <dd>{orderItems.paymentMethod}<br className="sp" />{orderItems.paymentNo && "(" + orderItems.paymentNo + ")"}</dd>
              </dl>
              <dl>
                <dt>ステータス</dt>
                <dd>{orderItems.status}</dd>
              </dl>
              <dl>
                <dt>お届け目安</dt>
                <dd>{orderItems.scheduledDate}</dd>
              </dl>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

class ItemDetail extends Component {
  render () {
    return (
      <div className="prize-item-detail">
        <ul>
          {this.props.historyItem.prizeItems.map((item, i) => {
            return (
              <PrizeCardDetail key={i} item={item} />
            );
          })}
        </ul>
      <BackButton linkTo="/mypage" text="戻る" />
      </div>
    );
  }
}

class HistoryDetail extends Component {
  constructor(props) {
    super(props);
    console.log("HistoryDetail props", props);

    const { login, match } = this.props;

    if(match.params.oid == null) {
      this.props.history.push("/404");
    }

    const data = {
      usid: login.usid,
      seid: login.seid,
      oid: match.params.oid,
    }
    this.props.dispatchHistoryDetailRequst(data);
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    sendGA(pathname);
  }

  componentDidUpdate() {
    const { purchaseHistoryDetail } = this.props;
    console.log("this.props => ",this.props);

    // コンビニ決済・WebMoney決済のみGAに送信処理
    switch(purchaseHistoryDetail.paymentType) {
      case 3:
      case 7:
        let price = purchaseHistoryDetail.totalPrice - (purchaseHistoryDetail.shipping + purchaseHistoryDetail.tax);
        let pos = purchaseHistoryDetail.title.indexOf("≪");
        let title = purchaseHistoryDetail.title.substring(0, pos);

        // GAにe-コマース情報を送る
        ReactGA.plugin.require('ecommerce');
        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
          id: purchaseHistoryDetail.oid,
          affiliation: title,
          revenue: purchaseHistoryDetail.totalPrice,
          shipping: purchaseHistoryDetail.shipping,
          tax: purchaseHistoryDetail.tax
        });
        ReactGA.plugin.execute('ecommerce', 'addItem', {
          id: purchaseHistoryDetail.oid,
          name: title,
          sku: purchaseHistoryDetail.lotteryResult.productId,
          price: price,
          quantity: 1
        });
        ReactGA.plugin.execute('ecommerce', 'send');
        break;
      default:
        break;
    }
  }

  render() {
    const { purchaseHistoryDetail, match } = this.props;
    if (match.params.oid !== purchaseHistoryDetail.oid) {
      return (
        <div className="wrapper">
          <Header />
        </div>);
    }
    var dateFormat = getDateFormat(purchaseHistoryDetail.purchaseDate);
    var prizeItems = [];
    for (var key in purchaseHistoryDetail.lotteryResult.premiumItemList) {
      var item = purchaseHistoryDetail.lotteryResult.premiumItemList[key];
      prizeItems.push({
        prizeName: item.itemAwardName,
        imgSrc: imgPath(purchaseHistoryDetail.rscdir + "/img/item/item_" + item.rscid + ".jpg"),
        imgAlt: item.itemAwardName + "賞",
        title: item.lotteryItemTitle,
        amount: item.num,
        note: item.note,
        snsTagList: item.snsTagList,
        rscdir: purchaseHistoryDetail.rscdir,
        rscid: item.rscid,
      });
    }
    for (key in purchaseHistoryDetail.lotteryResult.itemList) {
      item = purchaseHistoryDetail.lotteryResult.itemList[key];
      prizeItems.push({
        prizeName: item.itemAwardName,
        imgSrc: imgPath(purchaseHistoryDetail.rscdir + "/img/item/item_" + item.rscid + ".jpg"),
        imgAlt: item.itemAwardName + "賞",
        title: item.lotteryItemTitle,
        amount: item.num,
        note: item.note,
        snsTagList: item.snsTagList,
        rscdir: purchaseHistoryDetail.rscdir,
        rscid: item.rscid,
      });
    }
    historyItem = {
      no: purchaseHistoryDetail.oid,
      imgUrl: imgPath(purchaseHistoryDetail.rscdir + "/img/product/product_" + purchaseHistoryDetail.ltp + ".jpg"),
      title: purchaseHistoryDetail.title,
      amount: purchaseHistoryDetail.num,
      total: purchaseHistoryDetail.totalPrice,
      date: dateFormat,
      prizeItems: prizeItems,
      orderItems: {
        addressee: "〒" + purchaseHistoryDetail.postalCode1 + "-" + purchaseHistoryDetail.postalCode2 + " " + purchaseHistoryDetail.prefectures + purchaseHistoryDetail.address1 + purchaseHistoryDetail.address2,
        paymentMethod: getPaymentTypeText(purchaseHistoryDetail.paymentType),
        paymentNo: purchaseHistoryDetail.cnb,
        status: getShippingStatusTypeText(purchaseHistoryDetail.shippingStatus),
        scheduledDate: purchaseHistoryDetail.shippingSchedule,
      }
    }
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <HistoryDetailSection historyItem={historyItem} />
          <ItemDetail historyItem={historyItem} />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { login, purchaseHistoryDetail } = state;
  return {
    login: login,
    purchaseHistoryDetail: purchaseHistoryDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchHistoryDetailRequst: data => dispatch(purchaseHistoryDetailRequest(data, 'purchase-history-detail')),
   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryDetail);