import { call, put, takeEvery } from 'redux-saga/effects';
import checkResponse from '../common/checkResponse';
import { PRODUCT_REQUEST, productSuccess, productFailure } from '../actions/actions';
import api from '../common/api';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(productSuccess(payload));
    } else {
      yield put(productFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const productSaga = [takeEvery(PRODUCT_REQUEST, handleRequest)];
export default productSaga;