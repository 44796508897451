import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import NormalButton from '../components/normal-button';
import { sendGA } from '../common/helper';

function MessageSection() {
  return (
    <section className="purchase-complete-webmoney">
      <SectionTitle title="お支払い手続きのご案内" />
      <p className="message">入力いただいたメールアドレスへ、決済手続きのご案内をお送りさせていただきました。<br />
      数時間経ってもメールが届かない場合は、お手数ですが<a style={{color: "#00B2F4"}} href="/#/contact">お問い合わせ</a>よりご連絡ください。<br />
      メールが届かない場合は、迷惑メールボックスの設定解除、system@p01.mul-pay.comからのドメイン着信許可の設定をご確認ください。<br /><br />
      WebMoneyでのお支払いにつきましては、ご注文完了時の抽選演出は再生されません。<br />抽選結果に関しましては、ご注文完了時に届くお知らせメールまたはマイページにてご確認ください。<br /><br />
      </p>
      <div className="button-wrapper">
        <NormalButton linkTo="/mypage" buttonColor="white" text="マイページへ" />
      </div>
    </section>
  );
}

class PurchaseInfoWebMoney extends Component {
  componentDidMount() {
    const { pathname } = this.props.location;

    sendGA(pathname);
  }

  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <MessageSection />
        </div>
        <PageTop />
        <Footer />
      </div>
    );
  }
}
export default PurchaseInfoWebMoney;