import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import { RenderField } from './render-field';
import { required } from '../common/validate';
import BackButton from './back-button';
import SubmitButton from './submit-button';
// import CommonModal from '../components/common-modal';
// import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

let initial = {
  contents: "",
};

class ContactOtherForm extends Component {

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // 確認画面に入力情報を渡す
  handleSubmit(e) {
    const { login, invalid, formValues, dispatchFormRequest, api, reset } = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    let param = Object.assign({},
      formValues,
      login
    );

    console.log("param ==> ", param);

    if(invalid === false){
      // リクエストを投げる
      if(dispatchFormRequest != null) {
        dispatchFormRequest(param, api);
        reset();
      }
    }
  }

  submit = values => {
    // print the form values to the console
    console.log("submit ==>", values);
  }

  render() {
    const { invalid } = this.props;

    return (
      <form name="contactOtherForm" id="contactOtherForm" className="entry-form h-adr" onSubmit={this.submit}>
        <div>
          <p>本フォームよりいただいたご質問・ご意見は、担当者が全て確認のうえ、お答えするよう努力致しますが、全ての回答や返信をお約束するものではありません。何卒ご了承下さい。<br /><br /></p>
        </div>
        <div className="form-inner">
          <dl>
            <dt>お問い合わせ内容<span className="required">※</span></dt>
            <dd>
              <Field id={'contents'} name="contents" component={RenderField} type="textarea" maxLength="200" validate={[required]} className={'form-inner__input--long form-inner__textarea'} />
            </dd>
          </dl>
        </div>

        <section class="delivery-address">
        <div className="button-wrapper">
          <div className="button-wrap">
            <BackButton linkTo="/contact" text="戻る" />
          </div>
          <div className="button-wrapper--centering">
            <SubmitButton onClick={this.handleSubmit} text="送信" disabled={invalid} />
          </div>
        </div>
        </section>
      </form>
    );
  }
}

ContactOtherForm = reduxForm({
  form: 'contactOtherForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: initial,
})(ContactOtherForm);


ContactOtherForm = connect(state => {
  return {
    formValues: getFormValues('contactOtherForm')(state),
    formMeta: getFormMeta('contactOtherForm')(state),
  }
})(ContactOtherForm);

export default ContactOtherForm;
