import React, { Component } from 'react';
import Modal from 'react-modal';
import Close from '../images/common/close.png';
import SubmitButton from '../components/submit-button';
// import { dispatch } from 'rxjs/internal/observable/range';

const movefun = function(event){
	event.preventDefault();
}

class CustomModal extends Component {
  constructor () {
    super();
    this.state = {
      // showModal: false,
    };
    // this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  componentWillUnmount () {
    document.body.style.overflow = this.state.originalBodyOverflow;
    // iOS用スクロール有効
    window.removeEventListener( 'touchmove' , movefun, { passive: false } );
  }
  handleOpenModal () {
    // this.setState({
      // showModal: true,
      // originalBodyOverflow: document.body.style.overflow
    // });
    // document.body.style.overflow = 'hidden';
    // iOS用スクロール無効
    window.addEventListener('touchmove', movefun, { passive: false });
  }
  handleCloseModal () {
    // this.setState({ showModal: false });
    this.props.dispatcOrderFailure();
    // document.body.style.overflow = this.state.originalBodyOverflow;
    // iOS用スクロール有効
    window.removeEventListener( 'touchmove' , movefun, { passive: false } );
    console.log("handleCloseModal this.props",this.props);
  }
  render() {
    const { login, product, order, dispatcOrderRequst } = this.props;

    // if(order.sc != null && order.sc == 0){
    //   this.handleOpenModal();
    // }

    const orderParam = {
      usid: login.usid,
      seid: login.seid,
      pid: product.pid,
      num: product.num,
    }

    return (
      <React.Fragment>
        <div className="button-wrapper--centering">
          <SubmitButton text={this.props.buttonText} onClick={e => dispatcOrderRequst(orderParam)} />
        </div>
        <Modal
          isOpen={order.showModal}
          contentLabel="payment"
          className="modal"
          overlayClassName="overlay"
          onRequestClose={this.handleCloseModal}
          ariaHideApp = {false}>

          <button className="close-modal" onClick={this.handleCloseModal}><img src={Close} alt="閉じる" /></button>
          {this.props.children}
        </Modal>
      </React.Fragment>
    );
  }
}

export default CustomModal;