import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../components/header';
import Footer from '../components/footer';
import PageTop from '../components/page-top';
import SectionTitle from '../components/section-title';
import NormalButton from '../components/normal-button';


class ContactComplete extends Component {

  render() {
    const { account:{ email } } = this.props;

    return (
      <div className="wrapper">
        <Header />
        <div className="container">
          <SectionTitle title="お問い合わせ完了" />
          <p>
            お問い合わせいただきありがとうございます。<br /><br />
            いただいた内容は登録されているメールアドレス{ email !=="" && <span>({email})</span> }に確認としてメールを送信させていただいております。<br /><br />
            お問い合わせいただいた内容に関しましての返信には最大5営業日ほどお時間をいただく場合がございます。返信の際はinfo@kujifes.com よりメールにてお送りいたしますので、ドメイン設定の解除などをお願いいたします。<br />
            営業時間：10:00〜19:00（土日祝を除く）<br /><br /><br /><br />
          </p>
          <div className="button-wrapper t-center">
            <NormalButton linkTo="/" buttonColor="white-red" text="トップページへ"/>
          </div>
        </div>
        <PageTop />
        <Footer />
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { account } = state;

  return {
    account: account,
  }
}

export default connect(
  mapStateToProps,
)(ContactComplete);