import { call, put, fork, select, takeEvery, take } from 'redux-saga/effects';
import redirect from '../common/redirect';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import {
  PURCHASE_CONFIRM_REQUEST,
  PURCHASE_PRODUCT_REQUEST,
  PURCHASE_CHECK_SERISE_REQUEST,
  PURCHASE_ACCOUNT_REQUEST,
  loginClear,
  productSuccess,
  productFailure,
  checkSeriesSuccess,
  checkSeriesFailure,
  accountSuccess,
  accountFailure,
  purchaseProductRequest,
  purchaseCheckSeriesRequest,
  purchaseAccountRequest
} from '../actions/actions';
import api from '../common/api';


function* handleLoginCheck(action) {
  //const action = yield take(PURCHASE_CONFIRM_REQUEST, handleLoginCheck);
  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    payload.isLogin = payload.authorized;

    if( payload.sxp === false && payload.errMsg == null ) {
      const { login, product } = yield select();
      const param = {
        usid: login.usid,
        seid: login.seid,
        srid: product.srid,
        ltp: product.ltp,
        num: product.num,
      };
      yield put(purchaseProductRequest(param,'product'));
    } else {
      yield put(loginClear(payload));
      yield put(push("/login"));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

function* handleProduct() {
  console.log("call handleProduct");
  const action = yield take(PURCHASE_PRODUCT_REQUEST);

  try {
    const response = yield call(api, action);
    if ((response.status >= 200 && response.status < 300) || response.status === 400) {
      const payload = yield response.json();

      if( payload.sc === 0 && payload.errMsg == null ) {
        yield put(productSuccess(payload));
        const { product } = yield select();
        const param = {
          srid: product.srid,
        };
        yield put(purchaseCheckSeriesRequest(param,'check-series'));
      } else {
        yield put(productFailure(payload));
      }
    } else {
      yield put(redirect(response.status));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

function* handleSerise() {
  const action = yield take(PURCHASE_CHECK_SERISE_REQUEST);

  try {
    const response = yield call(api, action);

    if ((response.status >= 200 && response.status < 300) || response.status === 400) {
      const payload = yield response.json();

      if( payload.sc === 0 && payload.errMsg == null ) {
        yield put(checkSeriesSuccess(payload));
        const { login } = yield select();
        const param = {
          usid: login.usid,
          seid: login.seid,
        };
        yield put(purchaseAccountRequest(param,'account'));
      } else {
        yield put(checkSeriesFailure(payload));
      }
    } else {
      yield put(redirect(response.status));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

function* handleAccount() {
  const action = yield take(PURCHASE_ACCOUNT_REQUEST);

  try {
    const response = yield call(api, action);

    if ((response.status >= 200 && response.status < 300) || response.status === 400) {
      const payload = yield response.json();

      if( payload.sxp === false && payload.errMsg == null ) {
        yield put(accountSuccess(payload));
      } else {
        yield put(loginClear(payload));
        yield put(accountFailure(payload));
        yield put(push("/login"));
      }
    } else {
      yield put(redirect(response.status));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

// function* handlePurchaseConfirm(){
//   yield takeEvery(PURCHASE_CONFIRM_REQUEST, handleLoginCheck);
// }

const purchaseConfirmSaga = [
  takeEvery(PURCHASE_CONFIRM_REQUEST, handleLoginCheck),
  fork(handleProduct),
  fork(handleSerise),
  fork(handleAccount)
];
export default purchaseConfirmSaga;