import { call, put, takeEvery } from 'redux-saga/effects';
import { USER_EDIT_REQUEST, userEditSuccess, userEditFailure, loginSuccess } from '../actions/actions';
import api from '../common/api';
import checkResponse from '../common/checkResponse';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(loginSuccess(payload));
      yield put(userEditSuccess(payload));
    } else {
      yield put(userEditFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);
  }
}

const userEditSaga = [takeEvery(USER_EDIT_REQUEST, handleRequest)];
export default userEditSaga;