import React, { Component } from 'react';
import { history } from '../configureStore';

class MiniButtonRound extends Component {
  render() {
    // const buttonColor = this.props.buttonColor;
    return (
      <button type="button" onClick={() => {history.push(this.props.linkTo)}} className="button-mini-round">{this.props.text}</button>
    );
  }
}
export default MiniButtonRound;