import React, { Component } from 'react';
import SectionTitle from '../components/section-title';
import ItemCard from '../components/item-card';
import NormalButton from '../components/normal-button';
import { imgPath } from '../common/helper';
import { sortOrder } from '../common/utile';
import { categoryName, thumbLabelName, thumbLabelClass, SALE_TYPE } from '../common/const';

const categoryKeys = Object.keys(categoryName);
var saleItems = [];
var soldItems = [];
var categorySaleItems = [];
var categorySoldItems = [];

/*
 *  販売リスト表示用データの加工処理
 */
function createSaleItems(targetList) {
  let _resultItems = [];
  let _comingList = [];
  const _list = targetList;

  // 開始時間の遅い方が上（降順）
  sortOrder(_list, "startTime", "desc");
  // マスタの重みの値が大きい順（降順）
  sortOrder(_list, "twg", "desc");

  // for(let i in categorySeriesList){
  for (let i=0; i<_list.length; i++  ){
    if(_list[i].salesLabelType === SALE_TYPE.COMING_SOON) {
      _comingList.push(_list[i]);
      _list.splice(i, 1);
      i--;
    }
  }
  // 開始前のくじは開始時間が早い順（昇順）
  sortOrder(_comingList, "startTime", "asc");
  Array.prototype.push.apply(_list, _comingList);

  for (var activeKey in _list) {
    const series = _list[activeKey];
    _resultItems.push({linkTo: "/lp/" + series.rscdir + "/", imgSrc: imgPath(series.rhash + "/img/top/top_thumb.jpg"), imgAlt: series.title, salesLabelType: series.salesLabelType, labelKind: thumbLabelName[series.salesLabelType], labelClass: thumbLabelClass[series.salesLabelType], title: series.title, startTime: series.startTime, endTime: series.endTime, category: series.category, price: series.lotteryPrice, twg: series.twg});
  }

  return _resultItems;
}

/*
 *  販売終了リスト表示用データの加工処理
 */
function createSoldItems(targetList) {
  let _resultItems = [];

  for (var endKey in targetList) {
    const endSeries = targetList[endKey];
    _resultItems.push({linkTo: "/lp/" + endSeries.rscdir + "/", imgSrc: imgPath(endSeries.rhash + "/img/top/top_thumb.jpg"), imgAlt: endSeries.title, salesLabelType: endSeries.salesLabelType, labelKind: thumbLabelName[endSeries.salesLabelType], labelClass: thumbLabelClass[endSeries.salesLabelType], title: endSeries.title, startTime: endSeries.startTime, endTime: endSeries.endTime, category: endSeries.category});
  }

  return _resultItems;
}


class CategoryContent extends Component {
  render() {
    // 初期化
    saleItems = [];
    soldItems = [];

    // カテゴリ「すべて」の一覧生成
    const seriesList = this.props.seriesList;
    if(seriesList != null && !this.props.isEndListOnly) {
      saleItems = createSaleItems(seriesList);
    }
    const endSeriesList = this.props.endSeriesList;
    soldItems = createSoldItems(endSeriesList);

    // カテゴリ「すべて」以外の一覧生成
    const categorySeriesList = this.props.categorySeriesList;
    if(categorySeriesList != null ) {
      categorySaleItems = createSaleItems(categorySeriesList);
    }
    const categoryEndSeriesList = this.props.categoryEndSeriesList;
    categorySoldItems = createSoldItems(categoryEndSeriesList);

    // console.log("category =>" + category);
    // 同じカテゴリをまとめる
    // category = category.filter(function (x, i, self) {
    //   return self.indexOf(x) === i;
    // });
    // カテゴリソート
    // category.sort(function(a,b){
    //   if( a > b ) return 1;
    //   if( a < b ) return -1;
    //   return 0;
    // });

    return (
      <ItemSort isEndListOnly={this.props.isEndListOnly} endSeriesLimitCount={this.props.endSeriesLimitCount} endSeriesCount={endSeriesList.length} categorySeriesRequest={this.props.categorySeriesRequest} />
    );
  }
}
export default CategoryContent;

class ItemSort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectKey: categoryKeys[0],
      activeItems: saleItems,
      endItems: soldItems,
    };
    this.handleActive = this.handleActive.bind(this);
    this.initHidden = this.initHidden.bind(this);
  }
  // ちらつき防止用
  initHidden() {
    Array.from(document.getElementsByClassName('sold-item-list')).forEach((el, index) => {
      el.setAttribute('style', 'opacity:0');
      setTimeout(() =>
      el.removeAttribute('style'),50)
    })
  }
  handleActive(selectKey) {
    if(selectKey === this.state.selectKey) return;

    if(selectKey === categoryKeys[0]) {
      setTimeout(() => {
        this.setState({
          selectKey: selectKey
        });
      });
    }else{
      // 一度初期化
      categorySaleItems = [];
      categorySoldItems = [];
      this.setState({
        selectKey: selectKey,
      });
      setTimeout(() => {
        const param = { category: selectKey };
        this.props.categorySeriesRequest(param);
      });
    }

    this.initHidden();
  }
  // ※iOSの場合のみ描画タイミングが遅く、値段部分の表示が画像より先に出ることがあり結果ちらつきが発生するため
  // 値段表示部分のみdelayをいれて対応しています。現在1000msで設定していますが、もし気になるようでしたらご調整ください。
  // 2019/02/11 初めから描画させてみる(iOSでちらついたら場合分けする)
  componentDidUpdate(){
    Array.from(document.getElementsByClassName('item-card__img--price')).forEach((el, index) => {
      el.setAttribute('style', 'display:none');
      setTimeout(() =>
      el.removeAttribute('style'),0)
    })
  }

  render () {
    const { endSeriesCount } = this.props;
    let activeItems = [];
    let endItems = [];
    let pcOnlyFlag= false;

    if(this.state.selectKey === categoryKeys[0]) {

      pcOnlyFlag = true;

      saleItems.map((item, i) => {
        activeItems.push(item);
        return null;
      });

      soldItems.map((item, i) => {
        if (this.props.endSeriesLimitCount > 0 && i >= this.props.endSeriesLimitCount) return true;
        endItems.push(item);
        return null;
      });
    } else {
      console.log("categorySaleItems => ", categorySaleItems);
      console.log("categorySoldItems => ", categorySoldItems);
      categorySaleItems.map((item, i) => {
        activeItems.push(item);
        return null;
      });
      categorySoldItems.map((item, i) => {
        endItems.push(item);
        return null;
      });

    }

    var categoryList = [];

    for(var i in categoryName) {
      const key = i;
      categoryList.push(<li key={key}><span className={this.state.selectKey === i ? "category-link active" : "category-link"} onClick={() => this.handleActive(key)}>{categoryName[key]}</span></li>);
    }

    console.log("activeItems ==>", activeItems);

    return (
      <section className="category-list">
        <div className="sale-item-list">
          <ul className="item-sort">
            {categoryList}
          </ul>
          {activeItems.length > 0 && this.props.isEndListOnly === false &&
            <ul className="item-wrapper">
            {activeItems.map((item, i) => {
              return (
                <ItemCard key={i} item={item} index={i} />
              );
            })}
            </ul>
          }

          {activeItems.length === 0 &&
            <div className="no-sale-item-wrap">
              <span className="no-sale-item-text">現在、このカテゴリーの販売中のくじはありません。</span>
            </div>
          }
        </div>

        {endItems.length > 0 &&
          <div className="sold-item-list">
            <SectionTitle title="販売終了"/>
            <ul className="item-wrapper">
              {endItems.map((item, i) => {
                if (i < 2) {
                  return (
                    <ItemCard key={i} item={item} index={i} />
                  );
                } else {
                  return (
                    <ItemCard key={i} item={item} index={i} isPCOnly={pcOnlyFlag} />
                  );
                }
              })}
            </ul>
          </div>
        }

        {!this.props.isEndListOnly && endSeriesCount > 0 &&
          <div className="button-wrapper">
            <NormalButton linkTo="/item-end-list" buttonColor="white-red" text="販売終了くじ一覧へ"/>
          </div>
        }
      </section>
    );
  }
}