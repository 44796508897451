import { USER_EDIT_SUCCESS, USER_EDIT_FAILURE } from '../actions/actions';

const initialState = {};

export default function top(state = initialState, action) {
  switch (action.type) {
    case USER_EDIT_SUCCESS:
      console.log(action.type, action);
      state = action.payload;
      return state;
    case USER_EDIT_FAILURE:
      console.log(action.type, action);
      // state = action.payload;
      // return state;
      return Object.assign({},
        state,
        {
          errMsg: action.payload.errMsg
        });
    default:
      return state;
  }
}