const Config= () => {
  if (process.env.NODE_ENV === 'test') {
    // test
    return {
      restful: {
        appURL: process.env.REACT_APP_TEST_URL,
        // apiKey: process.env.REACT_APP_TEST_API_KEY,
        dynamicLinksDomain: process.env.REACT_APP_TEST_DYNAMIC_LINKS_DOMAIN,
        firebaseKey: process.env.REACT_APP_TEST_GOOGLE_FIREBASE_KEY,
        apiURL: process.env.REACT_APP_TEST_API_URL,
        purchaseURL: process.env.REACT_APP_TEST_SHOP_PURCHASE_URL,
        cardEditURL: process.env.REACT_APP_TEST_CARD_EDIT_URL,
        shopID: process.env.REACT_APP_TEST_SHOP_ID,
        siteID: process.env.REACT_APP_TEST_SITE_ID,
        gaID: process.env.REACT_APP_TEST_GOOGLE_ANALYTICS_ID,
        recaptchaID: process.env.REACT_APP_TEST_GOOGLE_RECAPTCHA_ID,
      }
    }
  } else if (process.env.NODE_ENV === 'staging') {
    // staging
    return {
      restful: {
        appURL: process.env.REACT_APP_STG_URL,
        // apiKey: process.env.REACT_APP_STG_API_KEY,
        dynamicLinksDomain: process.env.REACT_APP_STG_DYNAMIC_LINKS_DOMAIN,
        firebaseKey: process.env.REACT_APP_STG_GOOGLE_FIREBASE_KEY,
        apiURL: process.env.REACT_APP_STG_API_URL,
        purchaseURL: process.env.REACT_APP_STG_SHOP_PURCHASE_URL,
        cardEditURL: process.env.REACT_APP_STG_CARD_EDIT_URL,
        shopID: process.env.REACT_APP_STG_SHOP_ID,
        siteID: process.env.REACT_APP_STG_SITE_ID,
        gaID: process.env.REACT_APP_STG_GOOGLE_ANALYTICS_ID,
        recaptchaID: process.env.REACT_APP_STG_GOOGLE_RECAPTCHA_ID,
      }
    }
  } else if (process.env.NODE_ENV === 'production') {
    // production
    return {
      restful: {
        appURL: process.env.REACT_APP_PROD_URL,
        // apiKey: process.env.REACT_APP_PROD_API_KEY,
        dynamicLinksDomain: process.env.REACT_APP_PROD_DYNAMIC_LINKS_DOMAIN,
        firebaseKey: process.env.REACT_APP_PROD_GOOGLE_FIREBASE_KEY,
        apiURL: process.env.REACT_APP_PROD_API_URL,
        purchaseURL: process.env.REACT_APP_PROD_SHOP_PURCHASE_URL,
        cardEditURL: process.env.REACT_APP_PROD_CARD_EDIT_URL,
        shopID: process.env.REACT_APP_PROD_SHOP_ID,
        siteID: process.env.REACT_APP_PROD_SITE_ID,
        gaID: process.env.REACT_APP_PROD_GOOGLE_ANALYTICS_ID,
        recaptchaID: process.env.REACT_APP_PROD_GOOGLE_RECAPTCHA_ID,
      }
    }
  }
  // development
  return {
    restful: {
      appURL: process.env.REACT_APP_DEV_URL,
      // apiKey: process.env.REACT_APP_DEV_API_KEY,
      dynamicLinksDomain: process.env.REACT_APP_DEV_DYNAMIC_LINKS_DOMAIN,
      firebaseKey: process.env.REACT_APP_DEV_GOOGLE_FIREBASE_KEY,
      apiURL: process.env.REACT_APP_DEV_API_URL,
      purchaseURL: process.env.REACT_APP_DEV_SHOP_PURCHASE_URL,
      cardEditURL: process.env.REACT_APP_DEV_CARD_EDIT_URL,
      shopID: process.env.REACT_APP_DEV_SHOP_ID,
      siteID: process.env.REACT_APP_DEV_SITE_ID,
      gaID: process.env.REACT_APP_DEV_GOOGLE_ANALYTICS_ID,
      recaptchaID: process.env.REACT_APP_DEV_GOOGLE_RECAPTCHA_ID,
    }
  }
}
export const restfulApiConfig = Config().restful;

const ImgPath = () => {
  console.log("process.env ==> ", process);
  if (process.env.NODE_ENV === 'test') {
    // test
    return {
      path: process.env.REACT_APP_TEST_IMG_PATH
    }
  } else if (process.env.NODE_ENV === 'production') {
    // production
    return {
      path: process.env.REACT_APP_PROD_IMG_PATH
    }
  } else if (process.env.NODE_ENV === 'staging') {
    // production
    return {
      path: process.env.REACT_APP_STG_IMG_PATH
    }
  }
  // development
  return {
    path: process.env.REACT_APP_DEV_IMG_PATH
  }
}
export const imagePath = ImgPath().path;


export const resourceVersion = window.kf.resourceVersion;