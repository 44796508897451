const ErrorMessages = {
  required: '必須項目です',
  select: '選択してください',
  halfUpper: '英字は小文字のみとなります。',
  email: '正しいメールアドレスの形式で入力してください',
  confirmEmail : 'メールアドレスがが一致しません',
  password: 'パスワードは6文字以上16文字以内で半角英字、半角数字の各1文字以上を含んでください',
  confirmPassword : 'パスワードが一致しません',
  url: 'URLの形式が間違っています。 例：https://example.com',
  specialCharacter: '特殊文字が含まれています',
  katakana: 'カタカナを入力してください',
  number: '数字を入力してください',
  phone: '電話番号の形式が正しくありません',
}

const Regex = {
  halfUpper : /^[a-z0-9.!@#$%&'*+/=?^_`{|}~-]*$/,
  email: /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)[!-~]{6,16}$/,
  url: /^(https?)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/,
  answerCharacter: /^[!！@#$%^&*()_+\-=[\]{};':"\\|,.<>/?？]*$/,
  specialCharacter: /[!！"#$¥￥%％&＆'()*+\-.,/:;<=>?？@＠[\\\]^_`{|}~]+.*?$/,
  katakanaCharacter: /^[ァ-ヶー]*$/,
  number: /^[0-9]+$/,
  phone1: (/^(0[1-9]|0[5-9]0|0[1-9][1-9][0-9]|0[1-9][1-9][0-9][1-9])$/),
  phone2: /^\d{2,4}$/,
  phone3: /^\d{3,4}$/,
}

export const required = value => (value || typeof value === 'number' ? undefined : ErrorMessages.required);

export const addressRequired = value => (value || value !== '' ? undefined : ErrorMessages.required);

export const kana = value => (value && Regex.katakanaCharacter.test(value) ? undefined: ErrorMessages.katakana);

export const number = value => (value && Regex.number.test(value) ? undefined: ErrorMessages.number);

export const phone1 = value => (value && Regex.phone1.test(value) ? undefined: ErrorMessages.phone);
export const phone2 = value => (value && Regex.phone2.test(value) ? undefined: ErrorMessages.phone);
export const phone3 = value => (value && Regex.phone3.test(value) ? undefined: ErrorMessages.phone);

export const requiredPrefectures = value => (value && value !== '都道府県' ? undefined : ErrorMessages.select);

export const requiredSelect = value => (value && value !== '0' ? undefined : ErrorMessages.select);

export const halfUpper = value => (value && Regex.halfUpper.test(value) ? undefined: ErrorMessages.halfUpper);

export const email = value => (value && Regex.email.test(value) ? undefined: ErrorMessages.email);
export const emailConfirmation = (value, values) => (value === values.email ? undefined: ErrorMessages.confirmEmail);

export const specialCharacter = value => (value && Regex.specialCharacter.test(value) ? ErrorMessages.specialCharacter: undefined);

export const password = value => (value && Regex.password.test(value) ? undefined: ErrorMessages.password);
export const passwordConfirmation = (value, values) => (value === values.phash ? undefined: ErrorMessages.confirmPassword);
export const passwordEdit = value => (value && (Regex.password.test(value) || value === '********') ? undefined: ErrorMessages.password);
export const answerEdit = value => (value && (!Regex.answerCharacter.test(value) || value === '********') ? undefined: ErrorMessages.specialCharacter);

export const url = value => (value && !Regex.url.test(value) ? ErrorMessages.url : undefined);

export const extendValidator = text => func => (func ? text : undefined);