import React, { Component } from 'react';
import Modal from 'react-modal';
import Close from '../images/common/close.png';

class CommonModal extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({showModal: true});
  }

  handleCloseModal() {
    this.setState({showModal: false});
  }

  render() {
    const { color, title, message } = this.props;

    const titleClass= (color == null)? "modal__headline": "modal__headline modal__headline--" + color;
    const wrapClass= (color == null)? "modal-wrap": "modal-wrap modal-wrap--" + color;
    return (
      <React.Fragment>
        <a onClick={this.handleOpenModal}>{this.props.children}</a>
        <Modal
          isOpen={this.state.showModal}
          contentLabel="missing"
          className="modal"
          overlayClassName="overlay"
          onRequestClose={this.handleCloseModal}
          ariaHideApp = {false}>
          {/* <a href="javascript:void(0)" className="close-modal" onClick={this.handleCloseModal}><img src={Close} alt="" /></a> */}
          <button onClick={this.handleCloseModal} className="close-modal"><img src={Close} alt="" /></button>
          <div className={wrapClass}>
            <div className="purchase-modal">
              <h3 className={titleClass}>{title}</h3>
              <pre className="modal__text">{message}</pre><br/>
              {/* <a href="javascript:void(0)" onClick={this.handleCloseModal}><button className="button-red">OK</button></a> */}
              <button onClick={this.handleCloseModal} className="button-red">OK</button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
export default CommonModal;