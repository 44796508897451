import { ACCOUNT_SUCCESS, ACCOUNT_FAILURE } from '../actions/actions';
const initialState = {
  surname: "",
  name: "",
  postalCode1: "",
  postalCode2: "",
  prefectures: "",
  address1:"",
  address2:"",
  phone1:"",
  phone2:"",
  email:"",
  phash:"********",
  confirmPhash:"********",
  ahash:"********",
};

export default function account(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case ACCOUNT_SUCCESS:
    case ACCOUNT_FAILURE:
      //console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload.account
      );
    default:
      return state;
  }
}