import { INQUIRY_CONTACT_FAILURE } from '../actions/actions';
const initialState = {
  errorMsg: "",
};

export default function account(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case INQUIRY_CONTACT_FAILURE:
      //console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    default:
      return state;
  }
}