import React, { Component } from 'react';

class Label extends Component {
  render() {
    const labelKind = this.props.labelKind;
    let labelClass = "";

    switch(labelKind) {
      // case "New":
      // labelClass = "label-new";
      // break;
      case "New":
      case "販売中":
      labelClass = "label-sale";
      break;
      case "まもなく終了":
      labelClass = "label-closing-soon";
      break;
      case "Coming Soon":
      labelClass = "label-coming-soon";
      break;
      case "販売終了":
      labelClass = "label-sold-out";
      break;
      // case "販売期間":
      // case "公開日時":
      // labelClass = "label-sales-period";
      // break;
      default:
      break;
    }
    return (
      <span className={labelClass}>{labelKind}</span>
    );
  }
}
export default Label;