export const CORS_PROXY = "https://cors-anywhere.herokuapp.com/"
export const INFO_RSS_URL = 'http://rssblog.ameba.jp/kujifes/rss20.xml';
export const TWITTER_USER = 'kujifes';
export const CAUTION_INFO_TAG = '[重要]';

export const categoryName = {
  0 : "すべて",
  1 : "アイドル",
  2 : "アニメ",
  3 : "ゲーム",
  4 : "舞台",
  5 : "クリエイター",
  6 : "マンガ",
  7 : "キャラクター",
  100 : "その他",
};

export const thumbLabelName = {
  1 : "Coming Soon",
  2 : "販売中",
  3 : "販売中", // NEW
  4 : "まもなく終了",
  5 : "販売終了",
};

export const thumbLabelClass = {
  1 : "coming-soon",
  2 : "sale",
  3 : "sale", // NEW
  4 : "sale",
  5 : "sold-out",
};

export const SALE_TYPE = {
  "COMING_SOON": 1,
  "SALE" : 2,
  "NEW" : 3,
  "CLOSE_SOON" : 4,
  "SOLD_OUT" : 5,
};

// くじ引き回数タイプ
export const lotteryType = {
  "1times" : 1,
  "10times": 2,
  "100times": 3,
};

export const lotteryAwardType = {
  1 : "S",
  2 : "A",
  3 : "B",
  4 : "C",
  5 : "D",
  6 : "E",
  7 : "F",
  8 : "G",
  9 : "H",
  10 : "I",
  11 : "J",
  12 : "K",
  13 : "L",
  14 : "M",
};

export const contactItem = {
  0: { label: "選択", api: ""},
  1: { label: "配送先を変更したい", api: "inquiry-shipment"},
  2: { label: "メールアドレスを変更したい", api: "inquiry-email"},
  3: { label: "退会したい", api: "inquiry-withdraw"},
  100: { label: "その他お問い合わせ", api: "inquiry"},
}

export const paymentType = {
  0: "クレジットカード",
  3: "コンビニエンスストア決済",
  7: "WebMoney決済",
  8: "auかんたん決済",
  9: "ドコモ決済",
  11:"ソフトバンクまとめて支払い",
};

export const shippingStatusType = {
  0: "発送準備中",
  1: "発送完了",
};

export const prefectures = {
  0 : "都道府県",
  1 : "北海道",
  2 : "青森県",
  3 : "岩手県",
  4 : "宮城県",
  5 : "秋田県",
  6 : "山形県",
  7 : "福島県",
  8 : "茨城県",
  9 : "栃木県",
  10 : "群馬県",
  11 : "埼玉県",
  12 : "千葉県",
  13 : "東京都",
  14 : "神奈川県",
  15 : "新潟県",
  16 : "富山県",
  17 : "石川県",
  18 : "福井県",
  19 : "山梨県",
  20 : "長野県",
  21 : "岐阜県",
  22 : "静岡県",
  23 : "愛知県",
  24 : "三重県",
  25 : "滋賀県",
  26 : "京都府",
  27 : "大阪府",
  28 : "兵庫県",
  29 : "奈良県",
  30 : "和歌山県",
  31 : "鳥取県",
  32 : "島根県",
  33 : "岡山県",
  34 : "広島県",
  35 : "山口県",
  36 : "徳島県",
  37 : "香川県",
  38 : "愛媛県",
  39 : "高知県",
  40 : "福岡県",
  41 : "佐賀県",
  42 : "長崎県",
  43 : "熊本県",
  44 : "大分県",
  45 : "宮崎県",
  46 : "鹿児島県",
  47 : "沖縄県",
};

export const questions = {
  0 : "質問を選択してください",
  1 : "母親の旧姓は？",
  2 : "お気に入りのマンガは？",
  3 : "大好きなペットの名前は？",
  4 : "初恋の人の名前は？",
  5 : "面白かった映画は？",
  6 : "好きな食べ物は？",
};

export const comingSoonText = {
  "title" : "Coming Soon" ,
  "message" : "公開までしばらくお待ちください。",
}

export const termStatus = {
  "before": 0,
  "being" : 1,
  "end"   : 2
}