import store from '../configureStore';
import { PURCHASE_HISTORY_DETAIL_REQUEST, PURCHASE_HISTORY_DETAIL_SUCCESS, PURCHASE_HISTORY_DETAIL_FAILURE } from '../actions/actions';
import { push } from 'react-router-redux';

const initialState = {
  "oid": null,
  "pid": null,
  "title": null,
  "rscdir": null,
  "ltp": null,
  "num": null,
  "totalPrice": 0,
  "shipping": null,
  "tax": null,
  "purchaseDate": null,
  "postalCode1": null,
  "postalCode2": null,
  "prefectures": null,
  "address1": null,
  "address2": null,
  "usid": null,
  "seid": null,
  "paymentType": null,
  "cnb": null,
  "shippingStatus": null,
  "shippingSchedule": null,
  "lotteryResult":[
    {
      "productId": null,
      "itemList": [
        {
          "lotteryItemId": null,
          "lotteryItemTitle": null,
          "itemAwardName": null,
          "num": null,
          "note": null,
          "rscid": null,
          "snsTagList": [],
          "lotteryAwardId": null,
          "lotteryAwardType": null,
          "lotteryAwardTitle": null,
        }
      ],
      "premiumItemList": [
        {
          "lotteryItemId": null,
          "lotteryItemTitle": null,
          "itemAwardName": null,
          "num": null,
          "note": null,
          "rscid": null,
          "snsTagList": [],
        }
      ],
    },
  ],
  "sc": null,
};

export default function purchaseHistoryDetail(state=initialState, action) {
  //console.log(action.type, action);

  switch (action.type) {
    case PURCHASE_HISTORY_DETAIL_REQUEST:
      return state;
    case PURCHASE_HISTORY_DETAIL_SUCCESS:
      console.log("purchaseHistoryDetail action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload,
      );
    case PURCHASE_HISTORY_DETAIL_FAILURE:
      store.dispatch(push('/404'));
      return state;
    default:
      return state;
  }
}