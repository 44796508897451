import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../common/api';
import checkResponse from '../common/checkResponse';
import { push } from 'react-router-redux';
import { ACCOUNT_REQUEST, accountSuccess, accountFailure, loginClear } from '../actions/actions';

function* handleRequest(action) {
  console.log("ACCOUNT_REQUEST input==>",action.payload);
  try {
    const response = yield call(api, action);
    const payload = yield response.json();
    if(!checkResponse(response, payload)) return;

    if(payload.sxp) {
      yield put(loginClear());
      yield put(push("/login"));
      return;
    }

    if( payload.errMsg == null ) {
      yield put(accountSuccess(payload));
    } else {
      yield put(accountFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const accountSaga = [takeEvery(ACCOUNT_REQUEST, handleRequest)];
export default accountSaga;