import { call, put, takeEvery } from 'redux-saga/effects';
import { PURCHASE_HISTORY_DETAIL_REQUEST, purchaseHistoryDetailSuccess, purchaseHistoryDetailFailure } from '../actions/actions';
import api from '../common/api';
import checkResponse from '../common/checkResponse';

function* handleRequest(action) {

  try {
    const response = yield call(api, action);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(purchaseHistoryDetailSuccess(payload));
    } else {
      yield put(purchaseHistoryDetailFailure(payload));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }
}

const purchaseHistoryDetailSaga = [takeEvery(PURCHASE_HISTORY_DETAIL_REQUEST, handleRequest)];
export default purchaseHistoryDetailSaga;