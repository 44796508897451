import { createAction } from 'redux-actions';

export const LOGIN_REQUEST       = 'LOGIN_REQUEST';
export const LOGIN_CHECK_REQUEST = 'LOGIN_CHECK_REQUEST';
export const LOGIN_SUCCESS       = 'LOGIN_SUCCESS';
export const LOGIN_PRE_SUCCESS   = 'LOGIN_PRE_SUCCESS';
export const LOGIN_FAILURE       = 'LOGIN_FAILURE';
export const LOGIN_CLEAR         = 'LOGIN_CLEAR';

export const LOGOUT_REQUEST      = 'LOGOUT_REQUEST';

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_RECEIVE = 'CHECK_EMAIL_RECEIVE';

export const SEND_RESET_PASSWORD_REQUEST = 'SEND_RESET_PASSWORD_REQUEST';
export const SEND_RESET_PASSWORD_VALUE = 'SEND_RESET_PASSWORD_VALUE';
export const SEND_RESET_PASSWORD_CLEAR = 'SEND_RESET_PASSWORD_CLEAR';
export const SEND_RESET_PASSWORD_RECEIVE = 'SEND_RESET_PASSWORD_RECEIVE';

export const CHECK_CERTIFICATE_RESET_REQUEST = 'CHECK_CERTIFICATE_RESET_REQUEST';
export const CHECK_CERTIFICATE_RESET_RECEIVE = 'CHECK_CERTIFICATE_RESET_RECEIVE';

export const CERTIFICATE_RESET_REQUEST = 'CERTIFICATE_RESET_REQUEST';
export const CERTIFICATE_RESET_RECEIVE = 'CERTIFICATE_RESET_RECEIVE';

export const REGISTER_VALUE   = 'REGISTER_VALUE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const TOP_REQUEST = 'TOP_REQUEST';
export const TOP_SUCCESS = 'TOP_SUCCESS';
export const TOP_FAILURE = 'TOP_FAILURE';

export const CATEGORY_SERIES_LIST_REQUEST = 'CATEGORY_SERIES_LIST_REQUEST';
export const CATEGORY_SERIES_LIST_SUCCESS = 'CATEGORY_SERIES_LIST_SUCCESS';
export const CATEGORY_SERIES_LIST_FAILURE = 'CATEGORY_SERIES_LIST_FAILURE';

export const END_SERIES_LIST_REQUEST = 'END_SERIES_LIST_REQUEST';
export const END_SERIES_LIST_SUCCESS = 'END_SERIES_LIST_SUCCESS';
export const END_SERIES_LIST_FAILURE = 'END_SERIES_LIST_FAILURE';

export const CERTIFICATE_REQUEST = 'CERTIFICATE_REQUEST';
export const CERTIFICATE_SUCCESS = 'CERTIFICATE_SUCCESS';
export const CERTIFICATE_FAILURE = 'CERTIFICATE_FAILURE';

export const PURCHASE_PRODUCT_REQUEST = 'PURCHASE_PRODUCT_REQUEST';
export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILURE = 'PRODUCT_FAILURE';

export const PURCHASE_ACCOUNT_REQUEST = 'PURCHASE_ACCOUNT_REQUEST';
export const ACCOUNT_REQUEST = 'ACCOUNT_REQUEST';
export const ACCOUNT_SUCCESS = 'ACCOUNT_SUCCESS';
export const ACCOUNT_FAILURE = 'ACCOUNT_FAILURE';

export const CHECK_ACCOUNT_REQUEST = 'CHECK_ACCOUNT_REQUEST';

export const PURCHASE_CHECK_SERISE_REQUEST = 'PURCHASE_CHECK_SERISE_REQUEST';
export const CHECK_SERIES_REQUEST = 'CHECK_SERIES_REQUEST';
export const CHECK_SERIES_SUCCESS = 'CHECK_SERIES_SUCCESS';
export const CHECK_SERIES_FAILURE = 'CHECK_SERIES_FAILURE';

export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';

export const RESULT_REQUEST = 'RESULT_REQUEST';
export const RESULT_SUCCESS = 'RESULT_SUCCESS';
export const RESULT_FAILURE = 'RESULT_FAILURE';

export const OID_REGISTER = 'OID_REGISTER';
export const OID_CLEAR = 'OID_CLEAR';

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_FAILURE = 'USER_EDIT_FAILURE';

export const HISTORY_ACCOUNT_REQUEST = 'HISTORY_ACCOUNT_REQUEST';

export const PURCHASE_HISTORY_REQUEST = 'PURCHASE_HISTORY_REQUEST';
export const PURCHASE_HISTORY_LIST = 'PURCHASE_HISTORY_LIST';
export const PURCHASE_HISTORY_LIST_CLEAR   = 'PURCHASE_HISTORY_LIST_CLEAR';

export const PURCHASE_HISTORY_BY_RANGE_REQUEST = 'PURCHASE_HISTORY_BY_RANGE_REQUEST';
export const PURCHASE_HISTORY_BY_RANGE_SUCCESS = 'PURCHASE_HISTORY_BY_RANGE_SUCCESS';
export const PURCHASE_HISTORY_BY_RANGE_FAILURE = 'PURCHASE_HISTORY_BY_RANGE_FAILURE';
// export const PURCHASE_HISTORY_BY_RANGE_CLEAR   = 'PURCHASE_HISTORY_BY_RANGE_CLEAR';

export const PURCHASE_HISTORY_DETAIL_REQUEST = 'PURCHASE_HISTORY_DETAIL_REQUEST';
export const PURCHASE_HISTORY_DETAIL_SUCCESS = 'PURCHASE_HISTORY_DETAIL_SUCCESS';
export const PURCHASE_HISTORY_DETAIL_FAILURE = 'PURCHASE_HISTORY_DETAIL_FAILURE';

export const PURCHASE_CONFIRM_REQUEST = 'PURCHASE_CONFIRM_REQUEST';
export const PURCHASE_CONFIRM_SUCCESS = 'PURCHASE_CONFIRM_SUCCESS';
export const PURCHASE_CONFIRM_FAILURE = 'PURCHASE_CONFIRM_FAILURE';

export const SELECT_CONTACT = "SELECT_CONTACT";
export const SELECT_CONTACT_SUCCESS = "SELECT_CONTACT_SUCCESS";
export const SELECT_CONTACT_FAILURE = "SELECT_CONTACT_FAILURE";

export const INQUIRY_EMAIL_REQUEST = "INQUIRY_EMAIL_REQUEST";
export const INQUIRY_EMAIL_FAILURE = "INQUIRY_EMAIL_FAILURE";

export const INQUIRY_WITHDRAW_REQUEST = "INQUIRY_WITHDRAW_REQUEST";
export const INQUIRY_WITHDRAW_FAILURE = "INQUIRY_WITHDRAW_FAILURE";

export const INQUIRY_REQUEST = "INQUIRY_REQUEST";
export const INQUIRY_FAILURE = "INQUIRY_FAILURE";

export const INQUIRY_CONTACT_REQUEST = "INQUIRY_CONTACT_REQUEST";
export const INQUIRY_CONTACT_FAILURE = "INQUIRY_CONTACT_FAILURE";

export const PENDING_SHIPMENT_LOGIN_CHECK = "PENDING_SHIPMENT_LOGIN_CHECK";
export const PENDING_SHIPMENT_REQUEST = "PENDING_SHIPMENT_REQUEST";
export const PENDING_SHIPMENT_SUCCESS = "PENDING_SHIPMENT_SUCCESS";
export const PENDING_SHIPMENT_FAILURE = "PENDING_SHIPMENT_FAILURE";



export const loginRequest = createAction(LOGIN_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const logoutRequest = createAction(LOGOUT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);

export const loginCheckRequest = createAction(LOGIN_CHECK_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginFailure = createAction(LOGIN_FAILURE);
export const loginPreSuccess = createAction(LOGIN_PRE_SUCCESS);
export const loginClear   = createAction(LOGIN_CLEAR);

export const checkEmailRequest = createAction(CHECK_EMAIL_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const checkEmailReceive = createAction(CHECK_EMAIL_RECEIVE);

export const checkCertificateResetRequest = createAction(CHECK_CERTIFICATE_RESET_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const checkCertificateResetReceive = createAction(CHECK_CERTIFICATE_RESET_RECEIVE);

export const certificateResetRequest = createAction(CERTIFICATE_RESET_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const certificateResetReceive = createAction(CERTIFICATE_RESET_RECEIVE);

export const sendResetPasswordRequest = createAction(SEND_RESET_PASSWORD_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const sendResetPasswordValue = createAction(SEND_RESET_PASSWORD_VALUE);
export const sendResetPasswordClear = createAction(SEND_RESET_PASSWORD_CLEAR);
export const sendResetPasswordReceive = createAction(SEND_RESET_PASSWORD_RECEIVE);

export const registerValue = createAction(REGISTER_VALUE);
export const registerRequest = createAction(REGISTER_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const registerSuccess = createAction(REGISTER_SUCCESS);
export const registerFailure = createAction(REGISTER_FAILURE);

export const topRequest = createAction(TOP_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const topSuccess = createAction(TOP_SUCCESS);
export const topFailure = createAction(TOP_FAILURE);

export const categorySeriesListRequest = createAction(CATEGORY_SERIES_LIST_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const categorySeriesListSuccess = createAction(CATEGORY_SERIES_LIST_SUCCESS);
export const categorySeriesListFailure = createAction(CATEGORY_SERIES_LIST_FAILURE);

export const endSeriesListRequest = createAction(END_SERIES_LIST_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const endSeriesListSuccess = createAction(END_SERIES_LIST_SUCCESS);
export const endSeriesListFailure = createAction(END_SERIES_LIST_FAILURE);

export const certificateRequest = createAction(CERTIFICATE_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const certificateSuccess = createAction(CERTIFICATE_SUCCESS);
export const certificateFailure = createAction(CERTIFICATE_FAILURE);


export const purchaseProductRequest = createAction(PURCHASE_PRODUCT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const productRequest = createAction(PRODUCT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const productSuccess = createAction(PRODUCT_SUCCESS);
export const productFailure = createAction(PRODUCT_FAILURE);

export const checkAccountRequest = createAction(CHECK_ACCOUNT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);


export const historyAccountRequest = createAction(HISTORY_ACCOUNT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);

export const purchaseAccountRequest = createAction(PURCHASE_ACCOUNT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const accountRequest = createAction(ACCOUNT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const accountSuccess = createAction(ACCOUNT_SUCCESS);
export const accountFailure = createAction(ACCOUNT_FAILURE);


export const purchaseCheckSeriesRequest = createAction(PURCHASE_CHECK_SERISE_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const checkSeriesRequest = createAction(CHECK_SERIES_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const checkSeriesSuccess = createAction(CHECK_SERIES_SUCCESS);
export const checkSeriesFailure = createAction(CHECK_SERIES_FAILURE);

export const orderRequest = createAction(ORDER_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const orderSuccess = createAction(ORDER_SUCCESS);
export const orderFailure = createAction(ORDER_FAILURE);

export const resultRequest = createAction(RESULT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const resultSuccess = createAction(RESULT_SUCCESS);
export const resultFailure = createAction(RESULT_FAILURE);

export const oidRegister = createAction(OID_REGISTER);
export const oidClear = createAction(OID_CLEAR);

export const userEditRequest = createAction(USER_EDIT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const userEditSuccess = createAction(USER_EDIT_SUCCESS);
export const userEditFailure = createAction(USER_EDIT_FAILURE);

export const purchaseHistoryRequest = createAction(PURCHASE_HISTORY_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const purchaseHistoryByRangeRequest = createAction(PURCHASE_HISTORY_BY_RANGE_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const purchaseHistoryByRangeSuccess = createAction(PURCHASE_HISTORY_BY_RANGE_SUCCESS);
export const purchaseHistoryByRangeFailure = createAction(PURCHASE_HISTORY_BY_RANGE_FAILURE);

export const purchaseHistoryList    = createAction(PURCHASE_HISTORY_LIST);
export const purchaseHistoryListClear    = createAction(PURCHASE_HISTORY_LIST_CLEAR);

export const purchaseHistoryDetailRequest = createAction(PURCHASE_HISTORY_DETAIL_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const purchaseHistoryDetailSuccess = createAction(PURCHASE_HISTORY_DETAIL_SUCCESS);
export const purchaseHistoryDetailFailure = createAction(PURCHASE_HISTORY_DETAIL_FAILURE);

export const purchaseConfirmRequest = createAction(PURCHASE_CONFIRM_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const purchaseConfirmSuccess = createAction(PURCHASE_CONFIRM_SUCCESS);
export const purchaseConfirmFailure = createAction(PURCHASE_CONFIRM_FAILURE);

export const selectContact = createAction(SELECT_CONTACT,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);

export const inquiryEmailRequest = createAction(INQUIRY_EMAIL_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const inquiryEmailFailure = createAction(INQUIRY_EMAIL_FAILURE);

export const inquiryWithdrawRequest = createAction(INQUIRY_WITHDRAW_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const inquiryWithdrawFailure = createAction(INQUIRY_WITHDRAW_FAILURE);

export const inquiryRequest = createAction(INQUIRY_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const inquiryFailure = createAction(INQUIRY_FAILURE);


export const inquiryContactRequest = createAction(INQUIRY_CONTACT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const inquiryContactFailure = createAction(INQUIRY_CONTACT_FAILURE);


export const pendingShipmentLoginCheck = createAction(PENDING_SHIPMENT_LOGIN_CHECK,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const pendingShipmentRequest = createAction(PENDING_SHIPMENT_REQUEST,
  (...args) => args[0],
  (...args) => ({ api: args[1]}),
);
export const pendingShipmentSuccess = createAction(PENDING_SHIPMENT_SUCCESS);
export const pendingShipmentFailure = createAction(PENDING_SHIPMENT_FAILURE);



// actionは 下記の形式で渡される
// {
//   type    : {symbol|string}, // アクションタイプ
//   payload : {any},           // メインの情報
//   error   : {boolean},       // エラーかどうか
//   meta    : {any},           // payloadに乗らなかった情報
// }

// export default createActions (
//   {
//     [LOGIN_REQUEST] : [
//       (...args) => args[0],
//       (...args) => args[1],
//     ],
//     LOGIN_SUCCESS,
//     LOGIN_FAILURE,
//     [REGISTER_REQUEST] : [
//       (...args) => args[0],
//       (...args) => args[1],
//     ],
//     REGISTER_SUCCESS,
//     REGISTER_FAILURE,
//   }
// );
