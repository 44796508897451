import { PENDING_SHIPMENT_SUCCESS, PENDING_SHIPMENT_FAILURE } from '../actions/actions';
const initialState = {
  usid: "",
  olist: [],
};

export default function pendingShipment(state=initialState, action) {
  // console.log(action.type, action);
  switch (action.type) {
    case PENDING_SHIPMENT_SUCCESS:
    case PENDING_SHIPMENT_FAILURE:
      //console.log("return action.payload", action.payload);
      return Object.assign({},
        state,
        action.payload
      );
    default:
      return state;
  }
}