import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import { RenderField, inputFields, phoneFields, postalCodeFields, AddressField, ConfirmField } from './render-field';
import { required, requiredSelect, requiredPrefectures, passwordConfirmation, extendValidator, passwordEdit, answerEdit, kana, number, phone1, phone2, phone3 } from '../common/validate';
import SubmitButton from '../components/submit-button';
import { withRouter } from 'react-router-dom';
import { prefectures, questions } from '../common/const';


const prefecturesOption = [];
for(let key in prefectures) {
  prefecturesOption.push(<option key={key} value={prefectures[key]}>{prefectures[key]}</option>);
}
const questionsOption = [];
for(let key in questions) {
  questionsOption.push(<option key={key} value={key}>{questions[key]}</option>);
}


class EditForm extends Component {

  constructor(props) {
    super(props);
    const {account} = this.props;

    console.log("this.props.account ==> ",this.props.account);
    this.state = {
      // DBから取得した登録情報を初期値とする
      editForm : {
        surname: account.surname,
        name: account.name,
        phoneticSurname: account.phoneticSurname,
        phoneticName: account.phoneticName,
        postalCode1: account.postalCode1,
        postalCode2: account.postalCode2,
        prefectures: account.prefectures,
        address1: account.address1,
        address2: account.address2,
        phone1: account.phone1,
        phone2: account.phone2,
        phone3: account.phone3,
        email: account.email,
        birthYear: account.birthYear,
        birthMonth: account.birthMonth,
        birthDay: account.birthDay,
        password1: "********",
        phash: "********",
        questionType: account.questionType,
        ahash: "********",
      },
    }

    console.log("assign Aftet account",account);

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit() {
    // event.preventDefault();
    const {invalid, formValues, history} = this.props;
    // console.log("formValues", formValues);
    if(invalid === false) {
      history.push({ pathname: '/user/edit-confirm', state: {editForm: formValues}});
    }
  }

  componentDidMount() {
    if(window.YubinBango != null) {
      new window.YubinBango.MicroformatDom();
    }
  }

  componentDidUpdate = () => {
    const submitter = this.props.handleSubmit(()=>{});
    submitter();
   }

  render() {
    const { initialValues } = this.props;

    var prefList = [];
    var questionList = [];

    for(var i in prefectures){
      const key = prefectures[i];
      prefList.push(<option key={i} value={key}>{key}</option>);
    }

    for(var j in questions){
      const key = questions[j];
      questionList.push(<option key={j} value={key}>{key}</option>);
    }

    return (
      <form className="edit-form h-adr" name="edit-form">
        <input type="hidden" className="p-country-name" style={{display: 'none'}} value="Japan"></input>

        <div className="form-inner">
          <dl>
            <dt>お名前<span className="required">※</span></dt>
            <dd className="form-inner__name">
              <Fields names={['surname','name']}
                data={[{placeholder:'姓'},{placeholder:'名'} ]}
                className="form-inner__input--name"
                type="text"
                maxLength="15"
                component={inputFields}
                validate={{
                  surname: [
                    (value) => extendValidator('お名前（姓）が入力されていません')(required(value)),
                    // (value) => extendValidator('お名前（姓）に特殊文字が含まれています')(specialCharacter(value)),
                  ],
                  name: [
                    (value) => extendValidator('お名前（名）が入力されていません')(required(value)),
                    // (value) => extendValidator('お名前（名）に特殊文字が含まれています')(specialCharacter(value)),
                  ]
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>お名前(フリガナ)<span className="required">※</span></dt>
            <dd className="form-inner__name">
              <Fields names={['phoneticSurname','phoneticName']}
                data={[{placeholder:'セイ'},{placeholder:'メイ'} ]}
                className="form-inner__input--name"
                type="text"
                maxLength="15"
                component={inputFields}
                validate={{
                  phoneticSurname: [
                    (value) => extendValidator('お名前（セイ）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（セイ）全角カタカナを入力してください')(kana(value)),
                  ],
                  phoneticName: [
                    (value) => extendValidator('お名前（メイ）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（メイ）全角カタカナを入力してください')(kana(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>郵便番号<span className="required">※</span></dt>
            <dd className="form-inner__postalcode">
              <Fields names={['postalCode1', 'postalCode2']}
                component={postalCodeFields}
                alldata={this.props.formValues}
                allMeta={this.props.formMeta}
                validate={{
                  postalCode1: [
                    (value) => extendValidator('郵便番号1が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号1 半角数字を入力してください')(number(value)),
                  ],
                  postalCode2: [
                    (value) => extendValidator('郵便番号2が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号2 半角数字を入力してください')(number(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>住所<span className="required">※</span></dt>
            <dd className="form-inner__address">
              <Field id={'prefectures'} name="prefectures" component={RenderField} validate={[requiredPrefectures]} type="select" className={'p-region select-pref-wrapper__select--pref'}>
                {prefecturesOption}
              </Field>
              <div className="form-inner__address-wrapper">
                <Field id={'address1'} name="address1" component={AddressField} validate={[required]} type="text" placeholder="市区町村名" className={'p-locality p-street-address p-extended-address form-inner__input--long'} />
                <Field id={'address2'} name="address2" component={AddressField} validate={[required]}　type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} />
                {/* <Field id={'address2'} name="address2" component={RenderField} validate={[required]} type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} /> */}
              </div>
            </dd>
          </dl>

          <dl>
            <dt>電話番号<span className="required">※</span></dt>
            <dd className="form-inner__tel">
              <Fields names={['phone1', 'phone2', 'phone3']}
                component={phoneFields}
                type='tel'
                validate={{
                  phone1: [
                    (value) => extendValidator('電話番号1が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号1 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号1 電話番号の形式に誤りがあります')(phone1(value)),
                  ],
                  phone2: [
                    (value) => extendValidator('電話番号2が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号2 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号2 電話番号の形式に誤りがあります')(phone2(value)),
                  ],
                  phone3: [
                    (value) => extendValidator('電話番号3が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号3 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号3 電話番号の形式に誤りがあります')(phone3(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>メールアドレス</dt>
              <dd className="form-inner__mail">
                <input type="hidden" name="mail" value={initialValues.email} />
                {initialValues.email}
              </dd>
          </dl>

          <dl>
            <dt>生年月日</dt>
            <dd>
              <div>
                <span>
                  {initialValues.birthYear}年
                </span>
                <span>
                  {initialValues.birthMonth}月
                </span>
                <span>
                  {initialValues.birthDay}日
                </span>
              </div>
            </dd>
          </dl>

          <dl>
            <dt>パスワード<span className="required">※</span></dt>
            <dd className="form-inner__password">
              <Field id={'phash'} name="phash" component={RenderField} validate={[passwordEdit]} type="password" className={'form-inner__input--middle'} />
              <br />
              <Field id={'confirmPhash'} name="confirmPhash" component={RenderField} validate={[required, passwordConfirmation]} type="password" className={'form-inner__input--middle'} placeholder="確認のため2度入力してください" />
            </dd>
          </dl>

          <dl>
            <dt>秘密の質問<span className="required">※</span></dt>
            <dd className="form-inner__hint">
              <Field id={'questionType'} name="questionType" component={RenderField} validate={[requiredSelect]} type="select" className={'select-wrapper__select--hint1'}>
                {questionsOption}
              </Field>
              <div className="form-inner__hint2">
                <Field id={'ahash'} name="ahash" component={RenderField} maxLength="20" type="text" validate={[required, answerEdit]} className={'form-inner__input--middle'} placeholder="答えを入力してください" />
              </div>
            </dd>
          </dl>

          <dl>
            <dt><div className="sp">お知らせ</div></dt>
            <dd className="form-inner__announce">
              <Field name="announce" id="announce" component={ConfirmField} type="checkbox" />
              <label htmlFor="announce" className="announce-wrapper__checkbox checkbox" >お知らせメールを受け取る</label><br />
              <p className="annouce-bottom-label">（新しいくじの販売情報やお得なキャンペーンの情報をお届けします）</p>
            </dd>
          </dl>
        </div>
        <div className="button-wrapper">
          <SubmitButton onClick={this.handleSubmit} text="確認ページへ" />
        </div>
      </form>
    );
  }
}

EditForm = reduxForm({
  form: 'editForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  // initialValues: this.props,
})(EditForm);


EditForm = connect(state => {
  return {
    initialValues: state.account,
    formValues: getFormValues('editForm')(state),
    formMeta: getFormMeta('editForm')(state),
  }
})(EditForm);

export default withRouter(EditForm);

