import { imagePath, resourceVersion } from './config';
import { lotteryAwardType, paymentType, shippingStatusType } from './const';
import { unixTime2Date } from './utile';
import { throwError } from 'rxjs';
import ReactGA from 'react-ga';
import { getUrlParamList, accessLocalStorage } from '../common/utile';

export function imgPath(imgName='', rscdir, type){
  let path = imagePath;

  if(rscdir != null && type != null ){
    path += rscdir + "/img/" + type + "/" + type + "_" + imgName + resourceVersion;
  }else{
    path += imgName + resourceVersion;
  }
  return path;
}

export function getLotteryAwardText(type) {
  const text = lotteryAwardType[type];
  if(text == null) throwError("各等する景品賞Typeはありません！");

  return text;
}

export function getPaymentTypeText(type){
  const text = paymentType[type];
  // if(text == null) {
  //   const result = concat(of(7), throwError(new Error("各等する決済Typeはありません！")));
  //   result.subscribe(x => console.log(x), e => console.error(e));
  // }
  return text;
}

export function getShippingStatusTypeText(type){
  return shippingStatusType[type];
}

// URLパラメーターを取得してlocalStorageに保存
export function saveUrlParam() {
  let paramList = getUrlParamList();

  for (var key in paramList) {
    console.log(key, paramList[key]);
    accessLocalStorage("save", key, paramList[key]);
  }
}

export function sendGA(path) {

  console.log("window.location.href ==> ",window.location.href);
  let pathName = path;
  let paramFlag = false;

  var list = [];
  list["utm_source"] = accessLocalStorage("read", "utm_source");
  list["utm_campaign"] = accessLocalStorage("read", "utm_campaign");

  var param = "";
  for(var key in list) {
    if(list[key] != null){
      var paramStr = (paramFlag === false) ? "?": "&";
      param += paramStr + key + "=" + list[key];
      paramFlag = true;
    }
  }

  pathName += param;

  var loginData =  JSON.parse(window.localStorage.getItem("persist:login"));

  if(loginData != null && JSON.parse(loginData["isLogin"])){
    ReactGA.set({ user_id: JSON.parse(loginData["usid"]) });
  }
  // console.log("pathName ====> ", pathName);
  ReactGA.set({ page: pathName });
  ReactGA.pageview(pathName);
}

export function getDateFormat(unixTime){
  const dateData = unixTime2Date(unixTime);

  const formatText = dateData.year + "/" +
                    dateData.month + "/" +
                    dateData.day +
                    "（" + dateData.week + "）" +
                    dateData.hour + ":" +
                    dateData.min + ":" +
                    dateData.sec;

  return formatText;
}

export function escape_html (string) {
  if(typeof string !== 'string') {
    return string;
  }
  return string.replace(/[&'`"<>]/g, function(match) {
    return {
      '&': '&amp;',
      "'": '&#x27;',
      '`': '&#x60;',
      '"': '&quot;',
      '<': '&lt;',
      '>': '&gt;',
    }[match]
  });
}
