import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, Fields, getFormValues, getFormMeta, reduxForm } from 'redux-form';
import {RenderField, inputFields, phoneFields, postalCodeFields, ConfirmField, birthFields, AddressField } from './render-field';
import {required, requiredSelect, requiredPrefectures, halfUpper, email, emailConfirmation, password, passwordConfirmation, extendValidator, kana, number, specialCharacter, phone1, phone2, phone3} from '../common/validate';
import { prefectures, questions } from '../common/const'
import SubmitButton from '../components/submit-button';
import CommonModal from '../components/common-modal';

const prefecturesOption = [];
for(let key in prefectures) {
  prefecturesOption.push(<option key={key} value={prefectures[key]}>{prefectures[key]}</option>);
}
const questionsOption = [];
for(let key in questions) {
  questionsOption.push(<option key={key} value={key}>{questions[key]}</option>);
}

const initial = {
  surname: "",
  name: "",
  phoneticSurname: "",
  phoneticName: "",
  postalCode1: "",
  postalCode2: "",
  prefectures: "",
  address1: "",
  address2: "",
  phone1: "",
  phone2: "",
  phone3: "",
  email: "",
  announce: "1",
  birthYear: "",
  birthMonth: "",
  birthDay: "",
  phash: "",
  questionType: "",
  questionTypeText: "",
  ahash: "",
  recaptcha: "",
};


class EntryForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      agree: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // 確認画面に入力情報を渡す
  handleSubmit(e) {
    const {invalid, formValues} = this.props;

    // 全てのvalidateを走らせる処理追加
    const submitter = this.props.handleSubmit(()=>{});
    submitter();

    this.props.dispatchRegister(formValues);
    if(invalid === false){
      this.props.history.push({ pathname: '/entry/confirm'});
    }
  }

  handleChange() {
    this.setState({
      agree: !this.state.agree
    });
  }

  submit = values => {
    // print the form values to the console
    console.log("submit ==>", values);
  }

  componentDidMount() {
    if(window.YubinBango != null) {
      new window.YubinBango.MicroformatDom();
    }
  }

  render() {

    return (
      <form name="entryForm" id="entryForm" className="entry-form h-adr" onSubmit={this.submit}>
        <input type="hidden" className="p-country-name" style={{display: 'none'}} value="Japan"></input>

        <div className="form-inner">
          <dl>
            <dt>お名前<span className="required">※</span></dt>
            <dd className="form-inner__name">
              <Fields names={['surname','name']}
                data={[{placeholder:'姓'},{placeholder:'名'} ]}
                className="form-inner__input--name"
                type="text"
                maxLength="15"
                component={inputFields}
                validate={{
                  surname: [
                    (value) => extendValidator('お名前（姓）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（姓）に特殊文字が含まれています')(specialCharacter(value)),
                  ],
                  name: [
                    (value) => extendValidator('お名前（名）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（名）に特殊文字が含まれています')(specialCharacter(value)),
                  ]
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>お名前(フリガナ)<span className="required">※</span></dt>
            <dd className="form-inner__name">
              <Fields names={['phoneticSurname','phoneticName']}
                data={[{placeholder:'セイ'},{placeholder:'メイ'} ]}
                className="form-inner__input--name"
                type="text"
                maxLength="15"
                component={inputFields}
                // validate={{
                //   phoneticSurname: (value) => extendValidator('お名前（セイ）が入力されていません')(required(value)),
                //   phoneticName: (value) => extendValidator('お名前（メイ）が入力されていません')(required(value)),
                // }}
                validate={{
                  phoneticSurname: [
                    (value) => extendValidator('お名前（セイ）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（セイ）全角カタカナを入力してください')(kana(value)),
                  ],
                  phoneticName: [
                    (value) => extendValidator('お名前（メイ）が入力されていません')(required(value)),
                    (value) => extendValidator('お名前（メイ）全角カタカナを入力してください')(kana(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>郵便番号<span className="required">※</span></dt>
            <dd className="form-inner__postalcode">
              <Fields names={['postalCode1', 'postalCode2']}
                component={postalCodeFields}
                alldata={this.props.formValues}
                allMeta={this.props.formMeta}
                validate={{
                  postalCode1: [
                    (value) => extendValidator('郵便番号1が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号1 半角数字を入力してください')(number(value)),
                  ],
                  postalCode2: [
                    (value) => extendValidator('郵便番号2が入力されていません')(required(value)),
                    (value) => extendValidator('郵便番号2 半角数字を入力してください')(number(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>住所<span className="required">※</span></dt>
            <dd className="form-inner__address">
              <Field id={'prefectures'} name="prefectures" component={RenderField} validate={[requiredPrefectures]} type="select" className={'p-region select-pref-wrapper__select--pref'} >
                {prefecturesOption}
              </Field>
              <div className="form-inner__address-wrapper">
                <Field id={'address1'} name="address1" component={AddressField} validate={[required]} type="text" placeholder="市区町村名" className={'p-locality p-street-address p-extended-address form-inner__input--long'} />
                <Field id={'address2'} name="address2" component={AddressField} validate={[required]}　type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} />
                {/* <Field id={'address2'} name="address2" component={RenderField} validate={[required]} type="text" placeholder="番地・ビル名・部屋番号等" className={'form-inner__input--long'} /> */}
              </div>
            </dd>
          </dl>

          <dl>
            <dt>電話番号<span className="required">※</span></dt>
            <dd className="form-inner__tel">
              <Fields names={['phone1', 'phone2', 'phone3']}
                component={phoneFields}
                type='tel'
                validate={{
                  phone1: [
                    (value) => extendValidator('電話番号1が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号1 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号1 電話番号の形式に誤りがあります')(phone1(value)),
                  ],
                  phone2: [
                    (value) => extendValidator('電話番号2が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号2 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号2 電話番号の形式に誤りがあります')(phone2(value)),
                  ],
                  phone3: [
                    (value) => extendValidator('電話番号3が入力されていません')(required(value)),
                    (value) => extendValidator('電話番号3 半角数字を入力してください')(number(value)),
                    (value) => extendValidator('電話番号3 電話番号の形式に誤りがあります')(phone3(value)),
                  ],
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>メールアドレス<span className="required">※</span></dt>
            <dd className="form-inner__mail">
              <Field id={'email'} name="email" component={RenderField} type="email" validate={[required, halfUpper, email]} className={'form-inner__input--long'} />
              <Field id={'confirmEmail'} name="confirmEmail" component={ConfirmField} type="email" validate={[required, emailConfirmation]} className={'form-inner__input--long'} placeholder="確認のため2度入力してください" />
            </dd>
          </dl>

          <dl>
            <dt>生年月日<span className="required">※</span></dt>
            <dd className="form-inner__birthday">
              <Fields names={['birthYear','birthMonth', 'birthDay']}
                type="select"
                component={birthFields}
                validate={{
                  birthYear: (value) => extendValidator('誕生年が選択されていません')(requiredSelect(value)),
                  birthMonth: (value) => extendValidator('誕生月が選択されていません')(requiredSelect(value)),
                  birthDay: (value) => extendValidator('誕生日が入力されていません')(requiredSelect(value)),
                }}
              />
            </dd>
          </dl>

          <dl>
            <dt>パスワード<span className="required">※</span></dt>
            <dd className="form-inner__password">
              <Field id={'phash'} name="phash" component={RenderField} validate={[required, password]} type="password" className={'form-inner__input--long'} placeholder="6~16文字で半角英字・数字の各1文字を含む" />
              <br />
              <Field id={'confirmPhash'} name="confirmPhash" component={RenderField} validate={[required, passwordConfirmation]} type="password" className={'form-inner__input--long'} placeholder="確認のため2度入力してください" />
            </dd>
          </dl>

          <dl>
            <dt>パスワードを忘れた時のヒント<span className="required">※</span></dt>
            <dd className="form-inner__hint">
              <Field id={'questionType'} name="questionType" component={RenderField} validate={[requiredSelect]} type="select" className={'select-wrapper__select--hint1'}>
                {questionsOption}
              </Field>
              <div className="form-inner__hint2">
                <Field id={'ahash'} name="ahash" component={RenderField} maxLength="20" type="text" validate={[required, specialCharacter]} className={'form-inner__input--middle'} placeholder="答えを入力してください" />
              </div>
            </dd>
          </dl>

          <dl>
            <dt><div className="sp">お知らせ</div></dt>
            <dd className="form-inner__announce">
              <Field name="announce" id="announce" component={ConfirmField} type="checkbox" />
              <label htmlFor="announce" className="announce-wrapper__checkbox checkbox" >お知らせメールを受け取る</label>
              <p className="annouce-bottom-label">（新しいくじの販売情報やお得なキャンペーンの情報をお届けします）</p>
            </dd>
          </dl>
        </div>

        <div className="agree-wrapper">
          <input type="checkbox" name="agree" id="agree" value={this.state.agree} onChange={this.handleChange} />
          <label htmlFor="agree" className="agree-wrapper__checkbox checkbox"><span>注意事項・個人情報保護方針に同意する</span></label>
          <p className="agree-wrapper__text">※<a href="/#/notes" target="_blank" rel="noopener noreferrer">注意事項</a>・<a href="https://goodroid.co.jp/policy/" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>をご確認ください</p>
        </div>

        <div className="button-wrapper">
          { this.state.agree ?
          <SubmitButton onClick={this.handleSubmit} text="確認ページへ" />
          :
          <CommonModal title="" message="注意事項・個人情報保護方針への同意が必要です。&#10;ご確認の上チェックボックスへチェックをお願いいたします。">
            <button type="button" className="button-submit">確認ページへ</button>
          </CommonModal>
          }
        </div>
      </form>
    );
  }
}

EntryForm = reduxForm({
  form: 'entryForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  initialValues: initial,
})(EntryForm);


EntryForm = connect(state => {
  return {
    formValues: getFormValues('entryForm')(state),
    formMeta: getFormMeta('entryForm')(state),
  }
})(EntryForm);

export default EntryForm;
