import { call, put, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import api from '../common/api';
import Sha256 from 'js-sha256';
import checkResponse from '../common/checkResponse';
import { CERTIFICATE_RESET_REQUEST, certificateResetReceive } from '../actions/actions';

function* handleRequest(action) {

  let requestData = Object.assign({}, action);
  requestData.payload = Object.assign({}, requestData.payload, action.payload);
  requestData.payload.phash = Sha256(requestData.payload.phash);

  try {
    const response = yield call(api, requestData);
    const payload = yield response.json();

    if(!checkResponse(response, payload)) return;

    if( payload.sc === 0 && payload.errMsg == null ) {
      yield put(certificateResetReceive(payload));
      yield put(push('/password/resetting-complete'));
    }
  } catch(error) {
    console.log("error ==> ",error);

  }


}

const certificateResetSaga = [takeEvery(CERTIFICATE_RESET_REQUEST, handleRequest)];
export default certificateResetSaga;