import { imagePath } from './config';


export function imgPath(imgName=''){
  const path = imagePath + imgName;
  return path;
}

export function unixTime2Date(unixTime){
  var d = new Date( unixTime );
  var year  = d.getFullYear();
  var month = d.getMonth() + 1;
  var day  = d.getDate();
  var hour = ( '0' + d.getHours() ).slice(-2);
  var min  = ( '0' + d.getMinutes() ).slice(-2);
  var sec   = ( '0' + d.getSeconds() ).slice(-2);
  var week = d.getDay();
  var weekStr = [ "日", "月", "火", "水", "木", "金", "土" ][week];

  return { year : year, month : month, day : day, hour : hour, min : min, sec : sec, week : weekStr};
}

export function formatInfoDate(rssDate, format=''){
  var dt = new Date(rssDate);
  var y = dt.getFullYear();
  var m = ('00' + (dt.getMonth() + 1)).slice(-2);
  var d = ('00' + dt.getDate()).slice(-2);

  return (format === 'jp')? y + '年' + m + '月' + d + '日' : y + '.' + m + '.' + d;
  // return result;
}

export function formatInfoNoYearDate(rssDate){
  var dt = new Date(rssDate);
  var m = ('00' + (dt.getMonth() + 1)).slice(-2);
  var d = ('00' + dt.getDate()).slice(-2);
  var week = dt.getDay();
  var weekStr = [ "日", "月", "火", "水", "木", "金", "土" ][week];
  return { month : m, day : d, week : weekStr };
}

export function isEmpty(obj) {
  return !Object.keys(obj).length;
}

export function separate(num) {
  return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

// リストの昇順・降順処理
export function shuffleOrder(list) {
  if(list == null) return;

  for(let i= list.length -1; i >=0; i--) {
    var rand = Math.floor(Math.random() * (i +1));
    var tmp = list[i];
    list[i] = list[rand];
    list[rand] = tmp;
  }
}

// リストの昇順・降順処理
export function sortOrder(list, prop, type) {
  // console.log(type + " : before => ", list);
  switch(type) {
    case "asc":
      list.sort(function(a,b){
        if(a[prop] < b[prop]) return -1;
        if(a[prop] > b[prop]) return 1;
        return 0;
      });
      break;
    case "desc":
      list.sort(function(a,b){
        if(a[prop] > b[prop]) return -1;
        if(a[prop] < b[prop]) return 1;
        return 0;
      });
      break;
    default:
      break;
  }
}

export function findWithAttr(array, attr, value) {
  for(var i = 0; i < array.length; i += 1) {
    // console.log("array[i][attr]", array[i][attr], " value ==> ",value);
      if(array[i][attr] === value) {
          return i;
      }
  }
  return -1;
}

// localStorage周りの処理
export function accessLocalStorage (type, key, value) {
  var _localStorage = window.localStorage;
  switch(type) {
    case "save":
      _localStorage.setItem(key, value);
      break;
    case "read":
      var getValue = _localStorage.getItem(key);
      return getValue;
    case "remove":
      _localStorage.removeItem(key);
      break;
    default:
      break;
  }
}

// URLパラメータを取得
export function getUrlParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}


// URLパラメータを取得
export function getUrlParamList(url) {
  if (!url) url = window.location.href;

  var paramsArray= [];
console.log("url ==> ",url );
  var parameters = url.split("?");
console.log("parameters ? ==>", parameters);
  if( parameters.length > 1 ) {
      var params   = parameters[1].split("&");
      for ( var i = 0; i < params.length; i++ ) {
          var paramItem = params[i].split("=");
          paramsArray[paramItem[0]] = paramItem[1];
      }
  }
  return paramsArray;
}