import React from 'react';
// import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { ConnectedRouter } from 'react-router-redux';
// // import createHashHistory from 'history/createHistory';
// import createHistory from 'history/createBrowserHistory';

import Top from './containers/top';
import Login from './containers/login';
// import LoginSNS from './containers/login-sns';
import EntryConfirm from './containers/entry-confirm';
import EntryPreComplete from './containers/entry-pre-complete';
import PasswordReissueMail from './containers/password-reissue-mail';
import PasswordReissueHint from './containers/password-reissue-hint';
import PasswordReissueComplete from './containers/password-reissue-complete';
import PasswordResetting from './containers/password-resetting';
import PasswordResettingComplete from './containers/password-resetting-complete';
import ItemEndList from './containers/item-end-list';
import PurchaseConfirm from './containers/purchase-confirm';
import LotteryResult from './containers/result';
import About from './containers/about';
import FAQ from './containers/faq';
import Notes from './containers/notes';
import Policy from './containers/policy';
import UserEdit from './containers/user-edit';
import UserEditConfirm from './containers/user-edit-confirm';
import History from './containers/history';
import HistoryDetail from './containers/history-detail';
import Contact from './containers/contact';
import ContactReception from './containers/contact-reception';
import ContactComplete from './containers/contact-complete';
import Error from './containers/error';
import Maintenance from './containers/maintenance';
import Registration from './containers/registration';
import PurchaseInfoWebMoney from './containers/purchase-info-webmoney';

import { history } from './configureStore';


// ページ遷移した際にそのページのトップを表示する
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

function Router() {
  return (
    // <ConnectedRouter history={history}>
      // {/* <HashRouter history={syncHistoryWithStore(history, store)}> */}
      <HashRouter history={history}>
        <React.Fragment>
          <Route component={ScrollToTop} />
          <Switch>
            <Route exact path='/' component={Top} />
            <Route exact path='/item-end-list' component={ItemEndList} />
            <Route path='/entry/pre-complete' component={EntryPreComplete} />
            <Route path='/entry/confirm' component={EntryConfirm} />
            {/* <Route path='/login-sns' component={LoginSNS} /> */}
            <Route path='/login' component={Login} />
            <Route path='/password/reissue-mail' component={PasswordReissueMail} />
            <Route path='/password/reissue-hint' component={PasswordReissueHint} />
            <Route path='/password/reissue-complete' component={PasswordReissueComplete} />
            <Route path='/password/resetting-complete' component={PasswordResettingComplete} />
            <Route exact path='/password/resetting/:rstn' component={PasswordResetting} />
            <Route exact path='/purchase/confirm/' component={PurchaseConfirm} />
            <Route exact path='/purchase/confirm/:srid/:ltp/:num' component={PurchaseConfirm} />
            <Route exact path='/purchase/info/webmoney' component={PurchaseInfoWebMoney} />
            <Route exact path='/result/:oid' component={LotteryResult} />
            <Route exact path='/about' component={About} />
            <Route exact path='/faq' component={FAQ} />
            <Route exact path='/notes' component={Notes} />
            <Route exact path='/policy' component={Policy} />
            <Route path='/user/edit-confirm' component={UserEditConfirm} />
            <Route path='/user/edit' component={UserEdit} />
            <Route exact path='/history/:oid' component={HistoryDetail} />
            <Route path='/mypage' component={History} />
            <Route path='/registration/:token' component={Registration} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/contact/reception/:key' component={ContactReception} />
            <Route exact path='/contact/complete' component={ContactComplete} />
            <Route path='/maintenance' component={Maintenance} />

            {/* error */}
            <Route path='/404' component={Error} />
            <Route path='/500' component={Error} />
            <Route path='*' component={Error} />
          </Switch>
        </React.Fragment>
      </HashRouter>
    // {/* </ConnectedRouter> */}
  )
}

export default Router;