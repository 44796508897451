import { findWithAttr } from '../common/utile';
import { PURCHASE_HISTORY_LIST, PURCHASE_HISTORY_LIST_CLEAR } from '../actions/actions';

const initialState = {
  "itemsList": [],
};

export default function purchaseHistoryList(state=initialState, action) {
  // console.log(action.type, action);

  switch (action.type) {
    case PURCHASE_HISTORY_LIST:
      Array.prototype.push.apply(state.itemsList, action.payload.hlist);
      // 重複を削除
      let _historyList = [];
      _historyList = state.itemsList.filter(function (x, i, self) {
        return findWithAttr(state.itemsList, "oid", x.oid) === i;
      });
      state.itemsList = _historyList;

      return Object.assign({},
        state
      );
    case PURCHASE_HISTORY_LIST_CLEAR:
      return Object.assign({},
        state,
        initialState
      );
    default:
      return state;
  }
}